/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 @import url(./vendor/bootstrap/icons/bootstrap-icons.css);
 @import url(./vendor/fontawesome/css/all.min.css);
 @import url(./vendor/jarallax/jarallax.css);
 @import url(./vendor/magnific/magnific-popup.css);
 @import url(./vendor/owl-carousel/css/owl.carousel.min.css);
 @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
 @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
 :root {
   --bs-blue: #0d6efd;
   --bs-indigo: #6610f2;
   --bs-purple: #6f42c1;
   --bs-pink: #d63384;
   --bs-red: #dc3545;
   --bs-orange: #fd7e14;
   --bs-yellow: #ffc107;
   --bs-green: #198754;
   --bs-teal: #20c997;
   --bs-cyan: #0dcaf0;
   --bs-white: #ffffff;
   --bs-gray: #3a3a3a;
   --bs-gray-dark: #101010;
   --bs-primary: #FE4F70;
   --bs-secondary: #6c757d;
   --bs-success: #2dca8c;
   --bs-info: #50b5ff;
   --bs-warning: #ff9f1c;
   --bs-danger: #ff5c75;
   --bs-light: #f1f6fd;
   --bs-dark: #203656;
   --bs-font-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }
 
 *,
 *::before,
 *::after {
   box-sizing: border-box; }
 
 @media (prefers-reduced-motion: no-preference) {
   :root {
     scroll-behavior: smooth; } }
 
 body {
   margin: 0;
   font-family: var(--bs-font-sans-serif);
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.7;
   color: #8F9BAD;
   background-color: #ffffff;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
 
 [tabindex="-1"]:focus:not(:focus-visible) {
   outline: 0 !important; }
 
 hr {
   margin: 1rem 0;
   color: inherit;
   background-color: currentColor;
   border: 0;
   opacity: 0.25; }
 
 hr:not([size]) {
   height: 1px; }
 
 h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
   margin-top: 0;
   margin-bottom: 0.5rem;
   font-family: "Poppins", sans-serif;
   font-weight: 600;
   line-height: 1.4;
   color: #203656; }
 
 h1, .h1 {
   font-size: calc(1.375rem + 1.5vw); }
   @media (min-width: 1200px) {
     h1, .h1 {
       font-size: 2.5rem; } }
 
 h2, .h2 {
   font-size: calc(1.325rem + 0.9vw); }
   @media (min-width: 1200px) {
     h2, .h2 {
       font-size: 2rem; } }
 
 h3, .h3 {
   font-size: calc(1.3rem + 0.6vw); }
   @media (min-width: 1200px) {
     h3, .h3 {
       font-size: 1.75rem; } }
 
 h4, .h4 {
   font-size: calc(1.275rem + 0.3vw); }
   @media (min-width: 1200px) {
     h4, .h4 {
       font-size: 1.5rem; } }
 
 h5, .h5 {
   font-size: 1.25rem; }
 
 h6, .h6 {
   font-size: 1rem; }
 
 p {
   margin-top: 0;
   margin-bottom: 1rem; }
 
 abbr[title],
 abbr[data-bs-original-title] {
   text-decoration: underline;
   text-decoration: underline dotted;
   cursor: help;
   text-decoration-skip-ink: none; }
 
 address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit; }
 
 ol,
 ul {
   padding-left: 2rem; }
 
 ol,
 ul,
 dl {
   margin-top: 0;
   margin-bottom: 1rem; }
 
 ol ol,
 ul ul,
 ol ul,
 ul ol {
   margin-bottom: 0; }
 
 dt {
   font-weight: 700; }
 
 dd {
   margin-bottom: .5rem;
   margin-left: 0; }
 
 blockquote {
   margin: 0 0 1rem; }
 
 b,
 strong {
   font-weight: bolder; }
 
 small, .small {
   font-size: 0.875em; }
 
 mark, .mark {
   padding: 0.2em;
   background-color: #fcf8e3; }
 
 sub,
 sup {
   position: relative;
   font-size: 0.75em;
   line-height: 0;
   vertical-align: baseline; }
 
 sub {
   bottom: -.25em; }
 
 sup {
   top: -.5em; }
 
 a {
   color: #FE4F70;
   text-decoration: none; }
   a:hover {
     color: #cb3f5a; }
 
 a:not([href]):not([class]), a:not([href]):not([class]):hover {
   color: inherit;
   text-decoration: none; }
 
 pre,
 code,
 kbd,
 samp {
   font-family: var(--bs-font-monospace);
   font-size: 1em;
   direction: ltr /* rtl:ignore */;
   unicode-bidi: bidi-override; }
 
 pre {
   display: block;
   margin-top: 0;
   margin-bottom: 1rem;
   overflow: auto;
   font-size: 0.875em; }
   pre code {
     font-size: inherit;
     color: inherit;
     word-break: normal; }
 
 code {
   font-size: 0.875em;
   color: #d63384;
   word-wrap: break-word; }
   a > code {
     color: inherit; }
 
 kbd {
   padding: 0.2rem 0.4rem;
   font-size: 0.875em;
   color: #ffffff;
   background-color: #080808;
   border-radius: 0.2rem; }
   kbd kbd {
     padding: 0;
     font-size: 1em;
     font-weight: 700; }
 
 figure {
   margin: 0 0 1rem; }
 
 img,
 svg {
   vertical-align: middle; }
 
 table {
   caption-side: bottom;
   border-collapse: collapse; }
 
 caption {
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   color: #3a3a3a;
   text-align: left; }
 
 th {
   text-align: inherit;
   text-align: -webkit-match-parent; }
 
 thead,
 tbody,
 tfoot,
 tr,
 td,
 th {
   border-color: inherit;
   border-style: solid;
   border-width: 0; }
 
 label {
   display: inline-block; }
 
 button {
   border-radius: 0; }
 
 button:focus {
   outline: dotted 1px;
   outline: -webkit-focus-ring-color auto 5px; }
 
 input,
 button,
 select,
 optgroup,
 textarea {
   margin: 0;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit; }
 
 button,
 select {
   text-transform: none; }
 
 [role="button"] {
   cursor: pointer; }
 
 select {
   word-wrap: normal; }
 
 [list]::-webkit-calendar-picker-indicator {
   display: none; }
 
 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button; }
   button:not(:disabled),
   [type="button"]:not(:disabled),
   [type="reset"]:not(:disabled),
   [type="submit"]:not(:disabled) {
     cursor: pointer; }
 
 ::-moz-focus-inner {
   padding: 0;
   border-style: none; }
 
 textarea {
   resize: vertical; }
 
 fieldset {
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0; }
 
 legend {
   float: left;
   width: 100%;
   padding: 0;
   margin-bottom: 0.5rem;
   font-size: calc(1.275rem + 0.3vw);
   line-height: inherit; }
   @media (min-width: 1200px) {
     legend {
       font-size: 1.5rem; } }
   legend + * {
     clear: left; }
 
 ::-webkit-datetime-edit-fields-wrapper,
 ::-webkit-datetime-edit-text,
 ::-webkit-datetime-edit-minute,
 ::-webkit-datetime-edit-hour-field,
 ::-webkit-datetime-edit-day-field,
 ::-webkit-datetime-edit-month-field,
 ::-webkit-datetime-edit-year-field {
   padding: 0; }
 
 ::-webkit-inner-spin-button {
   height: auto; }
 
 [type="search"] {
   outline-offset: -2px;
   -webkit-appearance: textfield; }
 
 /* rtl:raw:
 [type="tel"],
 [type="url"],
 [type="email"],
 [type="number"] {
   direction: ltr;
 }
 */
 ::-webkit-search-decoration {
   -webkit-appearance: none; }
 
 ::-webkit-color-swatch-wrapper {
   padding: 0; }
 
 ::file-selector-button {
   font: inherit; }
 
 ::-webkit-file-upload-button {
   font: inherit;
   -webkit-appearance: button; }
 
 output {
   display: inline-block; }
 
 iframe {
   border: 0; }
 
 summary {
   display: list-item;
   cursor: pointer; }
 
 progress {
   vertical-align: baseline; }
 
 [hidden] {
   display: none !important; }
 
 .lead {
   font-size: 1.15rem;
   font-weight: 400; }
 
 .display-1 {
   font-size: calc(1.625rem + 4.5vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-1 {
       font-size: 5rem; } }
 
 .display-2 {
   font-size: calc(1.575rem + 3.9vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-2 {
       font-size: 4.5rem; } }
 
 .display-3 {
   font-size: calc(1.525rem + 3.3vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-3 {
       font-size: 4rem; } }
 
 .display-4 {
   font-size: calc(1.475rem + 2.7vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-4 {
       font-size: 3.5rem; } }
 
 .display-5 {
   font-size: calc(1.425rem + 2.1vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-5 {
       font-size: 3rem; } }
 
 .display-6 {
   font-size: calc(1.375rem + 1.5vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-6 {
       font-size: 2.5rem; } }
 
 .display-7 {
   font-size: calc(1.325rem + 0.9vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-7 {
       font-size: 2rem; } }
 
 .display-8 {
   font-size: calc(1.275rem + 0.3vw);
   font-weight: 600;
   line-height: 1.4; }
   @media (min-width: 1200px) {
     .display-8 {
       font-size: 1.5rem; } }
 
 .list-unstyled {
   padding-left: 0;
   list-style: none; }
 
 .list-inline {
   padding-left: 0;
   list-style: none; }
 
 .list-inline-item {
   display: inline-block; }
   .list-inline-item:not(:last-child) {
     margin-right: 0.5rem; }
 
 .initialism {
   font-size: 0.875em;
   text-transform: uppercase; }
 
 .blockquote {
   margin-bottom: 1rem;
   font-size: 1.25rem; }
   .blockquote > :last-child {
     margin-bottom: 0; }
 
 .blockquote-footer {
   margin-top: -1rem;
   margin-bottom: 1rem;
   font-size: 0.875em;
   color: #3a3a3a; }
   .blockquote-footer::before {
     content: "\2014\00A0"; }
 
 .img-fluid {
   max-width: 100%;
   height: auto; }
 
 .img-thumbnail {
   padding: 0.25rem;
   background-color: #ffffff;
   border: 1px solid #e2e8f0;
   border-radius: 0.25rem;
   max-width: 100%;
   height: auto; }
 
 .figure {
   display: inline-block; }
 
 .figure-img {
   margin-bottom: 0.5rem;
   line-height: 1; }
 
 .figure-caption {
   font-size: 0.875em;
   color: #3a3a3a; }
 
 .container,
 .container-fluid,
 .container-sm,
 .container-md,
 .container-lg,
 .container-xl,
 .container-xxl {
   width: 100%;
   padding-right: var(--bs-gutter-x, 0.75rem);
   padding-left: var(--bs-gutter-x, 0.75rem);
   margin-right: auto;
   margin-left: auto; }
 
 @media (min-width: 576px) {
   .container, .container-sm {
     max-width: 540px; } }
 
 @media (min-width: 768px) {
   .container, .container-sm, .container-md {
     max-width: 720px; } }
 
 @media (min-width: 992px) {
   .container, .container-sm, .container-md, .container-lg {
     max-width: 960px; } }
 
 @media (min-width: 1200px) {
   .container, .container-sm, .container-md, .container-lg, .container-xl {
     max-width: 1140px; } }
 
 @media (min-width: 1400px) {
   .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
     max-width: 1320px; } }
 
 .row {
   --bs-gutter-x: 1.5rem;
   --bs-gutter-y: 0;
   display: flex;
   flex-wrap: wrap;
   margin-top: calc(var(--bs-gutter-y) * -1);
   margin-right: calc(var(--bs-gutter-x) / -2);
   margin-left: calc(var(--bs-gutter-x) / -2); }
   .row > * {
     flex-shrink: 0;
     width: 100%;
     max-width: 100%;
     padding-right: calc(var(--bs-gutter-x) / 2);
     padding-left: calc(var(--bs-gutter-x) / 2);
     margin-top: var(--bs-gutter-y); }
 
 .col {
   flex: 1 0 0%; }
 
 .row-cols-auto > * {
   flex: 0 0 auto;
   width: auto; }
 
 .row-cols-1 > * {
   flex: 0 0 auto;
   width: 100%; }
 
 .row-cols-2 > * {
   flex: 0 0 auto;
   width: 50%; }
 
 .row-cols-3 > * {
   flex: 0 0 auto;
   width: 33.33333%; }
 
 .row-cols-4 > * {
   flex: 0 0 auto;
   width: 25%; }
 
 .row-cols-5 > * {
   flex: 0 0 auto;
   width: 20%; }
 
 .row-cols-6 > * {
   flex: 0 0 auto;
   width: 16.66667%; }
 
 .col-auto {
   flex: 0 0 auto;
   width: auto; }
 
 .col-1 {
   flex: 0 0 auto;
   width: 8.33333%; }
 
 .col-2 {
   flex: 0 0 auto;
   width: 16.66667%; }
 
 .col-3 {
   flex: 0 0 auto;
   width: 25%; }
 
 .col-4 {
   flex: 0 0 auto;
   width: 33.33333%; }
 
 .col-5 {
   flex: 0 0 auto;
   width: 41.66667%; }
 
 .col-6 {
   flex: 0 0 auto;
   width: 50%; }
 
 .col-7 {
   flex: 0 0 auto;
   width: 58.33333%; }
 
 .col-8 {
   flex: 0 0 auto;
   width: 66.66667%; }
 
 .col-9 {
   flex: 0 0 auto;
   width: 75%; }
 
 .col-10 {
   flex: 0 0 auto;
   width: 83.33333%; }
 
 .col-11 {
   flex: 0 0 auto;
   width: 91.66667%; }
 
 .col-12 {
   flex: 0 0 auto;
   width: 100%; }
 
 .offset-1 {
   margin-left: 8.33333%; }
 
 .offset-2 {
   margin-left: 16.66667%; }
 
 .offset-3 {
   margin-left: 25%; }
 
 .offset-4 {
   margin-left: 33.33333%; }
 
 .offset-5 {
   margin-left: 41.66667%; }
 
 .offset-6 {
   margin-left: 50%; }
 
 .offset-7 {
   margin-left: 58.33333%; }
 
 .offset-8 {
   margin-left: 66.66667%; }
 
 .offset-9 {
   margin-left: 75%; }
 
 .offset-10 {
   margin-left: 83.33333%; }
 
 .offset-11 {
   margin-left: 91.66667%; }
 
 .g-0,
 .gx-0 {
   --bs-gutter-x: 0; }
 
 .g-0,
 .gy-0 {
   --bs-gutter-y: 0; }
 
 .g-1,
 .gx-1 {
   --bs-gutter-x: 0.25rem; }
 
 .g-1,
 .gy-1 {
   --bs-gutter-y: 0.25rem; }
 
 .g-2,
 .gx-2 {
   --bs-gutter-x: 0.5rem; }
 
 .g-2,
 .gy-2 {
   --bs-gutter-y: 0.5rem; }
 
 .g-3,
 .gx-3 {
   --bs-gutter-x: 1rem; }
 
 .g-3,
 .gy-3 {
   --bs-gutter-y: 1rem; }
 
 .g-4,
 .gx-4 {
   --bs-gutter-x: 1.5rem; }
 
 .g-4,
 .gy-4 {
   --bs-gutter-y: 1.5rem; }
 
 .g-5,
 .gx-5 {
   --bs-gutter-x: 2rem; }
 
 .g-5,
 .gy-5 {
   --bs-gutter-y: 2rem; }
 
 .g-6,
 .gx-6 {
   --bs-gutter-x: 2.5rem; }
 
 .g-6,
 .gy-6 {
   --bs-gutter-y: 2.5rem; }
 
 .g-7,
 .gx-7 {
   --bs-gutter-x: 3rem; }
 
 .g-7,
 .gy-7 {
   --bs-gutter-y: 3rem; }
 
 .g-8,
 .gx-8 {
   --bs-gutter-x: 3.5rem; }
 
 .g-8,
 .gy-8 {
   --bs-gutter-y: 3.5rem; }
 
 .g-9,
 .gx-9 {
   --bs-gutter-x: 4rem; }
 
 .g-9,
 .gy-9 {
   --bs-gutter-y: 4rem; }
 
 .g-10,
 .gx-10 {
   --bs-gutter-x: 5rem; }
 
 .g-10,
 .gy-10 {
   --bs-gutter-y: 5rem; }
 
 .g-11,
 .gx-11 {
   --bs-gutter-x: 6rem; }
 
 .g-11,
 .gy-11 {
   --bs-gutter-y: 6rem; }
 
 .g-12,
 .gx-12 {
   --bs-gutter-x: 7rem; }
 
 .g-12,
 .gy-12 {
   --bs-gutter-y: 7rem; }
 
 @media (min-width: 576px) {
   .col-sm {
     flex: 1 0 0%; }
   .row-cols-sm-auto > * {
     flex: 0 0 auto;
     width: auto; }
   .row-cols-sm-1 > * {
     flex: 0 0 auto;
     width: 100%; }
   .row-cols-sm-2 > * {
     flex: 0 0 auto;
     width: 50%; }
   .row-cols-sm-3 > * {
     flex: 0 0 auto;
     width: 33.33333%; }
   .row-cols-sm-4 > * {
     flex: 0 0 auto;
     width: 25%; }
   .row-cols-sm-5 > * {
     flex: 0 0 auto;
     width: 20%; }
   .row-cols-sm-6 > * {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-sm-auto {
     flex: 0 0 auto;
     width: auto; }
   .col-sm-1 {
     flex: 0 0 auto;
     width: 8.33333%; }
   .col-sm-2 {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-sm-3 {
     flex: 0 0 auto;
     width: 25%; }
   .col-sm-4 {
     flex: 0 0 auto;
     width: 33.33333%; }
   .col-sm-5 {
     flex: 0 0 auto;
     width: 41.66667%; }
   .col-sm-6 {
     flex: 0 0 auto;
     width: 50%; }
   .col-sm-7 {
     flex: 0 0 auto;
     width: 58.33333%; }
   .col-sm-8 {
     flex: 0 0 auto;
     width: 66.66667%; }
   .col-sm-9 {
     flex: 0 0 auto;
     width: 75%; }
   .col-sm-10 {
     flex: 0 0 auto;
     width: 83.33333%; }
   .col-sm-11 {
     flex: 0 0 auto;
     width: 91.66667%; }
   .col-sm-12 {
     flex: 0 0 auto;
     width: 100%; }
   .offset-sm-0 {
     margin-left: 0; }
   .offset-sm-1 {
     margin-left: 8.33333%; }
   .offset-sm-2 {
     margin-left: 16.66667%; }
   .offset-sm-3 {
     margin-left: 25%; }
   .offset-sm-4 {
     margin-left: 33.33333%; }
   .offset-sm-5 {
     margin-left: 41.66667%; }
   .offset-sm-6 {
     margin-left: 50%; }
   .offset-sm-7 {
     margin-left: 58.33333%; }
   .offset-sm-8 {
     margin-left: 66.66667%; }
   .offset-sm-9 {
     margin-left: 75%; }
   .offset-sm-10 {
     margin-left: 83.33333%; }
   .offset-sm-11 {
     margin-left: 91.66667%; }
   .g-sm-0,
   .gx-sm-0 {
     --bs-gutter-x: 0; }
   .g-sm-0,
   .gy-sm-0 {
     --bs-gutter-y: 0; }
   .g-sm-1,
   .gx-sm-1 {
     --bs-gutter-x: 0.25rem; }
   .g-sm-1,
   .gy-sm-1 {
     --bs-gutter-y: 0.25rem; }
   .g-sm-2,
   .gx-sm-2 {
     --bs-gutter-x: 0.5rem; }
   .g-sm-2,
   .gy-sm-2 {
     --bs-gutter-y: 0.5rem; }
   .g-sm-3,
   .gx-sm-3 {
     --bs-gutter-x: 1rem; }
   .g-sm-3,
   .gy-sm-3 {
     --bs-gutter-y: 1rem; }
   .g-sm-4,
   .gx-sm-4 {
     --bs-gutter-x: 1.5rem; }
   .g-sm-4,
   .gy-sm-4 {
     --bs-gutter-y: 1.5rem; }
   .g-sm-5,
   .gx-sm-5 {
     --bs-gutter-x: 2rem; }
   .g-sm-5,
   .gy-sm-5 {
     --bs-gutter-y: 2rem; }
   .g-sm-6,
   .gx-sm-6 {
     --bs-gutter-x: 2.5rem; }
   .g-sm-6,
   .gy-sm-6 {
     --bs-gutter-y: 2.5rem; }
   .g-sm-7,
   .gx-sm-7 {
     --bs-gutter-x: 3rem; }
   .g-sm-7,
   .gy-sm-7 {
     --bs-gutter-y: 3rem; }
   .g-sm-8,
   .gx-sm-8 {
     --bs-gutter-x: 3.5rem; }
   .g-sm-8,
   .gy-sm-8 {
     --bs-gutter-y: 3.5rem; }
   .g-sm-9,
   .gx-sm-9 {
     --bs-gutter-x: 4rem; }
   .g-sm-9,
   .gy-sm-9 {
     --bs-gutter-y: 4rem; }
   .g-sm-10,
   .gx-sm-10 {
     --bs-gutter-x: 5rem; }
   .g-sm-10,
   .gy-sm-10 {
     --bs-gutter-y: 5rem; }
   .g-sm-11,
   .gx-sm-11 {
     --bs-gutter-x: 6rem; }
   .g-sm-11,
   .gy-sm-11 {
     --bs-gutter-y: 6rem; }
   .g-sm-12,
   .gx-sm-12 {
     --bs-gutter-x: 7rem; }
   .g-sm-12,
   .gy-sm-12 {
     --bs-gutter-y: 7rem; } }
 
 @media (min-width: 768px) {
   .col-md {
     flex: 1 0 0%; }
   .row-cols-md-auto > * {
     flex: 0 0 auto;
     width: auto; }
   .row-cols-md-1 > * {
     flex: 0 0 auto;
     width: 100%; }
   .row-cols-md-2 > * {
     flex: 0 0 auto;
     width: 50%; }
   .row-cols-md-3 > * {
     flex: 0 0 auto;
     width: 33.33333%; }
   .row-cols-md-4 > * {
     flex: 0 0 auto;
     width: 25%; }
   .row-cols-md-5 > * {
     flex: 0 0 auto;
     width: 20%; }
   .row-cols-md-6 > * {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-md-auto {
     flex: 0 0 auto;
     width: auto; }
   .col-md-1 {
     flex: 0 0 auto;
     width: 8.33333%; }
   .col-md-2 {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-md-3 {
     flex: 0 0 auto;
     width: 25%; }
   .col-md-4 {
     flex: 0 0 auto;
     width: 33.33333%; }
   .col-md-5 {
     flex: 0 0 auto;
     width: 41.66667%; }
   .col-md-6 {
     flex: 0 0 auto;
     width: 50%; }
   .col-md-7 {
     flex: 0 0 auto;
     width: 58.33333%; }
   .col-md-8 {
     flex: 0 0 auto;
     width: 66.66667%; }
   .col-md-9 {
     flex: 0 0 auto;
     width: 75%; }
   .col-md-10 {
     flex: 0 0 auto;
     width: 83.33333%; }
   .col-md-11 {
     flex: 0 0 auto;
     width: 91.66667%; }
   .col-md-12 {
     flex: 0 0 auto;
     width: 100%; }
   .offset-md-0 {
     margin-left: 0; }
   .offset-md-1 {
     margin-left: 8.33333%; }
   .offset-md-2 {
     margin-left: 16.66667%; }
   .offset-md-3 {
     margin-left: 25%; }
   .offset-md-4 {
     margin-left: 33.33333%; }
   .offset-md-5 {
     margin-left: 41.66667%; }
   .offset-md-6 {
     margin-left: 50%; }
   .offset-md-7 {
     margin-left: 58.33333%; }
   .offset-md-8 {
     margin-left: 66.66667%; }
   .offset-md-9 {
     margin-left: 75%; }
   .offset-md-10 {
     margin-left: 83.33333%; }
   .offset-md-11 {
     margin-left: 91.66667%; }
   .g-md-0,
   .gx-md-0 {
     --bs-gutter-x: 0; }
   .g-md-0,
   .gy-md-0 {
     --bs-gutter-y: 0; }
   .g-md-1,
   .gx-md-1 {
     --bs-gutter-x: 0.25rem; }
   .g-md-1,
   .gy-md-1 {
     --bs-gutter-y: 0.25rem; }
   .g-md-2,
   .gx-md-2 {
     --bs-gutter-x: 0.5rem; }
   .g-md-2,
   .gy-md-2 {
     --bs-gutter-y: 0.5rem; }
   .g-md-3,
   .gx-md-3 {
     --bs-gutter-x: 1rem; }
   .g-md-3,
   .gy-md-3 {
     --bs-gutter-y: 1rem; }
   .g-md-4,
   .gx-md-4 {
     --bs-gutter-x: 1.5rem; }
   .g-md-4,
   .gy-md-4 {
     --bs-gutter-y: 1.5rem; }
   .g-md-5,
   .gx-md-5 {
     --bs-gutter-x: 2rem; }
   .g-md-5,
   .gy-md-5 {
     --bs-gutter-y: 2rem; }
   .g-md-6,
   .gx-md-6 {
     --bs-gutter-x: 2.5rem; }
   .g-md-6,
   .gy-md-6 {
     --bs-gutter-y: 2.5rem; }
   .g-md-7,
   .gx-md-7 {
     --bs-gutter-x: 3rem; }
   .g-md-7,
   .gy-md-7 {
     --bs-gutter-y: 3rem; }
   .g-md-8,
   .gx-md-8 {
     --bs-gutter-x: 3.5rem; }
   .g-md-8,
   .gy-md-8 {
     --bs-gutter-y: 3.5rem; }
   .g-md-9,
   .gx-md-9 {
     --bs-gutter-x: 4rem; }
   .g-md-9,
   .gy-md-9 {
     --bs-gutter-y: 4rem; }
   .g-md-10,
   .gx-md-10 {
     --bs-gutter-x: 5rem; }
   .g-md-10,
   .gy-md-10 {
     --bs-gutter-y: 5rem; }
   .g-md-11,
   .gx-md-11 {
     --bs-gutter-x: 6rem; }
   .g-md-11,
   .gy-md-11 {
     --bs-gutter-y: 6rem; }
   .g-md-12,
   .gx-md-12 {
     --bs-gutter-x: 7rem; }
   .g-md-12,
   .gy-md-12 {
     --bs-gutter-y: 7rem; } }
 
 @media (min-width: 992px) {
   .col-lg {
     flex: 1 0 0%; }
   .row-cols-lg-auto > * {
     flex: 0 0 auto;
     width: auto; }
   .row-cols-lg-1 > * {
     flex: 0 0 auto;
     width: 100%; }
   .row-cols-lg-2 > * {
     flex: 0 0 auto;
     width: 50%; }
   .row-cols-lg-3 > * {
     flex: 0 0 auto;
     width: 33.33333%; }
   .row-cols-lg-4 > * {
     flex: 0 0 auto;
     width: 25%; }
   .row-cols-lg-5 > * {
     flex: 0 0 auto;
     width: 20%; }
   .row-cols-lg-6 > * {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-lg-auto {
     flex: 0 0 auto;
     width: auto; }
   .col-lg-1 {
     flex: 0 0 auto;
     width: 8.33333%; }
   .col-lg-2 {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-lg-3 {
     flex: 0 0 auto;
     width: 25%; }
   .col-lg-4 {
     flex: 0 0 auto;
     width: 33.33333%; }
   .col-lg-5 {
     flex: 0 0 auto;
     width: 41.66667%; }
   .col-lg-6 {
     flex: 0 0 auto;
     width: 50%; }
   .col-lg-7 {
     flex: 0 0 auto;
     width: 58.33333%; }
   .col-lg-8 {
     flex: 0 0 auto;
     width: 66.66667%; }
   .col-lg-9 {
     flex: 0 0 auto;
     width: 75%; }
   .col-lg-10 {
     flex: 0 0 auto;
     width: 83.33333%; }
   .col-lg-11 {
     flex: 0 0 auto;
     width: 91.66667%; }
   .col-lg-12 {
     flex: 0 0 auto;
     width: 100%; }
   .offset-lg-0 {
     margin-left: 0; }
   .offset-lg-1 {
     margin-left: 8.33333%; }
   .offset-lg-2 {
     margin-left: 16.66667%; }
   .offset-lg-3 {
     margin-left: 25%; }
   .offset-lg-4 {
     margin-left: 33.33333%; }
   .offset-lg-5 {
     margin-left: 41.66667%; }
   .offset-lg-6 {
     margin-left: 50%; }
   .offset-lg-7 {
     margin-left: 58.33333%; }
   .offset-lg-8 {
     margin-left: 66.66667%; }
   .offset-lg-9 {
     margin-left: 75%; }
   .offset-lg-10 {
     margin-left: 83.33333%; }
   .offset-lg-11 {
     margin-left: 91.66667%; }
   .g-lg-0,
   .gx-lg-0 {
     --bs-gutter-x: 0; }
   .g-lg-0,
   .gy-lg-0 {
     --bs-gutter-y: 0; }
   .g-lg-1,
   .gx-lg-1 {
     --bs-gutter-x: 0.25rem; }
   .g-lg-1,
   .gy-lg-1 {
     --bs-gutter-y: 0.25rem; }
   .g-lg-2,
   .gx-lg-2 {
     --bs-gutter-x: 0.5rem; }
   .g-lg-2,
   .gy-lg-2 {
     --bs-gutter-y: 0.5rem; }
   .g-lg-3,
   .gx-lg-3 {
     --bs-gutter-x: 1rem; }
   .g-lg-3,
   .gy-lg-3 {
     --bs-gutter-y: 1rem; }
   .g-lg-4,
   .gx-lg-4 {
     --bs-gutter-x: 1.5rem; }
   .g-lg-4,
   .gy-lg-4 {
     --bs-gutter-y: 1.5rem; }
   .g-lg-5,
   .gx-lg-5 {
     --bs-gutter-x: 2rem; }
   .g-lg-5,
   .gy-lg-5 {
     --bs-gutter-y: 2rem; }
   .g-lg-6,
   .gx-lg-6 {
     --bs-gutter-x: 2.5rem; }
   .g-lg-6,
   .gy-lg-6 {
     --bs-gutter-y: 2.5rem; }
   .g-lg-7,
   .gx-lg-7 {
     --bs-gutter-x: 3rem; }
   .g-lg-7,
   .gy-lg-7 {
     --bs-gutter-y: 3rem; }
   .g-lg-8,
   .gx-lg-8 {
     --bs-gutter-x: 3.5rem; }
   .g-lg-8,
   .gy-lg-8 {
     --bs-gutter-y: 3.5rem; }
   .g-lg-9,
   .gx-lg-9 {
     --bs-gutter-x: 4rem; }
   .g-lg-9,
   .gy-lg-9 {
     --bs-gutter-y: 4rem; }
   .g-lg-10,
   .gx-lg-10 {
     --bs-gutter-x: 5rem; }
   .g-lg-10,
   .gy-lg-10 {
     --bs-gutter-y: 5rem; }
   .g-lg-11,
   .gx-lg-11 {
     --bs-gutter-x: 6rem; }
   .g-lg-11,
   .gy-lg-11 {
     --bs-gutter-y: 6rem; }
   .g-lg-12,
   .gx-lg-12 {
     --bs-gutter-x: 7rem; }
   .g-lg-12,
   .gy-lg-12 {
     --bs-gutter-y: 7rem; } }
 
 @media (min-width: 1200px) {
   .col-xl {
     flex: 1 0 0%; }
   .row-cols-xl-auto > * {
     flex: 0 0 auto;
     width: auto; }
   .row-cols-xl-1 > * {
     flex: 0 0 auto;
     width: 100%; }
   .row-cols-xl-2 > * {
     flex: 0 0 auto;
     width: 50%; }
   .row-cols-xl-3 > * {
     flex: 0 0 auto;
     width: 33.33333%; }
   .row-cols-xl-4 > * {
     flex: 0 0 auto;
     width: 25%; }
   .row-cols-xl-5 > * {
     flex: 0 0 auto;
     width: 20%; }
   .row-cols-xl-6 > * {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-xl-auto {
     flex: 0 0 auto;
     width: auto; }
   .col-xl-1 {
     flex: 0 0 auto;
     width: 8.33333%; }
   .col-xl-2 {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-xl-3 {
     flex: 0 0 auto;
     width: 25%; }
   .col-xl-4 {
     flex: 0 0 auto;
     width: 33.33333%; }
   .col-xl-5 {
     flex: 0 0 auto;
     width: 41.66667%; }
   .col-xl-6 {
     flex: 0 0 auto;
     width: 50%; }
   .col-xl-7 {
     flex: 0 0 auto;
     width: 58.33333%; }
   .col-xl-8 {
     flex: 0 0 auto;
     width: 66.66667%; }
   .col-xl-9 {
     flex: 0 0 auto;
     width: 75%; }
   .col-xl-10 {
     flex: 0 0 auto;
     width: 83.33333%; }
   .col-xl-11 {
     flex: 0 0 auto;
     width: 91.66667%; }
   .col-xl-12 {
     flex: 0 0 auto;
     width: 100%; }
   .offset-xl-0 {
     margin-left: 0; }
   .offset-xl-1 {
     margin-left: 8.33333%; }
   .offset-xl-2 {
     margin-left: 16.66667%; }
   .offset-xl-3 {
     margin-left: 25%; }
   .offset-xl-4 {
     margin-left: 33.33333%; }
   .offset-xl-5 {
     margin-left: 41.66667%; }
   .offset-xl-6 {
     margin-left: 50%; }
   .offset-xl-7 {
     margin-left: 58.33333%; }
   .offset-xl-8 {
     margin-left: 66.66667%; }
   .offset-xl-9 {
     margin-left: 75%; }
   .offset-xl-10 {
     margin-left: 83.33333%; }
   .offset-xl-11 {
     margin-left: 91.66667%; }
   .g-xl-0,
   .gx-xl-0 {
     --bs-gutter-x: 0; }
   .g-xl-0,
   .gy-xl-0 {
     --bs-gutter-y: 0; }
   .g-xl-1,
   .gx-xl-1 {
     --bs-gutter-x: 0.25rem; }
   .g-xl-1,
   .gy-xl-1 {
     --bs-gutter-y: 0.25rem; }
   .g-xl-2,
   .gx-xl-2 {
     --bs-gutter-x: 0.5rem; }
   .g-xl-2,
   .gy-xl-2 {
     --bs-gutter-y: 0.5rem; }
   .g-xl-3,
   .gx-xl-3 {
     --bs-gutter-x: 1rem; }
   .g-xl-3,
   .gy-xl-3 {
     --bs-gutter-y: 1rem; }
   .g-xl-4,
   .gx-xl-4 {
     --bs-gutter-x: 1.5rem; }
   .g-xl-4,
   .gy-xl-4 {
     --bs-gutter-y: 1.5rem; }
   .g-xl-5,
   .gx-xl-5 {
     --bs-gutter-x: 2rem; }
   .g-xl-5,
   .gy-xl-5 {
     --bs-gutter-y: 2rem; }
   .g-xl-6,
   .gx-xl-6 {
     --bs-gutter-x: 2.5rem; }
   .g-xl-6,
   .gy-xl-6 {
     --bs-gutter-y: 2.5rem; }
   .g-xl-7,
   .gx-xl-7 {
     --bs-gutter-x: 3rem; }
   .g-xl-7,
   .gy-xl-7 {
     --bs-gutter-y: 3rem; }
   .g-xl-8,
   .gx-xl-8 {
     --bs-gutter-x: 3.5rem; }
   .g-xl-8,
   .gy-xl-8 {
     --bs-gutter-y: 3.5rem; }
   .g-xl-9,
   .gx-xl-9 {
     --bs-gutter-x: 4rem; }
   .g-xl-9,
   .gy-xl-9 {
     --bs-gutter-y: 4rem; }
   .g-xl-10,
   .gx-xl-10 {
     --bs-gutter-x: 5rem; }
   .g-xl-10,
   .gy-xl-10 {
     --bs-gutter-y: 5rem; }
   .g-xl-11,
   .gx-xl-11 {
     --bs-gutter-x: 6rem; }
   .g-xl-11,
   .gy-xl-11 {
     --bs-gutter-y: 6rem; }
   .g-xl-12,
   .gx-xl-12 {
     --bs-gutter-x: 7rem; }
   .g-xl-12,
   .gy-xl-12 {
     --bs-gutter-y: 7rem; } }
 
 @media (min-width: 1400px) {
   .col-xxl {
     flex: 1 0 0%; }
   .row-cols-xxl-auto > * {
     flex: 0 0 auto;
     width: auto; }
   .row-cols-xxl-1 > * {
     flex: 0 0 auto;
     width: 100%; }
   .row-cols-xxl-2 > * {
     flex: 0 0 auto;
     width: 50%; }
   .row-cols-xxl-3 > * {
     flex: 0 0 auto;
     width: 33.33333%; }
   .row-cols-xxl-4 > * {
     flex: 0 0 auto;
     width: 25%; }
   .row-cols-xxl-5 > * {
     flex: 0 0 auto;
     width: 20%; }
   .row-cols-xxl-6 > * {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-xxl-auto {
     flex: 0 0 auto;
     width: auto; }
   .col-xxl-1 {
     flex: 0 0 auto;
     width: 8.33333%; }
   .col-xxl-2 {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-xxl-3 {
     flex: 0 0 auto;
     width: 25%; }
   .col-xxl-4 {
     flex: 0 0 auto;
     width: 33.33333%; }
   .col-xxl-5 {
     flex: 0 0 auto;
     width: 41.66667%; }
   .col-xxl-6 {
     flex: 0 0 auto;
     width: 50%; }
   .col-xxl-7 {
     flex: 0 0 auto;
     width: 58.33333%; }
   .col-xxl-8 {
     flex: 0 0 auto;
     width: 66.66667%; }
   .col-xxl-9 {
     flex: 0 0 auto;
     width: 75%; }
   .col-xxl-10 {
     flex: 0 0 auto;
     width: 83.33333%; }
   .col-xxl-11 {
     flex: 0 0 auto;
     width: 91.66667%; }
   .col-xxl-12 {
     flex: 0 0 auto;
     width: 100%; }
   .offset-xxl-0 {
     margin-left: 0; }
   .offset-xxl-1 {
     margin-left: 8.33333%; }
   .offset-xxl-2 {
     margin-left: 16.66667%; }
   .offset-xxl-3 {
     margin-left: 25%; }
   .offset-xxl-4 {
     margin-left: 33.33333%; }
   .offset-xxl-5 {
     margin-left: 41.66667%; }
   .offset-xxl-6 {
     margin-left: 50%; }
   .offset-xxl-7 {
     margin-left: 58.33333%; }
   .offset-xxl-8 {
     margin-left: 66.66667%; }
   .offset-xxl-9 {
     margin-left: 75%; }
   .offset-xxl-10 {
     margin-left: 83.33333%; }
   .offset-xxl-11 {
     margin-left: 91.66667%; }
   .g-xxl-0,
   .gx-xxl-0 {
     --bs-gutter-x: 0; }
   .g-xxl-0,
   .gy-xxl-0 {
     --bs-gutter-y: 0; }
   .g-xxl-1,
   .gx-xxl-1 {
     --bs-gutter-x: 0.25rem; }
   .g-xxl-1,
   .gy-xxl-1 {
     --bs-gutter-y: 0.25rem; }
   .g-xxl-2,
   .gx-xxl-2 {
     --bs-gutter-x: 0.5rem; }
   .g-xxl-2,
   .gy-xxl-2 {
     --bs-gutter-y: 0.5rem; }
   .g-xxl-3,
   .gx-xxl-3 {
     --bs-gutter-x: 1rem; }
   .g-xxl-3,
   .gy-xxl-3 {
     --bs-gutter-y: 1rem; }
   .g-xxl-4,
   .gx-xxl-4 {
     --bs-gutter-x: 1.5rem; }
   .g-xxl-4,
   .gy-xxl-4 {
     --bs-gutter-y: 1.5rem; }
   .g-xxl-5,
   .gx-xxl-5 {
     --bs-gutter-x: 2rem; }
   .g-xxl-5,
   .gy-xxl-5 {
     --bs-gutter-y: 2rem; }
   .g-xxl-6,
   .gx-xxl-6 {
     --bs-gutter-x: 2.5rem; }
   .g-xxl-6,
   .gy-xxl-6 {
     --bs-gutter-y: 2.5rem; }
   .g-xxl-7,
   .gx-xxl-7 {
     --bs-gutter-x: 3rem; }
   .g-xxl-7,
   .gy-xxl-7 {
     --bs-gutter-y: 3rem; }
   .g-xxl-8,
   .gx-xxl-8 {
     --bs-gutter-x: 3.5rem; }
   .g-xxl-8,
   .gy-xxl-8 {
     --bs-gutter-y: 3.5rem; }
   .g-xxl-9,
   .gx-xxl-9 {
     --bs-gutter-x: 4rem; }
   .g-xxl-9,
   .gy-xxl-9 {
     --bs-gutter-y: 4rem; }
   .g-xxl-10,
   .gx-xxl-10 {
     --bs-gutter-x: 5rem; }
   .g-xxl-10,
   .gy-xxl-10 {
     --bs-gutter-y: 5rem; }
   .g-xxl-11,
   .gx-xxl-11 {
     --bs-gutter-x: 6rem; }
   .g-xxl-11,
   .gy-xxl-11 {
     --bs-gutter-y: 6rem; }
   .g-xxl-12,
   .gx-xxl-12 {
     --bs-gutter-x: 7rem; }
   .g-xxl-12,
   .gy-xxl-12 {
     --bs-gutter-y: 7rem; } }
 
 .table {
   --bs-table-bg: transparent;
   --bs-table-striped-color: #8F9BAD;
   --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
   --bs-table-active-color: #8F9BAD;
   --bs-table-active-bg: rgba(0, 0, 0, 0.1);
   --bs-table-hover-color: #8F9BAD;
   --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
   width: 100%;
   margin-bottom: 1rem;
   color: #8F9BAD;
   vertical-align: top;
   border-color: #e2e8f0; }
   .table > :not(caption) > * > * {
     padding: 0.5rem 0.5rem;
     background-color: var(--bs-table-bg);
     background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
     border-bottom-width: 1px; }
   .table > tbody {
     vertical-align: inherit; }
   .table > thead {
     vertical-align: bottom; }
   .table > :not(:last-child) > :last-child > * {
     border-bottom-color: currentColor; }
 
 .caption-top {
   caption-side: top; }
 
 .table-sm > :not(caption) > * > * {
   padding: 0.25rem 0.25rem; }
 
 .table-bordered > :not(caption) > * {
   border-width: 1px 0; }
   .table-bordered > :not(caption) > * > * {
     border-width: 0 1px; }
 
 .table-borderless > :not(caption) > * > * {
   border-bottom-width: 0; }
 
 .table-striped > tbody > tr:nth-of-type(odd) {
   --bs-table-accent-bg: var(--bs-table-striped-bg);
   color: var(--bs-table-striped-color); }
 
 .table-active {
   --bs-table-accent-bg: var(--bs-table-active-bg);
   color: var(--bs-table-active-color); }
 
 .table-hover > tbody > tr:hover {
   --bs-table-accent-bg: var(--bs-table-hover-bg);
   color: var(--bs-table-hover-color); }
 
 .table-primary {
   --bs-table-bg: #ffdce2;
   --bs-table-striped-bg: #f2d1d7;
   --bs-table-striped-color: #000;
   --bs-table-active-bg: #e6c6cb;
   --bs-table-active-color: #000;
   --bs-table-hover-bg: #ecccd1;
   --bs-table-hover-color: #000;
   color: #000;
   border-color: #e6c6cb; }
 
 .table-secondary {
   --bs-table-bg: #e2e3e5;
   --bs-table-striped-bg: #d7d8da;
   --bs-table-striped-color: #000;
   --bs-table-active-bg: #cbccce;
   --bs-table-active-color: #000;
   --bs-table-hover-bg: #d1d2d4;
   --bs-table-hover-color: #000;
   color: #000;
   border-color: #cbccce; }
 
 .table-success {
   --bs-table-bg: #d5f4e8;
   --bs-table-striped-bg: #cae8dc;
   --bs-table-striped-color: #000;
   --bs-table-active-bg: #c0dcd1;
   --bs-table-active-color: #000;
   --bs-table-hover-bg: #c5e2d7;
   --bs-table-hover-color: #000;
   color: #000;
   border-color: #c0dcd1; }
 
 .table-info {
   --bs-table-bg: #dcf0ff;
   --bs-table-striped-bg: #d1e4f2;
   --bs-table-striped-color: #000;
   --bs-table-active-bg: #c6d8e6;
   --bs-table-active-color: #000;
   --bs-table-hover-bg: #ccdeec;
   --bs-table-hover-color: #000;
   color: #000;
   border-color: #c6d8e6; }
 
 .table-warning {
   --bs-table-bg: #ffecd2;
   --bs-table-striped-bg: #f2e0c8;
   --bs-table-striped-color: #000;
   --bs-table-active-bg: #e6d4bd;
   --bs-table-active-color: #000;
   --bs-table-hover-bg: #ecdac2;
   --bs-table-hover-color: #000;
   color: #000;
   border-color: #e6d4bd; }
 
 .table-danger {
   --bs-table-bg: #ffdee3;
   --bs-table-striped-bg: #f2d3d8;
   --bs-table-striped-color: #000;
   --bs-table-active-bg: #e6c8cc;
   --bs-table-active-color: #000;
   --bs-table-hover-bg: #eccdd2;
   --bs-table-hover-color: #000;
   color: #000;
   border-color: #e6c8cc; }
 
 .table-light {
   --bs-table-bg: #f1f6fd;
   --bs-table-striped-bg: #e5eaf0;
   --bs-table-striped-color: #000;
   --bs-table-active-bg: #d9dde4;
   --bs-table-active-color: #000;
   --bs-table-hover-bg: #dfe4ea;
   --bs-table-hover-color: #000;
   color: #000;
   border-color: #d9dde4; }
 
 .table-dark {
   --bs-table-bg: #203656;
   --bs-table-striped-bg: #2b405e;
   --bs-table-striped-color: #ffffff;
   --bs-table-active-bg: #364a67;
   --bs-table-active-color: #ffffff;
   --bs-table-hover-bg: #314563;
   --bs-table-hover-color: #ffffff;
   color: #ffffff;
   border-color: #364a67; }
 
 .table-responsive {
   overflow-x: auto;
   -webkit-overflow-scrolling: touch; }
 
 @media (max-width: 575.98px) {
   .table-responsive-sm {
     overflow-x: auto;
     -webkit-overflow-scrolling: touch; } }
 
 @media (max-width: 767.98px) {
   .table-responsive-md {
     overflow-x: auto;
     -webkit-overflow-scrolling: touch; } }
 
 @media (max-width: 991.98px) {
   .table-responsive-lg {
     overflow-x: auto;
     -webkit-overflow-scrolling: touch; } }
 
 @media (max-width: 1199.98px) {
   .table-responsive-xl {
     overflow-x: auto;
     -webkit-overflow-scrolling: touch; } }
 
 @media (max-width: 1399.98px) {
   .table-responsive-xxl {
     overflow-x: auto;
     -webkit-overflow-scrolling: touch; } }
 
 .form-label {
   margin-bottom: 0.5rem; }
 
 .col-form-label {
   padding-top: calc(0.65rem + 1px);
   padding-bottom: calc(0.65rem + 1px);
   margin-bottom: 0;
   font-size: inherit;
   line-height: 1.7; }
 
 .col-form-label-lg {
   padding-top: calc(1rem + 1px);
   padding-bottom: calc(1rem + 1px);
   font-size: 1rem; }
 
 .col-form-label-sm {
   padding-top: calc(0.5rem + 1px);
   padding-bottom: calc(0.5rem + 1px);
   font-size: 0.875rem; }
 
 .form-text {
   margin-top: 0.25rem;
   font-size: 0.875em;
   color: #3a3a3a; }
 
 .form-control {
   display: block;
   width: 100%;
   padding: 0.65rem 1.25rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.7;
   color: #8F9BAD;
   background-color: #ffffff;
   background-clip: padding-box;
   border: 1px solid #cbd5e0;
   appearance: none;
   border-radius: 0.25rem;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
   @media (prefers-reduced-motion: reduce) {
     .form-control {
       transition: none; } }
   .form-control[type="file"] {
     overflow: hidden; }
     .form-control[type="file"]:not(:disabled):not([readonly]) {
       cursor: pointer; }
   .form-control:focus {
     color: #8F9BAD;
     background-color: #ffffff;
     border-color: #ffa7b8;
     outline: 0;
     box-shadow: 0 0 0 0 transparent; }
   .form-control::-webkit-date-and-time-value {
     height: 1.7em; }
   .form-control::placeholder {
     color: #3a3a3a;
     opacity: 1; }
   .form-control:disabled, .form-control[readonly] {
     background-color: #eff2f7;
     opacity: 1; }
   .form-control::file-selector-button {
     padding: 0.65rem 1.25rem;
     margin: -0.65rem -1.25rem;
     margin-inline-end: 1.25rem;
     color: #8F9BAD;
     background-color: #eff2f7;
     pointer-events: none;
     border-color: inherit;
     border-style: solid;
     border-width: 0;
     border-inline-end-width: 1px;
     border-radius: 0;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
     @media (prefers-reduced-motion: reduce) {
       .form-control::file-selector-button {
         transition: none; } }
   .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
     background-color: #e3e6eb; }
   .form-control::-webkit-file-upload-button {
     padding: 0.65rem 1.25rem;
     margin: -0.65rem -1.25rem;
     margin-inline-end: 1.25rem;
     color: #8F9BAD;
     background-color: #eff2f7;
     pointer-events: none;
     border-color: inherit;
     border-style: solid;
     border-width: 0;
     border-inline-end-width: 1px;
     border-radius: 0;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
     @media (prefers-reduced-motion: reduce) {
       .form-control::-webkit-file-upload-button {
         transition: none; } }
   .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
     background-color: #e3e6eb; }
 
 .form-control-plaintext {
   display: block;
   width: 100%;
   padding: 0.65rem 0;
   margin-bottom: 0;
   line-height: 1.7;
   color: #8F9BAD;
   background-color: transparent;
   border: solid transparent;
   border-width: 1px 0; }
   .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
     padding-right: 0;
     padding-left: 0; }
 
 .form-control-sm {
   min-height: calc(1.7em + 1rem + 2px);
   padding: 0.5rem 1.25rem;
   font-size: 0.875rem;
   border-radius: 0.2rem; }
   .form-control-sm::file-selector-button {
     padding: 0.5rem 1.25rem;
     margin: -0.5rem -1.25rem;
     margin-inline-end: 1.25rem; }
   .form-control-sm::-webkit-file-upload-button {
     padding: 0.5rem 1.25rem;
     margin: -0.5rem -1.25rem;
     margin-inline-end: 1.25rem; }
 
 .form-control-lg {
   min-height: calc(1.7em + 2rem + 2px);
   padding: 1rem 1.875rem;
   font-size: 1rem;
   border-radius: 0.3rem; }
   .form-control-lg::file-selector-button {
     padding: 1rem 1.875rem;
     margin: -1rem -1.875rem;
     margin-inline-end: 1.875rem; }
   .form-control-lg::-webkit-file-upload-button {
     padding: 1rem 1.875rem;
     margin: -1rem -1.875rem;
     margin-inline-end: 1.875rem; }
 
 textarea.form-control {
   min-height: calc(1.7em + 1.3rem + 2px); }
 
 textarea.form-control-sm {
   min-height: calc(1.7em + 1rem + 2px); }
 
 textarea.form-control-lg {
   min-height: calc(1.7em + 2rem + 2px); }
 
 .form-control-color {
   max-width: 3rem;
   height: auto;
   padding: 0.65rem; }
   .form-control-color:not(:disabled):not([readonly]) {
     cursor: pointer; }
   .form-control-color::-moz-color-swatch {
     height: 1.7em;
     border-radius: 0.25rem; }
   .form-control-color::-webkit-color-swatch {
     height: 1.7em;
     border-radius: 0.25rem; }
 
 .form-select {
   display: block;
   width: 100%;
   padding: 0.65rem 2.25rem 0.65rem 1.25rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.7;
   color: #8F9BAD;
   vertical-align: middle;
   background-color: #ffffff;
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23101010' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right 1.25rem center;
   background-size: 16px 12px;
   border: 1px solid #cbd5e0;
   border-radius: 0.25rem;
   appearance: none; }
   .form-select:focus {
     border-color: #ffa7b8;
     outline: 0;
     box-shadow: 0 0 0 0.25rem rgba(254, 79, 112, 0.25); }
   .form-select[multiple], .form-select[size]:not([size="1"]) {
     padding-right: 1.25rem;
     background-image: none; }
   .form-select:disabled {
     color: #3a3a3a;
     background-color: #eff2f7; }
   .form-select:-moz-focusring {
     color: transparent;
     text-shadow: 0 0 0 #8F9BAD; }
 
 .form-select-sm {
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   padding-left: 1.25rem;
   font-size: 0.875rem; }
 
 .form-select-lg {
   padding-top: 1rem;
   padding-bottom: 1rem;
   padding-left: 1.875rem;
   font-size: 1rem; }
 
 .form-check {
   display: block;
   min-height: 1.7rem;
   padding-left: 1.5em;
   margin-bottom: 0.125rem; }
   .form-check .form-check-input {
     float: left;
     margin-left: -1.5em; }
 
 .form-check-input {
   width: 1em;
   height: 1em;
   margin-top: 0.35em;
   vertical-align: top;
   background-color: #ffffff;
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   border: 1px solid rgba(0, 0, 0, 0.25);
   appearance: none;
   color-adjust: exact;
   transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
   @media (prefers-reduced-motion: reduce) {
     .form-check-input {
       transition: none; } }
   .form-check-input[type="checkbox"] {
     border-radius: 0.25em; }
   .form-check-input[type="radio"] {
     border-radius: 50%; }
   .form-check-input:active {
     filter: brightness(90%); }
   .form-check-input:focus {
     border-color: #ffa7b8;
     outline: 0;
     box-shadow: 0 0 0 0 transparent; }
   .form-check-input:checked {
     background-color: #FE4F70;
     border-color: #FE4F70; }
     .form-check-input:checked[type="checkbox"] {
       background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
     .form-check-input:checked[type="radio"] {
       background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e"); }
   .form-check-input[type="checkbox"]:indeterminate {
     background-color: #FE4F70;
     border-color: #FE4F70;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
   .form-check-input:disabled {
     pointer-events: none;
     filter: none;
     opacity: 0.5; }
   .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
     opacity: 0.5; }
 
 .form-switch {
   padding-left: 2.5em; }
   .form-switch .form-check-input {
     width: 2em;
     margin-left: -2.5em;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
     background-position: left center;
     border-radius: 2em; }
     .form-switch .form-check-input:focus {
       background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffa7b8'/%3e%3c/svg%3e"); }
     .form-switch .form-check-input:checked {
       background-position: right center;
       background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }
 
 .form-check-inline {
   display: inline-block;
   margin-right: 1rem; }
 
 .btn-check {
   position: absolute;
   clip: rect(0, 0, 0, 0);
   pointer-events: none; }
   .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
     pointer-events: none;
     filter: none;
     opacity: 0.65; }
 
 .form-range {
   width: 100%;
   height: 1.5rem;
   padding: 0;
   background-color: transparent;
   appearance: none; }
   .form-range:focus {
     outline: none; }
     .form-range:focus::-webkit-slider-thumb {
       box-shadow: 0 0 0 1px #ffffff, 0 0 0 0 transparent; }
     .form-range:focus::-moz-range-thumb {
       box-shadow: 0 0 0 1px #ffffff, 0 0 0 0 transparent; }
   .form-range::-moz-focus-outer {
     border: 0; }
   .form-range::-webkit-slider-thumb {
     width: 1rem;
     height: 1rem;
     margin-top: -0.25rem;
     background-color: #FE4F70;
     border: 0;
     border-radius: 1rem;
     transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     appearance: none; }
     @media (prefers-reduced-motion: reduce) {
       .form-range::-webkit-slider-thumb {
         transition: none; } }
     .form-range::-webkit-slider-thumb:active {
       background-color: #ffcad4; }
   .form-range::-webkit-slider-runnable-track {
     width: 100%;
     height: 0.5rem;
     color: transparent;
     cursor: pointer;
     background-color: #e2e8f0;
     border-color: transparent;
     border-radius: 1rem; }
   .form-range::-moz-range-thumb {
     width: 1rem;
     height: 1rem;
     background-color: #FE4F70;
     border: 0;
     border-radius: 1rem;
     transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     appearance: none; }
     @media (prefers-reduced-motion: reduce) {
       .form-range::-moz-range-thumb {
         transition: none; } }
     .form-range::-moz-range-thumb:active {
       background-color: #ffcad4; }
   .form-range::-moz-range-track {
     width: 100%;
     height: 0.5rem;
     color: transparent;
     cursor: pointer;
     background-color: #e2e8f0;
     border-color: transparent;
     border-radius: 1rem; }
   .form-range:disabled {
     pointer-events: none; }
     .form-range:disabled::-webkit-slider-thumb {
       background-color: #a0aec0; }
     .form-range:disabled::-moz-range-thumb {
       background-color: #a0aec0; }
 
 .form-floating {
   position: relative; }
   .form-floating > .form-control,
   .form-floating > .form-select {
     height: calc(3.5rem + 2px);
     padding: 1rem 1.25rem; }
   .form-floating > label {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     padding: 1rem 1.25rem;
     pointer-events: none;
     border: 1px solid transparent;
     transform-origin: 0 0;
     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
     @media (prefers-reduced-motion: reduce) {
       .form-floating > label {
         transition: none; } }
   .form-floating > .form-control::placeholder {
     color: transparent; }
   .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
     padding-top: 1.625rem;
     padding-bottom: 0.625rem; }
   .form-floating > .form-control:-webkit-autofill {
     padding-top: 1.625rem;
     padding-bottom: 0.625rem; }
   .form-floating > .form-select {
     padding-top: 1.625rem;
     padding-bottom: 0.625rem; }
   .form-floating > .form-control:focus ~ label,
   .form-floating > .form-control:not(:placeholder-shown) ~ label,
   .form-floating > .form-select ~ label {
     opacity: 0.65;
     transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
   .form-floating > .form-control:-webkit-autofill ~ label {
     opacity: 0.65;
     transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
 
 .input-group {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: stretch;
   width: 100%; }
   .input-group > .form-control,
   .input-group > .form-select {
     position: relative;
     flex: 1 1 auto;
     width: 1%;
     min-width: 0; }
   .input-group > .form-control:focus,
   .input-group > .form-select:focus {
     z-index: 3; }
   .input-group .btn {
     position: relative;
     z-index: 2; }
     .input-group .btn:focus {
       z-index: 3; }
 
 .input-group-text {
   display: flex;
   align-items: center;
   padding: 0.65rem 1.25rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.7;
   color: #8F9BAD;
   text-align: center;
   white-space: nowrap;
   background-color: #eff2f7;
   border: 1px solid #cbd5e0;
   border-radius: 0.25rem; }
 
 .input-group-lg > .form-control,
 .input-group-lg > .form-select,
 .input-group-lg > .input-group-text,
 .input-group-lg > .btn {
   padding: 1rem 1.875rem;
   font-size: 1rem;
   border-radius: 0.3rem; }
 
 .input-group-sm > .form-control,
 .input-group-sm > .form-select,
 .input-group-sm > .input-group-text,
 .input-group-sm > .btn {
   padding: 0.5rem 1.25rem;
   font-size: 0.875rem;
   border-radius: 0.2rem; }
 
 .input-group-lg > .form-select,
 .input-group-sm > .form-select {
   padding-right: 2.25rem; }
 
 .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
 .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0; }
 
 .input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
 .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0; }
 
 .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
   margin-left: -1px;
   border-top-left-radius: 0;
   border-bottom-left-radius: 0; }
 
 .valid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 0.875em;
   color: #2dca8c; }
 
 .valid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.25rem 0.5rem;
   margin-top: .1rem;
   font-size: 0.875rem;
   color: #ffffff;
   background-color: rgba(45, 202, 140, 0.9);
   border-radius: 0.25rem; }
 
 .was-validated :valid ~ .valid-feedback,
 .was-validated :valid ~ .valid-tooltip,
 .is-valid ~ .valid-feedback,
 .is-valid ~ .valid-tooltip {
   display: block; }
 
 .was-validated .form-control:valid, .form-control.is-valid {
   border-color: #2dca8c;
   padding-right: calc(1.7em + 1.3rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232dca8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right calc(0.425em + 0.325rem) center;
   background-size: calc(0.85em + 0.65rem) calc(0.85em + 0.65rem); }
   .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
     border-color: #2dca8c;
     box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.25); }
 
 .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
   padding-right: calc(1.7em + 1.3rem);
   background-position: top calc(0.425em + 0.325rem) right calc(0.425em + 0.325rem); }
 
 .was-validated .form-select:valid, .form-select.is-valid {
   border-color: #2dca8c;
   padding-right: calc(0.75em + 3.225rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23101010' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232dca8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
   background-position: right 1.25rem center, center right 2.25rem;
   background-size: 16px 12px, calc(0.85em + 0.65rem) calc(0.85em + 0.65rem); }
   .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
     border-color: #2dca8c;
     box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.25); }
 
 .was-validated .form-check-input:valid, .form-check-input.is-valid {
   border-color: #2dca8c; }
   .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
     background-color: #2dca8c; }
   .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
     box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.25); }
   .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
     color: #2dca8c; }
 
 .form-check-inline .form-check-input ~ .valid-feedback {
   margin-left: .5em; }
 
 .invalid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 0.875em;
   color: #ff5c75; }
 
 .invalid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.25rem 0.5rem;
   margin-top: .1rem;
   font-size: 0.875rem;
   color: #ffffff;
   background-color: rgba(255, 92, 117, 0.9);
   border-radius: 0.25rem; }
 
 .was-validated :invalid ~ .invalid-feedback,
 .was-validated :invalid ~ .invalid-tooltip,
 .is-invalid ~ .invalid-feedback,
 .is-invalid ~ .invalid-tooltip {
   display: block; }
 
 .was-validated .form-control:invalid, .form-control.is-invalid {
   border-color: #ff5c75;
   padding-right: calc(1.7em + 1.3rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5c75'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5c75' stroke='none'/%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right calc(0.425em + 0.325rem) center;
   background-size: calc(0.85em + 0.65rem) calc(0.85em + 0.65rem); }
   .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
     border-color: #ff5c75;
     box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.25); }
 
 .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
   padding-right: calc(1.7em + 1.3rem);
   background-position: top calc(0.425em + 0.325rem) right calc(0.425em + 0.325rem); }
 
 .was-validated .form-select:invalid, .form-select.is-invalid {
   border-color: #ff5c75;
   padding-right: calc(0.75em + 3.225rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23101010' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5c75'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5c75' stroke='none'/%3e%3c/svg%3e");
   background-position: right 1.25rem center, center right 2.25rem;
   background-size: 16px 12px, calc(0.85em + 0.65rem) calc(0.85em + 0.65rem); }
   .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
     border-color: #ff5c75;
     box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.25); }
 
 .was-validated .form-check-input:invalid, .form-check-input.is-invalid {
   border-color: #ff5c75; }
   .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
     background-color: #ff5c75; }
   .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
     box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.25); }
   .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
     color: #ff5c75; }
 
 .form-check-inline .form-check-input ~ .invalid-feedback {
   margin-left: .5em; }
 
 .btn {
   display: inline-block;
   font-weight: 400;
   line-height: 1.7;
   color: #8F9BAD;
   text-align: center;
   vertical-align: middle;
   cursor: pointer;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0.65rem 1.25rem;
   font-size: 1rem;
   border-radius: 0.25rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
   @media (prefers-reduced-motion: reduce) {
     .btn {
       transition: none; } }
   .btn:hover {
     color: #8F9BAD; }
   .btn-check:focus + .btn, .btn:focus {
     outline: 0;
     box-shadow: 0 0 0 0 transparent; }
   .btn:disabled, .btn.disabled,
   fieldset:disabled .btn {
     pointer-events: none;
     opacity: 0.65; }
 
 .btn-primary {
   color: #ffffff;
   background-color: #FE4F70;
   border-color: #FE4F70; }
   .btn-primary:hover {
     color: #ffffff;
     background-color: #d8435f;
     border-color: #cb3f5a; }
   .btn-check:focus + .btn-primary, .btn-primary:focus {
     color: #ffffff;
     background-color: #d8435f;
     border-color: #cb3f5a;
     box-shadow: 0 0 0 0.25rem rgba(254, 105, 133, 0.5); }
   .btn-check:checked + .btn-primary,
   .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
   .show > .btn-primary.dropdown-toggle {
     color: #ffffff;
     background-color: #cb3f5a;
     border-color: #bf3b54; }
     .btn-check:checked + .btn-primary:focus,
     .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
     .show > .btn-primary.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(254, 105, 133, 0.5); }
   .btn-primary:disabled, .btn-primary.disabled {
     color: #ffffff;
     background-color: #FE4F70;
     border-color: #FE4F70; }
 
 .btn-secondary {
   color: #ffffff;
   background-color: #6c757d;
   border-color: #6c757d; }
   .btn-secondary:hover {
     color: #ffffff;
     background-color: #5c636a;
     border-color: #565e64; }
   .btn-check:focus + .btn-secondary, .btn-secondary:focus {
     color: #ffffff;
     background-color: #5c636a;
     border-color: #565e64;
     box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
   .btn-check:checked + .btn-secondary,
   .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
   .show > .btn-secondary.dropdown-toggle {
     color: #ffffff;
     background-color: #565e64;
     border-color: #51585e; }
     .btn-check:checked + .btn-secondary:focus,
     .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
     .show > .btn-secondary.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
   .btn-secondary:disabled, .btn-secondary.disabled {
     color: #ffffff;
     background-color: #6c757d;
     border-color: #6c757d; }
 
 .btn-success {
   color: #ffffff;
   background-color: #2dca8c;
   border-color: #2dca8c; }
   .btn-success:hover {
     color: #ffffff;
     background-color: #26ac77;
     border-color: #24a270; }
   .btn-check:focus + .btn-success, .btn-success:focus {
     color: #ffffff;
     background-color: #26ac77;
     border-color: #24a270;
     box-shadow: 0 0 0 0.25rem rgba(77, 210, 157, 0.5); }
   .btn-check:checked + .btn-success,
   .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
   .show > .btn-success.dropdown-toggle {
     color: #ffffff;
     background-color: #24a270;
     border-color: #229869; }
     .btn-check:checked + .btn-success:focus,
     .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
     .show > .btn-success.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(77, 210, 157, 0.5); }
   .btn-success:disabled, .btn-success.disabled {
     color: #ffffff;
     background-color: #2dca8c;
     border-color: #2dca8c; }
 
 .btn-info {
   color: #ffffff;
   background-color: #50b5ff;
   border-color: #50b5ff; }
   .btn-info:hover {
     color: #ffffff;
     background-color: #449ad9;
     border-color: #4091cc; }
   .btn-check:focus + .btn-info, .btn-info:focus {
     color: #ffffff;
     background-color: #449ad9;
     border-color: #4091cc;
     box-shadow: 0 0 0 0.25rem rgba(106, 192, 255, 0.5); }
   .btn-check:checked + .btn-info,
   .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
   .show > .btn-info.dropdown-toggle {
     color: #ffffff;
     background-color: #4091cc;
     border-color: #3c88bf; }
     .btn-check:checked + .btn-info:focus,
     .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
     .show > .btn-info.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(106, 192, 255, 0.5); }
   .btn-info:disabled, .btn-info.disabled {
     color: #ffffff;
     background-color: #50b5ff;
     border-color: #50b5ff; }
 
 .btn-warning {
   color: #ffffff;
   background-color: #ff9f1c;
   border-color: #ff9f1c; }
   .btn-warning:hover {
     color: #ffffff;
     background-color: #d98718;
     border-color: #cc7f16; }
   .btn-check:focus + .btn-warning, .btn-warning:focus {
     color: #ffffff;
     background-color: #d98718;
     border-color: #cc7f16;
     box-shadow: 0 0 0 0.25rem rgba(255, 173, 62, 0.5); }
   .btn-check:checked + .btn-warning,
   .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
   .show > .btn-warning.dropdown-toggle {
     color: #ffffff;
     background-color: #cc7f16;
     border-color: #bf7715; }
     .btn-check:checked + .btn-warning:focus,
     .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
     .show > .btn-warning.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(255, 173, 62, 0.5); }
   .btn-warning:disabled, .btn-warning.disabled {
     color: #ffffff;
     background-color: #ff9f1c;
     border-color: #ff9f1c; }
 
 .btn-danger {
   color: #ffffff;
   background-color: #ff5c75;
   border-color: #ff5c75; }
   .btn-danger:hover {
     color: #ffffff;
     background-color: #d94e63;
     border-color: #cc4a5e; }
   .btn-check:focus + .btn-danger, .btn-danger:focus {
     color: #ffffff;
     background-color: #d94e63;
     border-color: #cc4a5e;
     box-shadow: 0 0 0 0.25rem rgba(255, 116, 138, 0.5); }
   .btn-check:checked + .btn-danger,
   .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
   .show > .btn-danger.dropdown-toggle {
     color: #ffffff;
     background-color: #cc4a5e;
     border-color: #bf4558; }
     .btn-check:checked + .btn-danger:focus,
     .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
     .show > .btn-danger.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(255, 116, 138, 0.5); }
   .btn-danger:disabled, .btn-danger.disabled {
     color: #ffffff;
     background-color: #ff5c75;
     border-color: #ff5c75; }
 
 .btn-light {
   color: #000;
   background-color: #f1f6fd;
   border-color: #f1f6fd; }
   .btn-light:hover {
     color: #000;
     background-color: #f3f7fd;
     border-color: #f2f7fd; }
   .btn-check:focus + .btn-light, .btn-light:focus {
     color: #000;
     background-color: #f3f7fd;
     border-color: #f2f7fd;
     box-shadow: 0 0 0 0.25rem rgba(205, 209, 215, 0.5); }
   .btn-check:checked + .btn-light,
   .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
   .show > .btn-light.dropdown-toggle {
     color: #000;
     background-color: #f4f8fd;
     border-color: #f2f7fd; }
     .btn-check:checked + .btn-light:focus,
     .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
     .show > .btn-light.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(205, 209, 215, 0.5); }
   .btn-light:disabled, .btn-light.disabled {
     color: #000;
     background-color: #f1f6fd;
     border-color: #f1f6fd; }
 
 .btn-dark {
   color: #ffffff;
   background-color: #203656;
   border-color: #203656; }
   .btn-dark:hover {
     color: #ffffff;
     background-color: #1b2e49;
     border-color: #1a2b45; }
   .btn-check:focus + .btn-dark, .btn-dark:focus {
     color: #ffffff;
     background-color: #1b2e49;
     border-color: #1a2b45;
     box-shadow: 0 0 0 0.25rem rgba(65, 84, 111, 0.5); }
   .btn-check:checked + .btn-dark,
   .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
   .show > .btn-dark.dropdown-toggle {
     color: #ffffff;
     background-color: #1a2b45;
     border-color: #182941; }
     .btn-check:checked + .btn-dark:focus,
     .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
     .show > .btn-dark.dropdown-toggle:focus {
       box-shadow: 0 0 0 0.25rem rgba(65, 84, 111, 0.5); }
   .btn-dark:disabled, .btn-dark.disabled {
     color: #ffffff;
     background-color: #203656;
     border-color: #203656; }
 
 .btn-outline-primary {
   color: #FE4F70;
   border-color: #FE4F70; }
   .btn-outline-primary:hover {
     color: #ffffff;
     background-color: #FE4F70;
     border-color: #FE4F70; }
   .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
     box-shadow: 0 0 0 0.25rem rgba(254, 79, 112, 0.5); }
   .btn-check:checked + .btn-outline-primary,
   .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
     color: #ffffff;
     background-color: #FE4F70;
     border-color: #FE4F70; }
     .btn-check:checked + .btn-outline-primary:focus,
     .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(254, 79, 112, 0.5); }
   .btn-outline-primary:disabled, .btn-outline-primary.disabled {
     color: #FE4F70;
     background-color: transparent; }
 
 .btn-outline-secondary {
   color: #6c757d;
   border-color: #6c757d; }
   .btn-outline-secondary:hover {
     color: #ffffff;
     background-color: #6c757d;
     border-color: #6c757d; }
   .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
     box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
   .btn-check:checked + .btn-outline-secondary,
   .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
     color: #ffffff;
     background-color: #6c757d;
     border-color: #6c757d; }
     .btn-check:checked + .btn-outline-secondary:focus,
     .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
   .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
     color: #6c757d;
     background-color: transparent; }
 
 .btn-outline-success {
   color: #2dca8c;
   border-color: #2dca8c; }
   .btn-outline-success:hover {
     color: #ffffff;
     background-color: #2dca8c;
     border-color: #2dca8c; }
   .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
     box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.5); }
   .btn-check:checked + .btn-outline-success,
   .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
     color: #ffffff;
     background-color: #2dca8c;
     border-color: #2dca8c; }
     .btn-check:checked + .btn-outline-success:focus,
     .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.5); }
   .btn-outline-success:disabled, .btn-outline-success.disabled {
     color: #2dca8c;
     background-color: transparent; }
 
 .btn-outline-info {
   color: #50b5ff;
   border-color: #50b5ff; }
   .btn-outline-info:hover {
     color: #ffffff;
     background-color: #50b5ff;
     border-color: #50b5ff; }
   .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
     box-shadow: 0 0 0 0.25rem rgba(80, 181, 255, 0.5); }
   .btn-check:checked + .btn-outline-info,
   .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
     color: #ffffff;
     background-color: #50b5ff;
     border-color: #50b5ff; }
     .btn-check:checked + .btn-outline-info:focus,
     .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(80, 181, 255, 0.5); }
   .btn-outline-info:disabled, .btn-outline-info.disabled {
     color: #50b5ff;
     background-color: transparent; }
 
 .btn-outline-warning {
   color: #ff9f1c;
   border-color: #ff9f1c; }
   .btn-outline-warning:hover {
     color: #ffffff;
     background-color: #ff9f1c;
     border-color: #ff9f1c; }
   .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
     box-shadow: 0 0 0 0.25rem rgba(255, 159, 28, 0.5); }
   .btn-check:checked + .btn-outline-warning,
   .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
     color: #ffffff;
     background-color: #ff9f1c;
     border-color: #ff9f1c; }
     .btn-check:checked + .btn-outline-warning:focus,
     .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(255, 159, 28, 0.5); }
   .btn-outline-warning:disabled, .btn-outline-warning.disabled {
     color: #ff9f1c;
     background-color: transparent; }
 
 .btn-outline-danger {
   color: #ff5c75;
   border-color: #ff5c75; }
   .btn-outline-danger:hover {
     color: #ffffff;
     background-color: #ff5c75;
     border-color: #ff5c75; }
   .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
     box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.5); }
   .btn-check:checked + .btn-outline-danger,
   .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
     color: #ffffff;
     background-color: #ff5c75;
     border-color: #ff5c75; }
     .btn-check:checked + .btn-outline-danger:focus,
     .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.5); }
   .btn-outline-danger:disabled, .btn-outline-danger.disabled {
     color: #ff5c75;
     background-color: transparent; }
 
 .btn-outline-light {
   color: #f1f6fd;
   border-color: #f1f6fd; }
   .btn-outline-light:hover {
     color: #000;
     background-color: #f1f6fd;
     border-color: #f1f6fd; }
   .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
     box-shadow: 0 0 0 0.25rem rgba(241, 246, 253, 0.5); }
   .btn-check:checked + .btn-outline-light,
   .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
     color: #000;
     background-color: #f1f6fd;
     border-color: #f1f6fd; }
     .btn-check:checked + .btn-outline-light:focus,
     .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(241, 246, 253, 0.5); }
   .btn-outline-light:disabled, .btn-outline-light.disabled {
     color: #f1f6fd;
     background-color: transparent; }
 
 .btn-outline-dark {
   color: #203656;
   border-color: #203656; }
   .btn-outline-dark:hover {
     color: #ffffff;
     background-color: #203656;
     border-color: #203656; }
   .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
     box-shadow: 0 0 0 0.25rem rgba(32, 54, 86, 0.5); }
   .btn-check:checked + .btn-outline-dark,
   .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
     color: #ffffff;
     background-color: #203656;
     border-color: #203656; }
     .btn-check:checked + .btn-outline-dark:focus,
     .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
       box-shadow: 0 0 0 0.25rem rgba(32, 54, 86, 0.5); }
   .btn-outline-dark:disabled, .btn-outline-dark.disabled {
     color: #203656;
     background-color: transparent; }
 
 .btn-link {
   font-weight: 400;
   color: #FE4F70;
   text-decoration: none; }
   .btn-link:hover {
     color: #cb3f5a; }
   .btn-link:disabled, .btn-link.disabled {
     color: #3a3a3a; }
 
 .btn-lg, .btn-group-lg > .btn {
   padding: 1rem 1.875rem;
   font-size: 1rem;
   border-radius: 0.3rem; }
 
 .btn-sm, .btn-group-sm > .btn {
   padding: 0.5rem 1.25rem;
   font-size: 0.875rem;
   border-radius: 0.2rem; }
 
 .fade {
   transition: opacity 0.15s linear; }
   @media (prefers-reduced-motion: reduce) {
     .fade {
       transition: none; } }
   .fade:not(.show) {
     opacity: 0; }
 
 .collapse:not(.show) {
   display: none; }
 
 .collapsing {
   height: 0;
   overflow: hidden;
   transition: height 0.35s ease; }
   @media (prefers-reduced-motion: reduce) {
     .collapsing {
       transition: none; } }
 
 .dropup,
 .dropend,
 .dropdown,
 .dropstart {
   position: relative; }
 
 .dropdown-toggle {
   white-space: nowrap; }
   .dropdown-toggle::after {
     display: inline-block;
     margin-left: 0.255em;
     vertical-align: 0.255em;
     content: "";
     border-top: 0.3em solid;
     border-right: 0.3em solid transparent;
     border-bottom: 0;
     border-left: 0.3em solid transparent; }
   .dropdown-toggle:empty::after {
     margin-left: 0; }
 
 .dropdown-menu {
   position: absolute;
   top: 100%;
   left: 0;
   z-index: 1000;
   display: none;
   min-width: 15rem;
   padding: 0.5rem 0;
   margin: 0.125rem 0 0;
   font-size: 1rem;
   color: #8F9BAD;
   text-align: left;
   list-style: none;
   background-color: #ffffff;
   background-clip: padding-box;
   border: 0 solid rgba(0, 0, 0, 0.15);
   border-radius: 0.25rem; }
   .dropdown-menu[style] {
     right: auto !important; }
 
 .dropdown-menu-start {
   --bs-position: start;
   right: auto /* rtl:ignore */;
   left: 0 /* rtl:ignore */; }
 
 .dropdown-menu-end {
   --bs-position: end;
   right: 0 /* rtl:ignore */;
   left: auto /* rtl:ignore */; }
 
 @media (min-width: 576px) {
   .dropdown-menu-sm-start {
     --bs-position: start;
     right: auto /* rtl:ignore */;
     left: 0 /* rtl:ignore */; }
   .dropdown-menu-sm-end {
     --bs-position: end;
     right: 0 /* rtl:ignore */;
     left: auto /* rtl:ignore */; } }
 
 @media (min-width: 768px) {
   .dropdown-menu-md-start {
     --bs-position: start;
     right: auto /* rtl:ignore */;
     left: 0 /* rtl:ignore */; }
   .dropdown-menu-md-end {
     --bs-position: end;
     right: 0 /* rtl:ignore */;
     left: auto /* rtl:ignore */; } }
 
 @media (min-width: 992px) {
   .dropdown-menu-lg-start {
     --bs-position: start;
     right: auto /* rtl:ignore */;
     left: 0 /* rtl:ignore */; }
   .dropdown-menu-lg-end {
     --bs-position: end;
     right: 0 /* rtl:ignore */;
     left: auto /* rtl:ignore */; } }
 
 @media (min-width: 1200px) {
   .dropdown-menu-xl-start {
     --bs-position: start;
     right: auto /* rtl:ignore */;
     left: 0 /* rtl:ignore */; }
   .dropdown-menu-xl-end {
     --bs-position: end;
     right: 0 /* rtl:ignore */;
     left: auto /* rtl:ignore */; } }
 
 @media (min-width: 1400px) {
   .dropdown-menu-xxl-start {
     --bs-position: start;
     right: auto /* rtl:ignore */;
     left: 0 /* rtl:ignore */; }
   .dropdown-menu-xxl-end {
     --bs-position: end;
     right: 0 /* rtl:ignore */;
     left: auto /* rtl:ignore */; } }
 
 .dropup .dropdown-menu {
   top: auto;
   bottom: 100%;
   margin-top: 0;
   margin-bottom: 0.125rem; }
 
 .dropup .dropdown-toggle::after {
   display: inline-block;
   margin-left: 0.255em;
   vertical-align: 0.255em;
   content: "";
   border-top: 0;
   border-right: 0.3em solid transparent;
   border-bottom: 0.3em solid;
   border-left: 0.3em solid transparent; }
 
 .dropup .dropdown-toggle:empty::after {
   margin-left: 0; }
 
 .dropend .dropdown-menu {
   top: 0;
   right: auto;
   left: 100%;
   margin-top: 0;
   margin-left: 0.125rem; }
 
 .dropend .dropdown-toggle::after {
   display: inline-block;
   margin-left: 0.255em;
   vertical-align: 0.255em;
   content: "";
   border-top: 0.3em solid transparent;
   border-right: 0;
   border-bottom: 0.3em solid transparent;
   border-left: 0.3em solid; }
 
 .dropend .dropdown-toggle:empty::after {
   margin-left: 0; }
 
 .dropend .dropdown-toggle::after {
   vertical-align: 0; }
 
 .dropstart .dropdown-menu {
   top: 0;
   right: 100%;
   left: auto;
   margin-top: 0;
   margin-right: 0.125rem; }
 
 .dropstart .dropdown-toggle::after {
   display: inline-block;
   margin-left: 0.255em;
   vertical-align: 0.255em;
   content: ""; }
 
 .dropstart .dropdown-toggle::after {
   display: none; }
 
 .dropstart .dropdown-toggle::before {
   display: inline-block;
   margin-right: 0.255em;
   vertical-align: 0.255em;
   content: "";
   border-top: 0.3em solid transparent;
   border-right: 0.3em solid;
   border-bottom: 0.3em solid transparent; }
 
 .dropstart .dropdown-toggle:empty::after {
   margin-left: 0; }
 
 .dropstart .dropdown-toggle::before {
   vertical-align: 0; }
 
 .dropdown-divider {
   height: 0;
   margin: 0.5rem 0;
   overflow: hidden;
   border-top: 1px solid rgba(0, 0, 0, 0.15); }
 
 .dropdown-item {
   display: block;
   width: 100%;
   padding: 0.4rem 1rem;
   clear: both;
   font-weight: 400;
   color: #203656;
   text-align: inherit;
   white-space: nowrap;
   background-color: transparent;
   border: 0; }
   .dropdown-item:hover, .dropdown-item:focus {
     color: #FE4F70;
     background-color: transparent; }
   .dropdown-item.active, .dropdown-item:active {
     color: #FE4F70;
     text-decoration: none;
     background-color: transparent; }
   .dropdown-item.disabled, .dropdown-item:disabled {
     color: #3a3a3a;
     pointer-events: none;
     background-color: transparent; }
 
 .dropdown-menu.show {
   display: block; }
 
 .dropdown-header {
   display: block;
   padding: 0.5rem 1rem;
   margin-bottom: 0;
   font-size: 0.875rem;
   color: #203656;
   white-space: nowrap; }
 
 .dropdown-item-text {
   display: block;
   padding: 0.4rem 1rem;
   color: #203656; }
 
 .dropdown-menu-dark {
   color: #e2e8f0;
   background-color: #101010;
   border-color: rgba(0, 0, 0, 0.15); }
   .dropdown-menu-dark .dropdown-item {
     color: #e2e8f0; }
     .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
       color: #ffffff;
       background-color: rgba(255, 255, 255, 0.15); }
     .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
       color: #FE4F70;
       background-color: transparent; }
     .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
       color: #a0aec0; }
   .dropdown-menu-dark .dropdown-divider {
     border-color: rgba(0, 0, 0, 0.15); }
   .dropdown-menu-dark .dropdown-item-text {
     color: #e2e8f0; }
   .dropdown-menu-dark .dropdown-header {
     color: #a0aec0; }
 
 .btn-group,
 .btn-group-vertical {
   position: relative;
   display: inline-flex;
   vertical-align: middle; }
   .btn-group > .btn,
   .btn-group-vertical > .btn {
     position: relative;
     flex: 1 1 auto; }
   .btn-group > .btn-check:checked + .btn,
   .btn-group > .btn-check:focus + .btn,
   .btn-group > .btn:hover,
   .btn-group > .btn:focus,
   .btn-group > .btn:active,
   .btn-group > .btn.active,
   .btn-group-vertical > .btn-check:checked + .btn,
   .btn-group-vertical > .btn-check:focus + .btn,
   .btn-group-vertical > .btn:hover,
   .btn-group-vertical > .btn:focus,
   .btn-group-vertical > .btn:active,
   .btn-group-vertical > .btn.active {
     z-index: 1; }
 
 .btn-toolbar {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start; }
   .btn-toolbar .input-group {
     width: auto; }
 
 .btn-group > .btn:not(:first-child),
 .btn-group > .btn-group:not(:first-child) {
   margin-left: -1px; }
 
 .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
 .btn-group > .btn-group:not(:last-child) > .btn {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0; }
 
 .btn-group > .btn:nth-child(n + 3),
 .btn-group > :not(.btn-check) + .btn,
 .btn-group > .btn-group:not(:first-child) > .btn {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0; }
 
 .dropdown-toggle-split {
   padding-right: 0.9375rem;
   padding-left: 0.9375rem; }
   .dropdown-toggle-split::after,
   .dropup .dropdown-toggle-split::after,
   .dropend .dropdown-toggle-split::after {
     margin-left: 0; }
   .dropstart .dropdown-toggle-split::before {
     margin-right: 0; }
 
 .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
   padding-right: 0.9375rem;
   padding-left: 0.9375rem; }
 
 .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
   padding-right: 1.40625rem;
   padding-left: 1.40625rem; }
 
 .btn-group-vertical {
   flex-direction: column;
   align-items: flex-start;
   justify-content: center; }
   .btn-group-vertical > .btn,
   .btn-group-vertical > .btn-group {
     width: 100%; }
   .btn-group-vertical > .btn:not(:first-child),
   .btn-group-vertical > .btn-group:not(:first-child) {
     margin-top: -1px; }
   .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
   .btn-group-vertical > .btn-group:not(:last-child) > .btn {
     border-bottom-right-radius: 0;
     border-bottom-left-radius: 0; }
   .btn-group-vertical > .btn ~ .btn,
   .btn-group-vertical > .btn-group:not(:first-child) > .btn {
     border-top-left-radius: 0;
     border-top-right-radius: 0; }
 
 .nav {
   display: flex;
   flex-wrap: wrap;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none; }
 
 .nav-link {
   display: block;
   padding: 0.5rem 1rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
   @media (prefers-reduced-motion: reduce) {
     .nav-link {
       transition: none; } }
   .nav-link.disabled {
     color: #a0aec0;
     pointer-events: none;
     cursor: default; }
 
 .nav-tabs {
   border-bottom: 1px solid #e2e8f0; }
   .nav-tabs .nav-link {
     margin-bottom: -1px;
     border: 1px solid transparent;
     border-top-left-radius: 0.25rem;
     border-top-right-radius: 0.25rem; }
     .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
       border-color: transparent transparent #FE4F70; }
     .nav-tabs .nav-link.disabled {
       color: #a0aec0;
       background-color: transparent;
       border-color: transparent; }
   .nav-tabs .nav-link.active,
   .nav-tabs .nav-item.show .nav-link {
     color: #333333;
     background-color: transparent;
     border-color: transparent transparent #FE4F70; }
   .nav-tabs .dropdown-menu {
     margin-top: -1px;
     border-top-left-radius: 0;
     border-top-right-radius: 0; }
 
 .nav-pills .nav-link {
   border-radius: 0.25rem; }
 
 .nav-pills .nav-link.active,
 .nav-pills .show > .nav-link {
   color: #ffffff;
   background-color: #FE4F70; }
 
 .nav-fill > .nav-link,
 .nav-fill .nav-item {
   flex: 1 1 auto;
   text-align: center; }
 
 .nav-justified > .nav-link,
 .nav-justified .nav-item {
   flex-basis: 0;
   flex-grow: 1;
   text-align: center; }
 
 .tab-content > .tab-pane {
   display: none; }
 
 .tab-content > .active {
   display: block; }
 
 .navbar {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   padding-top: 0;
   padding-bottom: 0; }
   .navbar > .container,
   .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
     display: flex;
     flex-wrap: inherit;
     align-items: center;
     justify-content: space-between; }
 
 .navbar-brand {
   padding-top: 0.415rem;
   padding-bottom: 0.415rem;
   margin-right: 1rem;
   font-size: 1.1rem;
   white-space: nowrap; }
 
 .navbar-nav {
   display: flex;
   flex-direction: column;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none; }
   .navbar-nav .nav-link {
     padding-right: 0;
     padding-left: 0; }
   .navbar-nav .dropdown-menu {
     position: static; }
 
 .navbar-text {
   padding-top: 0.5rem;
   padding-bottom: 0.5rem; }
 
 .navbar-collapse {
   align-items: center;
   width: 100%; }
 
 .navbar-toggler {
   padding: 0.25rem 0.75rem;
   font-size: 1.1rem;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   border-radius: 0.25rem;
   transition: box-shadow 0.15s ease-in-out; }
   @media (prefers-reduced-motion: reduce) {
     .navbar-toggler {
       transition: none; } }
   .navbar-toggler:hover {
     text-decoration: none; }
   .navbar-toggler:focus {
     text-decoration: none;
     outline: 0;
     box-shadow: 0 0 0 0.25rem; }
 
 .navbar-toggler-icon {
   display: inline-block;
   width: 1.5em;
   height: 1.5em;
   vertical-align: middle;
   background-repeat: no-repeat;
   background-position: center;
   background-size: 100%; }
 
 @media (min-width: 576px) {
   .navbar-expand-sm {
     flex-wrap: nowrap;
     justify-content: flex-start; }
     .navbar-expand-sm .navbar-nav {
       flex-direction: row; }
       .navbar-expand-sm .navbar-nav .dropdown-menu {
         position: absolute; }
       .navbar-expand-sm .navbar-nav .nav-link {
         padding-right: 0.75rem;
         padding-left: 0.75rem; }
     .navbar-expand-sm .navbar-collapse {
       display: flex !important; }
     .navbar-expand-sm .navbar-toggler {
       display: none; } }
 
 @media (min-width: 768px) {
   .navbar-expand-md {
     flex-wrap: nowrap;
     justify-content: flex-start; }
     .navbar-expand-md .navbar-nav {
       flex-direction: row; }
       .navbar-expand-md .navbar-nav .dropdown-menu {
         position: absolute; }
       .navbar-expand-md .navbar-nav .nav-link {
         padding-right: 0.75rem;
         padding-left: 0.75rem; }
     .navbar-expand-md .navbar-collapse {
       display: flex !important; }
     .navbar-expand-md .navbar-toggler {
       display: none; } }
 
 @media (min-width: 992px) {
   .navbar-expand-lg {
     flex-wrap: nowrap;
     justify-content: flex-start; }
     .navbar-expand-lg .navbar-nav {
       flex-direction: row; }
       .navbar-expand-lg .navbar-nav .dropdown-menu {
         position: absolute; }
       .navbar-expand-lg .navbar-nav .nav-link {
         padding-right: 0.75rem;
         padding-left: 0.75rem; }
     .navbar-expand-lg .navbar-collapse {
       display: flex !important; }
     .navbar-expand-lg .navbar-toggler {
       display: none; } }
 
 @media (min-width: 1200px) {
   .navbar-expand-xl {
     flex-wrap: nowrap;
     justify-content: flex-start; }
     .navbar-expand-xl .navbar-nav {
       flex-direction: row; }
       .navbar-expand-xl .navbar-nav .dropdown-menu {
         position: absolute; }
       .navbar-expand-xl .navbar-nav .nav-link {
         padding-right: 0.75rem;
         padding-left: 0.75rem; }
     .navbar-expand-xl .navbar-collapse {
       display: flex !important; }
     .navbar-expand-xl .navbar-toggler {
       display: none; } }
 
 @media (min-width: 1400px) {
   .navbar-expand-xxl {
     flex-wrap: nowrap;
     justify-content: flex-start; }
     .navbar-expand-xxl .navbar-nav {
       flex-direction: row; }
       .navbar-expand-xxl .navbar-nav .dropdown-menu {
         position: absolute; }
       .navbar-expand-xxl .navbar-nav .nav-link {
         padding-right: 0.75rem;
         padding-left: 0.75rem; }
     .navbar-expand-xxl .navbar-collapse {
       display: flex !important; }
     .navbar-expand-xxl .navbar-toggler {
       display: none; } }
 
 .navbar-expand {
   flex-wrap: nowrap;
   justify-content: flex-start; }
   .navbar-expand .navbar-nav {
     flex-direction: row; }
     .navbar-expand .navbar-nav .dropdown-menu {
       position: absolute; }
     .navbar-expand .navbar-nav .nav-link {
       padding-right: 0.75rem;
       padding-left: 0.75rem; }
   .navbar-expand .navbar-collapse {
     display: flex !important; }
   .navbar-expand .navbar-toggler {
     display: none; }
 
 .navbar-light .navbar-brand {
   color: #FE4F70; }
   .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
     color: #FE4F70; }
 
 .navbar-light .navbar-nav .nav-link {
   color: #203656; }
   .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
     color: #FE4F70; }
   .navbar-light .navbar-nav .nav-link.disabled {
     color: rgba(0, 0, 0, 0.3); }
 
 .navbar-light .navbar-nav .show > .nav-link,
 .navbar-light .navbar-nav .nav-link.active {
   color: #FE4F70; }
 
 .navbar-light .navbar-toggler {
   color: #203656;
   border-color: rgba(0, 0, 0, 0.1); }
 
 .navbar-light .navbar-toggler-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23203656' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
 
 .navbar-light .navbar-text {
   color: #203656; }
   .navbar-light .navbar-text a,
   .navbar-light .navbar-text a:hover,
   .navbar-light .navbar-text a:focus {
     color: #FE4F70; }
 
 .navbar-dark .navbar-brand {
   color: rgba(255, 255, 255, 0.75); }
   .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
     color: rgba(255, 255, 255, 0.75); }
 
 .navbar-dark .navbar-nav .nav-link {
   color: #ffffff; }
   .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
     color: rgba(255, 255, 255, 0.75); }
   .navbar-dark .navbar-nav .nav-link.disabled {
     color: rgba(255, 255, 255, 0.25); }
 
 .navbar-dark .navbar-nav .show > .nav-link,
 .navbar-dark .navbar-nav .nav-link.active {
   color: rgba(255, 255, 255, 0.75); }
 
 .navbar-dark .navbar-toggler {
   color: #ffffff;
   border-color: rgba(255, 255, 255, 0.1); }
 
 .navbar-dark .navbar-toggler-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
 
 .navbar-dark .navbar-text {
   color: #ffffff; }
   .navbar-dark .navbar-text a,
   .navbar-dark .navbar-text a:hover,
   .navbar-dark .navbar-text a:focus {
     color: rgba(255, 255, 255, 0.75); }
 
 .card {
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #ffffff;
   background-clip: border-box;
   border: 1px solid #e2e8f0;
   border-radius: 0.5rem; }
   .card > hr {
     margin-right: 0;
     margin-left: 0; }
   .card > .list-group {
     border-top: inherit;
     border-bottom: inherit; }
     .card > .list-group:first-child {
       border-top-width: 0;
       border-top-left-radius: calc(0.5rem - 1px);
       border-top-right-radius: calc(0.5rem - 1px); }
     .card > .list-group:last-child {
       border-bottom-width: 0;
       border-bottom-right-radius: calc(0.5rem - 1px);
       border-bottom-left-radius: calc(0.5rem - 1px); }
   .card > .card-header + .list-group,
   .card > .list-group + .card-footer {
     border-top: 0; }
 
 .card-body {
   flex: 1 1 auto;
   padding: 1.2rem 1.2rem; }
 
 .card-title {
   margin-bottom: 0.5rem; }
 
 .card-subtitle {
   margin-top: -0.25rem;
   margin-bottom: 0; }
 
 .card-text:last-child {
   margin-bottom: 0; }
 
 .card-link:hover {
   text-decoration: none; }
 
 .card-link + .card-link {
   margin-left: 1.2rem /* rtl:ignore */; }
 
 .card-header {
   padding: 1.2rem 1.2rem;
   margin-bottom: 0;
   background-color: transparent;
   border-bottom: 1px solid #e2e8f0; }
   .card-header:first-child {
     border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0; }
 
 .card-footer {
   padding: 1.2rem 1.2rem;
   background-color: transparent;
   border-top: 1px solid #e2e8f0; }
   .card-footer:last-child {
     border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px); }
 
 .card-header-tabs {
   margin-right: -0.6rem;
   margin-bottom: -1.2rem;
   margin-left: -0.6rem;
   border-bottom: 0; }
   .card-header-tabs .nav-link.active {
     background-color: #ffffff;
     border-bottom-color: #ffffff; }
 
 .card-header-pills {
   margin-right: -0.6rem;
   margin-left: -0.6rem; }
 
 .card-img-overlay {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   padding: 1rem;
   border-radius: calc(0.5rem - 1px); }
 
 .card-img,
 .card-img-top,
 .card-img-bottom {
   width: 100%; }
 
 .card-img,
 .card-img-top {
   max-height: 20rem;
   border-top-left-radius: calc(0.5rem - 1px);
   border-top-right-radius: calc(0.5rem - 1px); }
 
 .card-img,
 .card-img-bottom {
   border-bottom-right-radius: calc(0.5rem - 1px);
   border-bottom-left-radius: calc(0.5rem - 1px); }
 
 .card-group > .card {
   margin-bottom: 0.75rem; }
 
 @media (min-width: 576px) {
   .card-group {
     display: flex;
     flex-flow: row wrap; }
     .card-group > .card {
       flex: 1 0 0%;
       margin-bottom: 0; }
       .card-group > .card + .card {
         margin-left: 0;
         border-left: 0; }
       .card-group > .card:not(:last-child) {
         border-top-right-radius: 0;
         border-bottom-right-radius: 0; }
         .card-group > .card:not(:last-child) .card-img-top,
         .card-group > .card:not(:last-child) .card-header {
           border-top-right-radius: 0; }
         .card-group > .card:not(:last-child) .card-img-bottom,
         .card-group > .card:not(:last-child) .card-footer {
           border-bottom-right-radius: 0; }
       .card-group > .card:not(:first-child) {
         border-top-left-radius: 0;
         border-bottom-left-radius: 0; }
         .card-group > .card:not(:first-child) .card-img-top,
         .card-group > .card:not(:first-child) .card-header {
           border-top-left-radius: 0; }
         .card-group > .card:not(:first-child) .card-img-bottom,
         .card-group > .card:not(:first-child) .card-footer {
           border-bottom-left-radius: 0; } }
 
 .accordion-button {
   position: relative;
   display: flex;
   align-items: center;
   width: 100%;
   padding: 1rem 1.25rem;
   font-size: 1rem;
   color: #203656;
   background-color: transparent;
   border: 1px solid #eff2f7;
   border-radius: 0;
   overflow-anchor: none;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
   @media (prefers-reduced-motion: reduce) {
     .accordion-button {
       transition: none; } }
   .accordion-button.collapsed {
     border-bottom-width: 0; }
   .accordion-button:not(.collapsed) {
     color: #FE4F70;
     background-color: #ffffff; }
     .accordion-button:not(.collapsed)::after {
       background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FE4F70'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
       transform: rotate(180deg); }
   .accordion-button::after {
     flex-shrink: 0;
     width: 1.25rem;
     height: 1.25rem;
     margin-left: auto;
     content: "";
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238F9BAD'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-size: 1.25rem;
     transition: transform 0.2s ease-in-out; }
     @media (prefers-reduced-motion: reduce) {
       .accordion-button::after {
         transition: none; } }
   .accordion-button:hover {
     z-index: 2; }
   .accordion-button:focus {
     z-index: 3;
     border-color: none;
     outline: 0;
     box-shadow: none; }
 
 .accordion-header {
   margin-bottom: 0; }
 
 .accordion-item:first-of-type .accordion-button {
   border-top-left-radius: 0.25rem;
   border-top-right-radius: 0.25rem; }
 
 .accordion-item:last-of-type .accordion-button.collapsed {
   border-bottom-width: 1px;
   border-bottom-right-radius: 0.25rem;
   border-bottom-left-radius: 0.25rem; }
 
 .accordion-item:last-of-type .accordion-collapse {
   border-bottom-width: 1px;
   border-bottom-right-radius: 0.25rem;
   border-bottom-left-radius: 0.25rem; }
 
 .accordion-collapse {
   border: solid #eff2f7;
   border-width: 0 1px; }
 
 .accordion-body {
   padding: 1rem 1.25rem; }
 
 .accordion-flush .accordion-button {
   border-right: 0;
   border-left: 0;
   border-radius: 0; }
 
 .accordion-flush .accordion-collapse {
   border-width: 0; }
 
 .accordion-flush .accordion-item:first-of-type .accordion-button {
   border-top-width: 0;
   border-top-left-radius: 0;
   border-top-right-radius: 0; }
 
 .accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
   border-bottom-width: 0;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0; }
 
 .breadcrumb {
   display: flex;
   flex-wrap: wrap;
   padding: 0 0;
   margin-bottom: 1rem;
   list-style: none;
   background-color: transparent; }
 
 .breadcrumb-item + .breadcrumb-item {
   padding-left: 0.5rem; }
   .breadcrumb-item + .breadcrumb-item::before {
     float: left;
     padding-right: 0.5rem;
     color: #3a3a3a;
     content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }
 
 .breadcrumb-item.active {
   color: #3a3a3a; }
 
 .pagination {
   display: flex;
   padding-left: 0;
   list-style: none; }
 
 .page-link {
   position: relative;
   display: block;
   color: #FE4F70;
   background-color: #ffffff;
   border: 1px solid #e2e8f0;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
   @media (prefers-reduced-motion: reduce) {
     .page-link {
       transition: none; } }
   .page-link:hover {
     z-index: 2;
     color: #cb3f5a;
     background-color: #eff2f7;
     border-color: #e2e8f0; }
   .page-link:focus {
     z-index: 3;
     color: #cb3f5a;
     background-color: #eff2f7;
     outline: 0;
     box-shadow: 0 0 0 0 transparent; }
 
 .page-item:not(:first-child) .page-link {
   margin-left: -1px; }
 
 .page-item.active .page-link {
   z-index: 3;
   color: #ffffff;
   background-color: #FE4F70;
   border-color: #FE4F70; }
 
 .page-item.disabled .page-link {
   color: #3a3a3a;
   pointer-events: none;
   background-color: #ffffff;
   border-color: #e2e8f0; }
 
 .page-link {
   padding: 0.375rem 0.75rem; }
 
 .page-item:first-child .page-link {
   border-top-left-radius: 0.25rem;
   border-bottom-left-radius: 0.25rem; }
 
 .page-item:last-child .page-link {
   border-top-right-radius: 0.25rem;
   border-bottom-right-radius: 0.25rem; }
 
 .pagination-lg .page-link {
   padding: 0.75rem 1.5rem;
   font-size: 1.1rem; }
 
 .pagination-lg .page-item:first-child .page-link {
   border-top-left-radius: 0.3rem;
   border-bottom-left-radius: 0.3rem; }
 
 .pagination-lg .page-item:last-child .page-link {
   border-top-right-radius: 0.3rem;
   border-bottom-right-radius: 0.3rem; }
 
 .pagination-sm .page-link {
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem; }
 
 .pagination-sm .page-item:first-child .page-link {
   border-top-left-radius: 0.2rem;
   border-bottom-left-radius: 0.2rem; }
 
 .pagination-sm .page-item:last-child .page-link {
   border-top-right-radius: 0.2rem;
   border-bottom-right-radius: 0.2rem; }
 
 .badge {
   display: inline-block;
   padding: 0.35em 0.65em;
   font-size: 0.75em;
   font-weight: 700;
   line-height: 1;
   color: #ffffff;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.25rem; }
   .badge:empty {
     display: none; }
 
 .btn .badge {
   position: relative;
   top: -1px; }
 
 .alert {
   position: relative;
   padding: 1rem 1rem;
   margin-bottom: 1rem;
   border: 1px solid transparent;
   border-radius: 0.25rem; }
 
 .alert-heading {
   color: inherit; }
 
 .alert-link {
   font-weight: 700; }
 
 .alert-dismissible {
   padding-right: 3rem; }
   .alert-dismissible .btn-close {
     position: absolute;
     top: 0;
     right: 0;
     z-index: 2;
     padding: 1.25rem 1rem; }
 
 .alert-primary {
   color: #982f43;
   background-color: #ffdce2;
   border-color: #ffcad4; }
   .alert-primary .alert-link {
     color: #7a2636; }
 
 .alert-secondary {
   color: #41464b;
   background-color: #e2e3e5;
   border-color: #d3d6d8; }
   .alert-secondary .alert-link {
     color: #34383c; }
 
 .alert-success {
   color: #1b7954;
   background-color: #d5f4e8;
   border-color: #c0efdd; }
   .alert-success .alert-link {
     color: #166143; }
 
 .alert-info {
   color: #306d99;
   background-color: #dcf0ff;
   border-color: #cbe9ff; }
   .alert-info .alert-link {
     color: #26577a; }
 
 .alert-warning {
   color: #995f11;
   background-color: #ffecd2;
   border-color: #ffe2bb; }
   .alert-warning .alert-link {
     color: #7a4c0e; }
 
 .alert-danger {
   color: #993746;
   background-color: #ffdee3;
   border-color: #ffced6; }
   .alert-danger .alert-link {
     color: #7a2c38; }
 
 .alert-light {
   color: #919498;
   background-color: #fcfdff;
   border-color: #fbfcfe; }
   .alert-light .alert-link {
     color: #74767a; }
 
 .alert-dark {
   color: #132034;
   background-color: #d2d7dd;
   border-color: #bcc3cc; }
   .alert-dark .alert-link {
     color: #0f1a2a; }
 
 @keyframes progress-bar-stripes {
   0% {
     background-position-x: 1rem; } }
 
 .progress {
   display: flex;
   height: 1rem;
   overflow: hidden;
   font-size: 0.75rem;
   background-color: #eff2f7;
   border-radius: 0.25rem; }
 
 .progress-bar {
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow: hidden;
   color: #ffffff;
   text-align: center;
   white-space: nowrap;
   background-color: #FE4F70;
   transition: width 0.6s ease; }
   @media (prefers-reduced-motion: reduce) {
     .progress-bar {
       transition: none; } }
 
 .progress-bar-striped {
   background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
   background-size: 1rem 1rem; }
 
 .progress-bar-animated {
   animation: 1s linear infinite progress-bar-stripes; }
   @media (prefers-reduced-motion: reduce) {
     .progress-bar-animated {
       animation: none; } }
 
 .list-group {
   display: flex;
   flex-direction: column;
   padding-left: 0;
   margin-bottom: 0;
   border-radius: 0.25rem; }
 
 .list-group-item-action {
   width: 100%;
   color: #333333;
   text-align: inherit; }
   .list-group-item-action:hover, .list-group-item-action:focus {
     z-index: 1;
     color: #333333;
     text-decoration: none;
     background-color: #f7f7ff; }
   .list-group-item-action:active {
     color: #8F9BAD;
     background-color: #eff2f7; }
 
 .list-group-item {
   position: relative;
   display: block;
   padding: 0.5rem 1rem;
   background-color: #ffffff;
   border: 1px solid #eff2f7; }
   .list-group-item:first-child {
     border-top-left-radius: inherit;
     border-top-right-radius: inherit; }
   .list-group-item:last-child {
     border-bottom-right-radius: inherit;
     border-bottom-left-radius: inherit; }
   .list-group-item.disabled, .list-group-item:disabled {
     color: #a0aec0;
     pointer-events: none;
     background-color: #ffffff; }
   .list-group-item.active {
     z-index: 2;
     color: #203656;
     background-color: #f7f7ff;
     border-color: #eff2f7; }
   .list-group-item + .list-group-item {
     border-top-width: 0; }
     .list-group-item + .list-group-item.active {
       margin-top: -1px;
       border-top-width: 1px; }
 
 .list-group-horizontal {
   flex-direction: row; }
   .list-group-horizontal > .list-group-item:first-child {
     border-bottom-left-radius: 0.25rem;
     border-top-right-radius: 0; }
   .list-group-horizontal > .list-group-item:last-child {
     border-top-right-radius: 0.25rem;
     border-bottom-left-radius: 0; }
   .list-group-horizontal > .list-group-item.active {
     margin-top: 0; }
   .list-group-horizontal > .list-group-item + .list-group-item {
     border-top-width: 1px;
     border-left-width: 0; }
     .list-group-horizontal > .list-group-item + .list-group-item.active {
       margin-left: -1px;
       border-left-width: 1px; }
 
 @media (min-width: 576px) {
   .list-group-horizontal-sm {
     flex-direction: row; }
     .list-group-horizontal-sm > .list-group-item:first-child {
       border-bottom-left-radius: 0.25rem;
       border-top-right-radius: 0; }
     .list-group-horizontal-sm > .list-group-item:last-child {
       border-top-right-radius: 0.25rem;
       border-bottom-left-radius: 0; }
     .list-group-horizontal-sm > .list-group-item.active {
       margin-top: 0; }
     .list-group-horizontal-sm > .list-group-item + .list-group-item {
       border-top-width: 1px;
       border-left-width: 0; }
       .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px; } }
 
 @media (min-width: 768px) {
   .list-group-horizontal-md {
     flex-direction: row; }
     .list-group-horizontal-md > .list-group-item:first-child {
       border-bottom-left-radius: 0.25rem;
       border-top-right-radius: 0; }
     .list-group-horizontal-md > .list-group-item:last-child {
       border-top-right-radius: 0.25rem;
       border-bottom-left-radius: 0; }
     .list-group-horizontal-md > .list-group-item.active {
       margin-top: 0; }
     .list-group-horizontal-md > .list-group-item + .list-group-item {
       border-top-width: 1px;
       border-left-width: 0; }
       .list-group-horizontal-md > .list-group-item + .list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px; } }
 
 @media (min-width: 992px) {
   .list-group-horizontal-lg {
     flex-direction: row; }
     .list-group-horizontal-lg > .list-group-item:first-child {
       border-bottom-left-radius: 0.25rem;
       border-top-right-radius: 0; }
     .list-group-horizontal-lg > .list-group-item:last-child {
       border-top-right-radius: 0.25rem;
       border-bottom-left-radius: 0; }
     .list-group-horizontal-lg > .list-group-item.active {
       margin-top: 0; }
     .list-group-horizontal-lg > .list-group-item + .list-group-item {
       border-top-width: 1px;
       border-left-width: 0; }
       .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px; } }
 
 @media (min-width: 1200px) {
   .list-group-horizontal-xl {
     flex-direction: row; }
     .list-group-horizontal-xl > .list-group-item:first-child {
       border-bottom-left-radius: 0.25rem;
       border-top-right-radius: 0; }
     .list-group-horizontal-xl > .list-group-item:last-child {
       border-top-right-radius: 0.25rem;
       border-bottom-left-radius: 0; }
     .list-group-horizontal-xl > .list-group-item.active {
       margin-top: 0; }
     .list-group-horizontal-xl > .list-group-item + .list-group-item {
       border-top-width: 1px;
       border-left-width: 0; }
       .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px; } }
 
 @media (min-width: 1400px) {
   .list-group-horizontal-xxl {
     flex-direction: row; }
     .list-group-horizontal-xxl > .list-group-item:first-child {
       border-bottom-left-radius: 0.25rem;
       border-top-right-radius: 0; }
     .list-group-horizontal-xxl > .list-group-item:last-child {
       border-top-right-radius: 0.25rem;
       border-bottom-left-radius: 0; }
     .list-group-horizontal-xxl > .list-group-item.active {
       margin-top: 0; }
     .list-group-horizontal-xxl > .list-group-item + .list-group-item {
       border-top-width: 1px;
       border-left-width: 0; }
       .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px; } }
 
 .list-group-flush {
   border-radius: 0; }
   .list-group-flush > .list-group-item {
     border-width: 0 0 1px; }
     .list-group-flush > .list-group-item:last-child {
       border-bottom-width: 0; }
 
 .list-group-item-primary {
   color: #982f43;
   background-color: #ffdce2; }
   .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
     color: #982f43;
     background-color: #e6c6cb; }
   .list-group-item-primary.list-group-item-action.active {
     color: #ffffff;
     background-color: #982f43;
     border-color: #982f43; }
 
 .list-group-item-secondary {
   color: #41464b;
   background-color: #e2e3e5; }
   .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
     color: #41464b;
     background-color: #cbccce; }
   .list-group-item-secondary.list-group-item-action.active {
     color: #ffffff;
     background-color: #41464b;
     border-color: #41464b; }
 
 .list-group-item-success {
   color: #1b7954;
   background-color: #d5f4e8; }
   .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
     color: #1b7954;
     background-color: #c0dcd1; }
   .list-group-item-success.list-group-item-action.active {
     color: #ffffff;
     background-color: #1b7954;
     border-color: #1b7954; }
 
 .list-group-item-info {
   color: #306d99;
   background-color: #dcf0ff; }
   .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
     color: #306d99;
     background-color: #c6d8e6; }
   .list-group-item-info.list-group-item-action.active {
     color: #ffffff;
     background-color: #306d99;
     border-color: #306d99; }
 
 .list-group-item-warning {
   color: #995f11;
   background-color: #ffecd2; }
   .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
     color: #995f11;
     background-color: #e6d4bd; }
   .list-group-item-warning.list-group-item-action.active {
     color: #ffffff;
     background-color: #995f11;
     border-color: #995f11; }
 
 .list-group-item-danger {
   color: #993746;
   background-color: #ffdee3; }
   .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
     color: #993746;
     background-color: #e6c8cc; }
   .list-group-item-danger.list-group-item-action.active {
     color: #ffffff;
     background-color: #993746;
     border-color: #993746; }
 
 .list-group-item-light {
   color: #919498;
   background-color: #fcfdff; }
   .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
     color: #919498;
     background-color: #e3e4e6; }
   .list-group-item-light.list-group-item-action.active {
     color: #ffffff;
     background-color: #919498;
     border-color: #919498; }
 
 .list-group-item-dark {
   color: #132034;
   background-color: #d2d7dd; }
   .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
     color: #132034;
     background-color: #bdc2c7; }
   .list-group-item-dark.list-group-item-action.active {
     color: #ffffff;
     background-color: #132034;
     border-color: #132034; }
 
 .btn-close {
   box-sizing: content-box;
   width: 1em;
   height: 1em;
   padding: 0.25em 0.25em;
   color: #000;
   background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
   border: 0;
   border-radius: 0.25rem;
   opacity: 0.5; }
   .btn-close:hover {
     color: #000;
     text-decoration: none;
     opacity: 0.75; }
   .btn-close:focus {
     outline: none;
     box-shadow: 0 0 0 0 transparent;
     opacity: 1; }
   .btn-close:disabled, .btn-close.disabled {
     pointer-events: none;
     user-select: none;
     opacity: 0.25; }
 
 .btn-close-white {
   filter: invert(1) grayscale(100%) brightness(200%); }
 
 .toast {
   width: 350px;
   max-width: 100%;
   font-size: 0.875rem;
   pointer-events: auto;
   background-color: rgba(255, 255, 255, 0.85);
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.1);
   box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1);
   border-radius: 0.25rem; }
   .toast:not(.showing):not(.show) {
     opacity: 0; }
   .toast.hide {
     display: none; }
 
 .toast-container {
   width: max-content;
   max-width: 100%;
   pointer-events: none; }
   .toast-container > :not(:last-child) {
     margin-bottom: 0.75rem; }
 
 .toast-header {
   display: flex;
   align-items: center;
   padding: 0.5rem 0.75rem;
   color: #3a3a3a;
   background-color: rgba(255, 255, 255, 0.85);
   background-clip: padding-box;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
   border-top-left-radius: calc(0.25rem - 1px);
   border-top-right-radius: calc(0.25rem - 1px); }
   .toast-header .btn-close {
     margin-right: -0.375rem;
     margin-left: 0.75rem; }
 
 .toast-body {
   padding: 0.75rem; }
 
 .modal-open {
   overflow: hidden; }
   .modal-open .modal {
     overflow-x: hidden;
     overflow-y: auto; }
 
 .modal {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1050;
   display: none;
   width: 100%;
   height: 100%;
   overflow: hidden;
   outline: 0; }
 
 .modal-dialog {
   position: relative;
   width: auto;
   margin: 0.5rem;
   pointer-events: none; }
   .modal.fade .modal-dialog {
     transition: transform 0.3s ease-out;
     transform: translate(0, -50px); }
     @media (prefers-reduced-motion: reduce) {
       .modal.fade .modal-dialog {
         transition: none; } }
   .modal.show .modal-dialog {
     transform: none; }
   .modal.modal-static .modal-dialog {
     transform: scale(1.02); }
 
 .modal-dialog-scrollable {
   height: calc(100% - 1rem); }
   .modal-dialog-scrollable .modal-content {
     max-height: 100%;
     overflow: hidden; }
   .modal-dialog-scrollable .modal-body {
     overflow-y: auto; }
 
 .modal-dialog-centered {
   display: flex;
   align-items: center;
   min-height: calc(100% - 1rem); }
 
 .modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   pointer-events: auto;
   background-color: #ffffff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 0.3rem;
   outline: 0; }
 
 .modal-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1040;
   width: 100vw;
   height: 100vh;
   background-color: #000; }
   .modal-backdrop.fade {
     opacity: 0; }
   .modal-backdrop.show {
     opacity: 0.5; }
 
 .modal-header {
   display: flex;
   flex-shrink: 0;
   align-items: center;
   justify-content: space-between;
   padding: 1rem 1rem;
   border-bottom: 1px solid #e2e8f0;
   border-top-left-radius: calc(0.3rem - 1px);
   border-top-right-radius: calc(0.3rem - 1px); }
   .modal-header .btn-close {
     padding: 0.5rem 0.5rem;
     margin: -0.5rem -0.5rem -0.5rem auto; }
 
 .modal-title {
   margin-bottom: 0;
   line-height: 1.7; }
 
 .modal-body {
   position: relative;
   flex: 1 1 auto;
   padding: 1rem; }
 
 .modal-footer {
   display: flex;
   flex-wrap: wrap;
   flex-shrink: 0;
   align-items: center;
   justify-content: flex-end;
   padding: 0.75rem;
   border-top: 1px solid #e2e8f0;
   border-bottom-right-radius: calc(0.3rem - 1px);
   border-bottom-left-radius: calc(0.3rem - 1px); }
   .modal-footer > * {
     margin: 0.25rem; }
 
 .modal-scrollbar-measure {
   position: absolute;
   top: -9999px;
   width: 50px;
   height: 50px;
   overflow: scroll; }
 
 @media (min-width: 576px) {
   .modal-dialog {
     max-width: 500px;
     margin: 1.75rem auto; }
   .modal-dialog-scrollable {
     height: calc(100% - 3.5rem); }
   .modal-dialog-centered {
     min-height: calc(100% - 3.5rem); }
   .modal-sm {
     max-width: 300px; } }
 
 @media (min-width: 992px) {
   .modal-lg,
   .modal-xl {
     max-width: 800px; } }
 
 @media (min-width: 1200px) {
   .modal-xl {
     max-width: 1140px; } }
 
 .modal-fullscreen {
   width: 100vw;
   max-width: none;
   height: 100%;
   margin: 0; }
   .modal-fullscreen .modal-content {
     height: 100%;
     border: 0;
     border-radius: 0; }
   .modal-fullscreen .modal-header {
     border-radius: 0; }
   .modal-fullscreen .modal-body {
     overflow-y: auto; }
   .modal-fullscreen .modal-footer {
     border-radius: 0; }
 
 @media (max-width: 575.98px) {
   .modal-fullscreen-sm-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0; }
     .modal-fullscreen-sm-down .modal-content {
       height: 100%;
       border: 0;
       border-radius: 0; }
     .modal-fullscreen-sm-down .modal-header {
       border-radius: 0; }
     .modal-fullscreen-sm-down .modal-body {
       overflow-y: auto; }
     .modal-fullscreen-sm-down .modal-footer {
       border-radius: 0; } }
 
 @media (max-width: 767.98px) {
   .modal-fullscreen-md-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0; }
     .modal-fullscreen-md-down .modal-content {
       height: 100%;
       border: 0;
       border-radius: 0; }
     .modal-fullscreen-md-down .modal-header {
       border-radius: 0; }
     .modal-fullscreen-md-down .modal-body {
       overflow-y: auto; }
     .modal-fullscreen-md-down .modal-footer {
       border-radius: 0; } }
 
 @media (max-width: 991.98px) {
   .modal-fullscreen-lg-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0; }
     .modal-fullscreen-lg-down .modal-content {
       height: 100%;
       border: 0;
       border-radius: 0; }
     .modal-fullscreen-lg-down .modal-header {
       border-radius: 0; }
     .modal-fullscreen-lg-down .modal-body {
       overflow-y: auto; }
     .modal-fullscreen-lg-down .modal-footer {
       border-radius: 0; } }
 
 @media (max-width: 1199.98px) {
   .modal-fullscreen-xl-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0; }
     .modal-fullscreen-xl-down .modal-content {
       height: 100%;
       border: 0;
       border-radius: 0; }
     .modal-fullscreen-xl-down .modal-header {
       border-radius: 0; }
     .modal-fullscreen-xl-down .modal-body {
       overflow-y: auto; }
     .modal-fullscreen-xl-down .modal-footer {
       border-radius: 0; } }
 
 @media (max-width: 1399.98px) {
   .modal-fullscreen-xxl-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0; }
     .modal-fullscreen-xxl-down .modal-content {
       height: 100%;
       border: 0;
       border-radius: 0; }
     .modal-fullscreen-xxl-down .modal-header {
       border-radius: 0; }
     .modal-fullscreen-xxl-down .modal-body {
       overflow-y: auto; }
     .modal-fullscreen-xxl-down .modal-footer {
       border-radius: 0; } }
 
 .tooltip {
   position: absolute;
   z-index: 1070;
   display: block;
   margin: 0;
   font-family: var(--bs-font-sans-serif);
   font-style: normal;
   font-weight: 400;
   line-height: 1.7;
   text-align: left;
   text-align: start;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   word-spacing: normal;
   white-space: normal;
   line-break: auto;
   font-size: 0.875rem;
   word-wrap: break-word;
   opacity: 0; }
   .tooltip.show {
     opacity: 0.9; }
   .tooltip .tooltip-arrow {
     position: absolute;
     display: block;
     width: 0.8rem;
     height: 0.4rem; }
     .tooltip .tooltip-arrow::before {
       position: absolute;
       content: "";
       border-color: transparent;
       border-style: solid; }
 
 .bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
   padding: 0.4rem 0; }
   .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
     bottom: 0; }
     .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
       top: -1px;
       border-width: 0.4rem 0.4rem 0;
       border-top-color: #000; }
 
 .bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
   padding: 0 0.4rem; }
   .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
     left: 0;
     width: 0.4rem;
     height: 0.8rem; }
     .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
       right: -1px;
       border-width: 0.4rem 0.4rem 0.4rem 0;
       border-right-color: #000; }
 
 .bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
   padding: 0.4rem 0; }
   .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
     top: 0; }
     .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
       bottom: -1px;
       border-width: 0 0.4rem 0.4rem;
       border-bottom-color: #000; }
 
 .bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
   padding: 0 0.4rem; }
   .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
     right: 0;
     width: 0.4rem;
     height: 0.8rem; }
     .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
       left: -1px;
       border-width: 0.4rem 0 0.4rem 0.4rem;
       border-left-color: #000; }
 
 .tooltip-inner {
   max-width: 200px;
   padding: 0.25rem 0.5rem;
   color: #ffffff;
   text-align: center;
   background-color: #000;
   border-radius: 0.25rem; }
 
 .popover {
   position: absolute;
   top: 0;
   left: 0 /* rtl:ignore */;
   z-index: 1060;
   display: block;
   max-width: 276px;
   font-family: var(--bs-font-sans-serif);
   font-style: normal;
   font-weight: 400;
   line-height: 1.7;
   text-align: left;
   text-align: start;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   word-spacing: normal;
   white-space: normal;
   line-break: auto;
   font-size: 0.875rem;
   word-wrap: break-word;
   background-color: #ffffff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 0.3rem; }
   .popover .popover-arrow {
     position: absolute;
     display: block;
     width: 1rem;
     height: 0.5rem;
     margin: 0 0.3rem; }
     .popover .popover-arrow::before, .popover .popover-arrow::after {
       position: absolute;
       display: block;
       content: "";
       border-color: transparent;
       border-style: solid; }
 
 .bs-popover-top, .bs-popover-auto[data-popper-placement^="top"] {
   margin-bottom: 0.5rem !important; }
   .bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
     bottom: calc(-0.5rem - 1px); }
     .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
       bottom: 0;
       border-width: 0.5rem 0.5rem 0;
       border-top-color: rgba(0, 0, 0, 0.25); }
     .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
       bottom: 1px;
       border-width: 0.5rem 0.5rem 0;
       border-top-color: #ffffff; }
 
 .bs-popover-end, .bs-popover-auto[data-popper-placement^="right"] {
   margin-left: 0.5rem !important; }
   .bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
     left: calc(-0.5rem - 1px);
     width: 0.5rem;
     height: 1rem;
     margin: 0.3rem 0; }
     .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
       left: 0;
       border-width: 0.5rem 0.5rem 0.5rem 0;
       border-right-color: rgba(0, 0, 0, 0.25); }
     .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
       left: 1px;
       border-width: 0.5rem 0.5rem 0.5rem 0;
       border-right-color: #ffffff; }
 
 .bs-popover-bottom, .bs-popover-auto[data-popper-placement^="bottom"] {
   margin-top: 0.5rem !important; }
   .bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
     top: calc(-0.5rem - 1px); }
     .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
       top: 0;
       border-width: 0 0.5rem 0.5rem 0.5rem;
       border-bottom-color: rgba(0, 0, 0, 0.25); }
     .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
       top: 1px;
       border-width: 0 0.5rem 0.5rem 0.5rem;
       border-bottom-color: #ffffff; }
   .bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
     position: absolute;
     top: 0;
     left: 50%;
     display: block;
     width: 1rem;
     margin-left: -0.5rem;
     content: "";
     border-bottom: 1px solid #f0f0f0; }
 
 .bs-popover-start, .bs-popover-auto[data-popper-placement^="left"] {
   margin-right: 0.5rem !important; }
   .bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
     right: calc(-0.5rem - 1px);
     width: 0.5rem;
     height: 1rem;
     margin: 0.3rem 0; }
     .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
       right: 0;
       border-width: 0.5rem 0 0.5rem 0.5rem;
       border-left-color: rgba(0, 0, 0, 0.25); }
     .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
       right: 1px;
       border-width: 0.5rem 0 0.5rem 0.5rem;
       border-left-color: #ffffff; }
 
 .popover-header {
   padding: 0.5rem 1rem;
   margin-bottom: 0;
   font-size: 1rem;
   color: #203656;
   background-color: #f0f0f0;
   border-bottom: 1px solid #d8d8d8;
   border-top-left-radius: calc(0.3rem - 1px);
   border-top-right-radius: calc(0.3rem - 1px); }
   .popover-header:empty {
     display: none; }
 
 .popover-body {
   padding: 1rem 1rem;
   color: #8F9BAD; }
 
 .carousel {
   position: relative; }
 
 .carousel.pointer-event {
   touch-action: pan-y; }
 
 .carousel-inner {
   position: relative;
   width: 100%;
   overflow: hidden; }
   .carousel-inner::after {
     display: block;
     clear: both;
     content: ""; }
 
 .carousel-item {
   position: relative;
   display: none;
   float: left;
   width: 100%;
   margin-right: -100%;
   backface-visibility: hidden;
   transition: transform 0.6s ease-in-out; }
   @media (prefers-reduced-motion: reduce) {
     .carousel-item {
       transition: none; } }
 
 .carousel-item.active,
 .carousel-item-next,
 .carousel-item-prev {
   display: block; }
 
 /* rtl:begin:ignore */
 .carousel-item-next:not(.carousel-item-start),
 .active.carousel-item-end {
   transform: translateX(100%); }
 
 .carousel-item-prev:not(.carousel-item-end),
 .active.carousel-item-start {
   transform: translateX(-100%); }
 
 /* rtl:end:ignore */
 .carousel-fade .carousel-item {
   opacity: 0;
   transition-property: opacity;
   transform: none; }
 
 .carousel-fade .carousel-item.active,
 .carousel-fade .carousel-item-next.carousel-item-start,
 .carousel-fade .carousel-item-prev.carousel-item-end {
   z-index: 1;
   opacity: 1; }
 
 .carousel-fade .active.carousel-item-start,
 .carousel-fade .active.carousel-item-end {
   z-index: 0;
   opacity: 0;
   transition: opacity 0s 0.6s; }
   @media (prefers-reduced-motion: reduce) {
     .carousel-fade .active.carousel-item-start,
     .carousel-fade .active.carousel-item-end {
       transition: none; } }
 
 .carousel-control-prev,
 .carousel-control-next {
   position: absolute;
   top: 0;
   bottom: 0;
   z-index: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 15%;
   color: #ffffff;
   text-align: center;
   opacity: 0.5;
   transition: opacity 0.15s ease; }
   @media (prefers-reduced-motion: reduce) {
     .carousel-control-prev,
     .carousel-control-next {
       transition: none; } }
   .carousel-control-prev:hover, .carousel-control-prev:focus,
   .carousel-control-next:hover,
   .carousel-control-next:focus {
     color: #ffffff;
     text-decoration: none;
     outline: 0;
     opacity: 0.9; }
 
 .carousel-control-prev {
   left: 0; }
 
 .carousel-control-next {
   right: 0; }
 
 .carousel-control-prev-icon,
 .carousel-control-next-icon {
   display: inline-block;
   width: 2rem;
   height: 2rem;
   background-repeat: no-repeat;
   background-position: 50%;
   background-size: 100% 100%; }
 
 /* rtl:options: {
   "autoRename": true,
   "stringMap":[ {
     "name"    : "prev-next",
     "search"  : "prev",
     "replace" : "next"
   } ]
 } */
 .carousel-control-prev-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }
 
 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }
 
 .carousel-indicators {
   position: absolute;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 2;
   display: flex;
   justify-content: center;
   padding-left: 0;
   margin-right: 15%;
   margin-left: 15%;
   list-style: none; }
   .carousel-indicators li {
     box-sizing: content-box;
     flex: 0 1 auto;
     width: 30px;
     height: 3px;
     margin-right: 3px;
     margin-left: 3px;
     text-indent: -999px;
     cursor: pointer;
     background-color: #ffffff;
     background-clip: padding-box;
     border-top: 10px solid transparent;
     border-bottom: 10px solid transparent;
     opacity: 0.5;
     transition: opacity 0.6s ease; }
     @media (prefers-reduced-motion: reduce) {
       .carousel-indicators li {
         transition: none; } }
   .carousel-indicators .active {
     opacity: 1; }
 
 .carousel-caption {
   position: absolute;
   right: 15%;
   bottom: 1.25rem;
   left: 15%;
   padding-top: 1.25rem;
   padding-bottom: 1.25rem;
   color: #ffffff;
   text-align: center; }
 
 .carousel-dark .carousel-control-prev-icon,
 .carousel-dark .carousel-control-next-icon {
   filter: invert(1) grayscale(100); }
 
 .carousel-dark .carousel-indicators li {
   background-color: #000; }
 
 .carousel-dark .carousel-caption {
   color: #000; }
 
 @keyframes spinner-border {
   to {
     transform: rotate(360deg) /* rtl:ignore */; } }
 
 .spinner-border {
   display: inline-block;
   width: 2rem;
   height: 2rem;
   vertical-align: text-bottom;
   border: 0.25em solid currentColor;
   border-right-color: transparent;
   border-radius: 50%;
   animation: 0.75s linear infinite spinner-border; }
 
 .spinner-border-sm {
   width: 1rem;
   height: 1rem;
   border-width: 0.2em; }
 
 @keyframes spinner-grow {
   0% {
     transform: scale(0); }
   50% {
     opacity: 1;
     transform: none; } }
 
 .spinner-grow {
   display: inline-block;
   width: 2rem;
   height: 2rem;
   vertical-align: text-bottom;
   background-color: currentColor;
   border-radius: 50%;
   opacity: 0;
   animation: 0.75s linear infinite spinner-grow; }
 
 .spinner-grow-sm {
   width: 1rem;
   height: 1rem; }
 
 @media (prefers-reduced-motion: reduce) {
   .spinner-border,
   .spinner-grow {
     animation-duration: 1.5s; } }
 
 .clearfix::after {
   display: block;
   clear: both;
   content: ""; }
 
 .link-primary {
   color: #FE4F70; }
   .link-primary:hover, .link-primary:focus {
     color: #cb3f5a; }
 
 .link-secondary {
   color: #6c757d; }
   .link-secondary:hover, .link-secondary:focus {
     color: #565e64; }
 
 .link-success {
   color: #2dca8c; }
   .link-success:hover, .link-success:focus {
     color: #24a270; }
 
 .link-info {
   color: #50b5ff; }
   .link-info:hover, .link-info:focus {
     color: #4091cc; }
 
 .link-warning {
   color: #ff9f1c; }
   .link-warning:hover, .link-warning:focus {
     color: #cc7f16; }
 
 .link-danger {
   color: #ff5c75; }
   .link-danger:hover, .link-danger:focus {
     color: #cc4a5e; }
 
 .link-light {
   color: #f1f6fd; }
   .link-light:hover, .link-light:focus {
     color: #f4f8fd; }
 
 .link-dark {
   color: #203656; }
   .link-dark:hover, .link-dark:focus {
     color: #1a2b45; }
 
 .ratio {
   position: relative;
   width: 100%; }
   .ratio::before {
     display: block;
     padding-top: var(--aspect-ratio);
     content: ""; }
   .ratio > * {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%; }
 
 .ratio-1x1 {
   --aspect-ratio: 100%; }
 
 .ratio-4x3 {
   --aspect-ratio: calc(3 / 4 * 100%); }
 
 .ratio-16x9 {
   --aspect-ratio: calc(9 / 16 * 100%); }
 
 .ratio-21x9 {
   --aspect-ratio: calc(9 / 21 * 100%); }
 
 .fixed-top {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 1030; }
 
 .fixed-bottom {
   position: fixed;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1030; }
 
 .sticky-top {
   position: sticky;
   top: 0;
   z-index: 1020; }
 
 @media (min-width: 576px) {
   .sticky-sm-top {
     position: sticky;
     top: 0;
     z-index: 1020; } }
 
 @media (min-width: 768px) {
   .sticky-md-top {
     position: sticky;
     top: 0;
     z-index: 1020; } }
 
 @media (min-width: 992px) {
   .sticky-lg-top {
     position: sticky;
     top: 0;
     z-index: 1020; } }
 
 @media (min-width: 1200px) {
   .sticky-xl-top {
     position: sticky;
     top: 0;
     z-index: 1020; } }
 
 @media (min-width: 1400px) {
   .sticky-xxl-top {
     position: sticky;
     top: 0;
     z-index: 1020; } }
 
 .visually-hidden,
 .visually-hidden-focusable:not(:focus) {
   position: absolute !important;
   width: 1px !important;
   height: 1px !important;
   padding: 0 !important;
   margin: -1px !important;
   overflow: hidden !important;
   clip: rect(0, 0, 0, 0) !important;
   white-space: nowrap !important;
   border: 0 !important; }
 
 .stretched-link::after {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1;
   content: ""; }
 
 .text-truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap; }
 
 .align-baseline {
   vertical-align: baseline !important; }
 
 .align-top {
   vertical-align: top !important; }
 
 .align-middle {
   vertical-align: middle !important; }
 
 .align-bottom {
   vertical-align: bottom !important; }
 
 .align-text-bottom {
   vertical-align: text-bottom !important; }
 
 .align-text-top {
   vertical-align: text-top !important; }
 
 .float-start {
   float: left !important; }
 
 .float-end {
   float: right !important; }
 
 .float-none {
   float: none !important; }
 
 .overflow-auto {
   overflow: auto !important; }
 
 .overflow-hidden {
   overflow: hidden !important; }
 
 .overflow-visible {
   overflow: visible !important; }
 
 .overflow-scroll {
   overflow: scroll !important; }
 
 .d-inline {
   display: inline !important; }
 
 .d-inline-block {
   display: inline-block !important; }
 
 .d-block {
   display: block !important; }
 
 .d-grid {
   display: grid !important; }
 
 .d-table {
   display: table !important; }
 
 .d-table-row {
   display: table-row !important; }
 
 .d-table-cell {
   display: table-cell !important; }
 
 .d-flex {
   display: flex !important; }
 
 .d-inline-flex {
   display: inline-flex !important; }
 
 .d-none {
   display: none !important; }
 
 .shadow {
   box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1) !important; }
 
 .shadow-sm {
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15) !important; }
 
 .shadow-lg {
   box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1) !important; }
 
 .shadow-none {
   box-shadow: none !important; }
 
 .position-static {
   position: static !important; }
 
 .position-relative {
   position: relative !important; }
 
 .position-absolute {
   position: absolute !important; }
 
 .position-fixed {
   position: fixed !important; }
 
 .position-sticky {
   position: sticky !important; }
 
 .top-0 {
   top: 0 !important; }
 
 .top-50 {
   top: 50% !important; }
 
 .top-100 {
   top: 100% !important; }
 
 .bottom-0 {
   bottom: 0 !important; }
 
 .bottom-50 {
   bottom: 50% !important; }
 
 .bottom-100 {
   bottom: 100% !important; }
 
 .start-0 {
   left: 0 !important; }
 
 .start-50 {
   left: 50% !important; }
 
 .start-100 {
   left: 100% !important; }
 
 .end-0 {
   right: 0 !important; }
 
 .end-50 {
   right: 50% !important; }
 
 .end-100 {
   right: 100% !important; }
 
 .translate-middle {
   transform: translate(-50%, -50%) !important; }
 
 .translate-middle-x {
   transform: translateX(-50%) !important; }
 
 .translate-middle-y {
   transform: translateY(-50%) !important; }
 
 .border {
   border: 1px solid #e2e8f0 !important; }
 
 .border-0 {
   border: 0 !important; }
 
 .border-top {
   border-top: 1px solid #e2e8f0 !important; }
 
 .border-top-0 {
   border-top: 0 !important; }
 
 .border-end {
   border-right: 1px solid #e2e8f0 !important; }
 
 .border-end-0 {
   border-right: 0 !important; }
 
 .border-bottom {
   border-bottom: 1px solid #e2e8f0 !important; }
 
 .border-bottom-0 {
   border-bottom: 0 !important; }
 
 .border-start {
   border-left: 1px solid #e2e8f0 !important; }
 
 .border-start-0 {
   border-left: 0 !important; }
 
 .border-primary {
   border-color: #FE4F70 !important; }
 
 .border-secondary {
   border-color: #6c757d !important; }
 
 .border-success {
   border-color: #2dca8c !important; }
 
 .border-info {
   border-color: #50b5ff !important; }
 
 .border-warning {
   border-color: #ff9f1c !important; }
 
 .border-danger {
   border-color: #ff5c75 !important; }
 
 .border-light {
   border-color: #f1f6fd !important; }
 
 .border-dark {
   border-color: #203656 !important; }
 
 .border-white {
   border-color: #ffffff !important; }
 
 .border-0 {
   border-width: 0 !important; }
 
 .border-1 {
   border-width: 1px !important; }
 
 .border-2 {
   border-width: 2px !important; }
 
 .border-3 {
   border-width: 3px !important; }
 
 .border-4 {
   border-width: 4px !important; }
 
 .border-5 {
   border-width: 5px !important; }
 
 .border-6 {
   border-width: 6 !important; }
 
 .border-7 {
   border-width: 7px !important; }
 
 .border-8 {
   border-width: 8px !important; }
 
 .border-9 {
   border-width: 9px !important; }
 
 .border-10 {
   border-width: 10px !important; }
 
 .w-25 {
   width: 25% !important; }
 
 .w-50 {
   width: 50% !important; }
 
 .w-75 {
   width: 75% !important; }
 
 .w-100 {
   width: 100% !important; }
 
 .w-auto {
   width: auto !important; }
 
 .mw-100 {
   max-width: 100% !important; }
 
 .vw-100 {
   width: 100vw !important; }
 
 .min-vw-100 {
   min-width: 100vw !important; }
 
 .h-25 {
   height: 25% !important; }
 
 .h-50 {
   height: 50% !important; }
 
 .h-75 {
   height: 75% !important; }
 
 .h-100 {
   height: 100% !important; }
 
 .h-auto {
   height: auto !important; }
 
 .mh-100 {
   max-height: 100% !important; }
 
 .vh-100 {
   height: 100vh !important; }
 
 .min-vh-100 {
   min-height: 100vh !important; }
 
 .flex-fill {
   flex: 1 1 auto !important; }
 
 .flex-row {
   flex-direction: row !important; }
 
 .flex-column {
   flex-direction: column !important; }
 
 .flex-row-reverse {
   flex-direction: row-reverse !important; }
 
 .flex-column-reverse {
   flex-direction: column-reverse !important; }
 
 .flex-grow-0 {
   flex-grow: 0 !important; }
 
 .flex-grow-1 {
   flex-grow: 1 !important; }
 
 .flex-shrink-0 {
   flex-shrink: 0 !important; }
 
 .flex-shrink-1 {
   flex-shrink: 1 !important; }
 
 .flex-wrap {
   flex-wrap: wrap !important; }
 
 .flex-nowrap {
   flex-wrap: nowrap !important; }
 
 .flex-wrap-reverse {
   flex-wrap: wrap-reverse !important; }
 
 .gap-0 {
   gap: 0 !important; }
 
 .gap-1 {
   gap: 0.25rem !important; }
 
 .gap-2 {
   gap: 0.5rem !important; }
 
 .gap-3 {
   gap: 1rem !important; }
 
 .gap-4 {
   gap: 1.5rem !important; }
 
 .gap-5 {
   gap: 2rem !important; }
 
 .gap-6 {
   gap: 2.5rem !important; }
 
 .gap-7 {
   gap: 3rem !important; }
 
 .gap-8 {
   gap: 3.5rem !important; }
 
 .gap-9 {
   gap: 4rem !important; }
 
 .gap-10 {
   gap: 5rem !important; }
 
 .gap-11 {
   gap: 6rem !important; }
 
 .gap-12 {
   gap: 7rem !important; }
 
 .justify-content-start {
   justify-content: flex-start !important; }
 
 .justify-content-end {
   justify-content: flex-end !important; }
 
 .justify-content-center {
   justify-content: center !important; }
 
 .justify-content-between {
   justify-content: space-between !important; }
 
 .justify-content-around {
   justify-content: space-around !important; }
 
 .justify-content-evenly {
   justify-content: space-evenly !important; }
 
 .align-items-start {
   align-items: flex-start !important; }
 
 .align-items-end {
   align-items: flex-end !important; }
 
 .align-items-center {
   align-items: center !important; }
 
 .align-items-baseline {
   align-items: baseline !important; }
 
 .align-items-stretch {
   align-items: stretch !important; }
 
 .align-content-start {
   align-content: flex-start !important; }
 
 .align-content-end {
   align-content: flex-end !important; }
 
 .align-content-center {
   align-content: center !important; }
 
 .align-content-between {
   align-content: space-between !important; }
 
 .align-content-around {
   align-content: space-around !important; }
 
 .align-content-stretch {
   align-content: stretch !important; }
 
 .align-self-auto {
   align-self: auto !important; }
 
 .align-self-start {
   align-self: flex-start !important; }
 
 .align-self-end {
   align-self: flex-end !important; }
 
 .align-self-center {
   align-self: center !important; }
 
 .align-self-baseline {
   align-self: baseline !important; }
 
 .align-self-stretch {
   align-self: stretch !important; }
 
 .order-first {
   order: -1 !important; }
 
 .order-0 {
   order: 0 !important; }
 
 .order-1 {
   order: 1 !important; }
 
 .order-2 {
   order: 2 !important; }
 
 .order-3 {
   order: 3 !important; }
 
 .order-4 {
   order: 4 !important; }
 
 .order-5 {
   order: 5 !important; }
 
 .order-last {
   order: 6 !important; }
 
 .m-0 {
   margin: 0 !important; }
 
 .m-1 {
   margin: 0.25rem !important; }
 
 .m-2 {
   margin: 0.5rem !important; }
 
 .m-3 {
   margin: 1rem !important; }
 
 .m-4 {
   margin: 1.5rem !important; }
 
 .m-5 {
   margin: 2rem !important; }
 
 .m-6 {
   margin: 2.5rem !important; }
 
 .m-7 {
   margin: 3rem !important; }
 
 .m-8 {
   margin: 3.5rem !important; }
 
 .m-9 {
   margin: 4rem !important; }
 
 .m-10 {
   margin: 5rem !important; }
 
 .m-11 {
   margin: 6rem !important; }
 
 .m-12 {
   margin: 7rem !important; }
 
 .m-auto {
   margin: auto !important; }
 
 .mx-0 {
   margin-right: 0 !important;
   margin-left: 0 !important; }
 
 .mx-1 {
   margin-right: 0.25rem !important;
   margin-left: 0.25rem !important; }
 
 .mx-2 {
   margin-right: 0.5rem !important;
   margin-left: 0.5rem !important; }
 
 .mx-3 {
   margin-right: 1rem !important;
   margin-left: 1rem !important; }
 
 .mx-4 {
   margin-right: 1.5rem !important;
   margin-left: 1.5rem !important; }
 
 .mx-5 {
   margin-right: 2rem !important;
   margin-left: 2rem !important; }
 
 .mx-6 {
   margin-right: 2.5rem !important;
   margin-left: 2.5rem !important; }
 
 .mx-7 {
   margin-right: 3rem !important;
   margin-left: 3rem !important; }
 
 .mx-8 {
   margin-right: 3.5rem !important;
   margin-left: 3.5rem !important; }
 
 .mx-9 {
   margin-right: 4rem !important;
   margin-left: 4rem !important; }
 
 .mx-10 {
   margin-right: 5rem !important;
   margin-left: 5rem !important; }
 
 .mx-11 {
   margin-right: 6rem !important;
   margin-left: 6rem !important; }
 
 .mx-12 {
   margin-right: 7rem !important;
   margin-left: 7rem !important; }
 
 .mx-auto {
   margin-right: auto !important;
   margin-left: auto !important; }
 
 .my-0 {
   margin-top: 0 !important;
   margin-bottom: 0 !important; }
 
 .my-1 {
   margin-top: 0.25rem !important;
   margin-bottom: 0.25rem !important; }
 
 .my-2 {
   margin-top: 0.5rem !important;
   margin-bottom: 0.5rem !important; }
 
 .my-3 {
   margin-top: 1rem !important;
   margin-bottom: 1rem !important; }
 
 .my-4 {
   margin-top: 1.5rem !important;
   margin-bottom: 1.5rem !important; }
 
 .my-5 {
   margin-top: 2rem !important;
   margin-bottom: 2rem !important; }
 
 .my-6 {
   margin-top: 2.5rem !important;
   margin-bottom: 2.5rem !important; }
 
 .my-7 {
   margin-top: 3rem !important;
   margin-bottom: 3rem !important; }
 
 .my-8 {
   margin-top: 3.5rem !important;
   margin-bottom: 3.5rem !important; }
 
 .my-9 {
   margin-top: 4rem !important;
   margin-bottom: 4rem !important; }
 
 .my-10 {
   margin-top: 5rem !important;
   margin-bottom: 5rem !important; }
 
 .my-11 {
   margin-top: 6rem !important;
   margin-bottom: 6rem !important; }
 
 .my-12 {
   margin-top: 7rem !important;
   margin-bottom: 7rem !important; }
 
 .my-auto {
   margin-top: auto !important;
   margin-bottom: auto !important; }
 
 .mt-0 {
   margin-top: 0 !important; }
 
 .mt-1 {
   margin-top: 0.25rem !important; }
 
 .mt-2 {
   margin-top: 0.5rem !important; }
 
 .mt-3 {
   margin-top: 1rem !important; }
 
 .mt-4 {
   margin-top: 1.5rem !important; }
 
 .mt-5 {
   margin-top: 2rem !important; }
 
 .mt-6 {
   margin-top: 2.5rem !important; }
 
 .mt-7 {
   margin-top: 3rem !important; }
 
 .mt-8 {
   margin-top: 3.5rem !important; }
 
 .mt-9 {
   margin-top: 4rem !important; }
 
 .mt-10 {
   margin-top: 5rem !important; }
 
 .mt-11 {
   margin-top: 6rem !important; }
 
 .mt-12 {
   margin-top: 7rem !important; }
 
 .mt-auto {
   margin-top: auto !important; }
 
 .me-0 {
   margin-right: 0 !important; }
 
 .me-1 {
   margin-right: 0.25rem !important; }
 
 .me-2 {
   margin-right: 0.5rem !important; }
 
 .me-3 {
   margin-right: 1rem !important; }
 
 .me-4 {
   margin-right: 1.5rem !important; }
 
 .me-5 {
   margin-right: 2rem !important; }
 
 .me-6 {
   margin-right: 2.5rem !important; }
 
 .me-7 {
   margin-right: 3rem !important; }
 
 .me-8 {
   margin-right: 3.5rem !important; }
 
 .me-9 {
   margin-right: 4rem !important; }
 
 .me-10 {
   margin-right: 5rem !important; }
 
 .me-11 {
   margin-right: 6rem !important; }
 
 .me-12 {
   margin-right: 7rem !important; }
 
 .me-auto {
   margin-right: auto !important; }
 
 .mb-0 {
   margin-bottom: 0 !important; }
 
 .mb-1 {
   margin-bottom: 0.25rem !important; }
 
 .mb-2 {
   margin-bottom: 0.5rem !important; }
 
 .mb-3 {
   margin-bottom: 1rem !important; }
 
 .mb-4 {
   margin-bottom: 1.5rem !important; }
 
 .mb-5 {
   margin-bottom: 2rem !important; }
 
 .mb-6 {
   margin-bottom: 2.5rem !important; }
 
 .mb-7 {
   margin-bottom: 3rem !important; }
 
 .mb-8 {
   margin-bottom: 3.5rem !important; }
 
 .mb-9 {
   margin-bottom: 4rem !important; }
 
 .mb-10 {
   margin-bottom: 5rem !important; }
 
 .mb-11 {
   margin-bottom: 6rem !important; }
 
 .mb-12 {
   margin-bottom: 7rem !important; }
 
 .mb-auto {
   margin-bottom: auto !important; }
 
 .ms-0 {
   margin-left: 0 !important; }
 
 .ms-1 {
   margin-left: 0.25rem !important; }
 
 .ms-2 {
   margin-left: 0.5rem !important; }
 
 .ms-3 {
   margin-left: 1rem !important; }
 
 .ms-4 {
   margin-left: 1.5rem !important; }
 
 .ms-5 {
   margin-left: 2rem !important; }
 
 .ms-6 {
   margin-left: 2.5rem !important; }
 
 .ms-7 {
   margin-left: 3rem !important; }
 
 .ms-8 {
   margin-left: 3.5rem !important; }
 
 .ms-9 {
   margin-left: 4rem !important; }
 
 .ms-10 {
   margin-left: 5rem !important; }
 
 .ms-11 {
   margin-left: 6rem !important; }
 
 .ms-12 {
   margin-left: 7rem !important; }
 
 .ms-auto {
   margin-left: auto !important; }
 
 .m-n1 {
   margin: -0.25rem !important; }
 
 .m-n2 {
   margin: -0.5rem !important; }
 
 .m-n3 {
   margin: -1rem !important; }
 
 .m-n4 {
   margin: -1.5rem !important; }
 
 .m-n5 {
   margin: -2rem !important; }
 
 .m-n6 {
   margin: -2.5rem !important; }
 
 .m-n7 {
   margin: -3rem !important; }
 
 .m-n8 {
   margin: -3.5rem !important; }
 
 .m-n9 {
   margin: -4rem !important; }
 
 .m-n10 {
   margin: -5rem !important; }
 
 .m-n11 {
   margin: -6rem !important; }
 
 .m-n12 {
   margin: -7rem !important; }
 
 .mx-n1 {
   margin-right: -0.25rem !important;
   margin-left: -0.25rem !important; }
 
 .mx-n2 {
   margin-right: -0.5rem !important;
   margin-left: -0.5rem !important; }
 
 .mx-n3 {
   margin-right: -1rem !important;
   margin-left: -1rem !important; }
 
 .mx-n4 {
   margin-right: -1.5rem !important;
   margin-left: -1.5rem !important; }
 
 .mx-n5 {
   margin-right: -2rem !important;
   margin-left: -2rem !important; }
 
 .mx-n6 {
   margin-right: -2.5rem !important;
   margin-left: -2.5rem !important; }
 
 .mx-n7 {
   margin-right: -3rem !important;
   margin-left: -3rem !important; }
 
 .mx-n8 {
   margin-right: -3.5rem !important;
   margin-left: -3.5rem !important; }
 
 .mx-n9 {
   margin-right: -4rem !important;
   margin-left: -4rem !important; }
 
 .mx-n10 {
   margin-right: -5rem !important;
   margin-left: -5rem !important; }
 
 .mx-n11 {
   margin-right: -6rem !important;
   margin-left: -6rem !important; }
 
 .mx-n12 {
   margin-right: -7rem !important;
   margin-left: -7rem !important; }
 
 .my-n1 {
   margin-top: -0.25rem !important;
   margin-bottom: -0.25rem !important; }
 
 .my-n2 {
   margin-top: -0.5rem !important;
   margin-bottom: -0.5rem !important; }
 
 .my-n3 {
   margin-top: -1rem !important;
   margin-bottom: -1rem !important; }
 
 .my-n4 {
   margin-top: -1.5rem !important;
   margin-bottom: -1.5rem !important; }
 
 .my-n5 {
   margin-top: -2rem !important;
   margin-bottom: -2rem !important; }
 
 .my-n6 {
   margin-top: -2.5rem !important;
   margin-bottom: -2.5rem !important; }
 
 .my-n7 {
   margin-top: -3rem !important;
   margin-bottom: -3rem !important; }
 
 .my-n8 {
   margin-top: -3.5rem !important;
   margin-bottom: -3.5rem !important; }
 
 .my-n9 {
   margin-top: -4rem !important;
   margin-bottom: -4rem !important; }
 
 .my-n10 {
   margin-top: -5rem !important;
   margin-bottom: -5rem !important; }
 
 .my-n11 {
   margin-top: -6rem !important;
   margin-bottom: -6rem !important; }
 
 .my-n12 {
   margin-top: -7rem !important;
   margin-bottom: -7rem !important; }
 
 .mt-n1 {
   margin-top: -0.25rem !important; }
 
 .mt-n2 {
   margin-top: -0.5rem !important; }
 
 .mt-n3 {
   margin-top: -1rem !important; }
 
 .mt-n4 {
   margin-top: -1.5rem !important; }
 
 .mt-n5 {
   margin-top: -2rem !important; }
 
 .mt-n6 {
   margin-top: -2.5rem !important; }
 
 .mt-n7 {
   margin-top: -3rem !important; }
 
 .mt-n8 {
   margin-top: -3.5rem !important; }
 
 .mt-n9 {
   margin-top: -4rem !important; }
 
 .mt-n10 {
   margin-top: -5rem !important; }
 
 .mt-n11 {
   margin-top: -6rem !important; }
 
 .mt-n12 {
   margin-top: -7rem !important; }
 
 .me-n1 {
   margin-right: -0.25rem !important; }
 
 .me-n2 {
   margin-right: -0.5rem !important; }
 
 .me-n3 {
   margin-right: -1rem !important; }
 
 .me-n4 {
   margin-right: -1.5rem !important; }
 
 .me-n5 {
   margin-right: -2rem !important; }
 
 .me-n6 {
   margin-right: -2.5rem !important; }
 
 .me-n7 {
   margin-right: -3rem !important; }
 
 .me-n8 {
   margin-right: -3.5rem !important; }
 
 .me-n9 {
   margin-right: -4rem !important; }
 
 .me-n10 {
   margin-right: -5rem !important; }
 
 .me-n11 {
   margin-right: -6rem !important; }
 
 .me-n12 {
   margin-right: -7rem !important; }
 
 .mb-n1 {
   margin-bottom: -0.25rem !important; }
 
 .mb-n2 {
   margin-bottom: -0.5rem !important; }
 
 .mb-n3 {
   margin-bottom: -1rem !important; }
 
 .mb-n4 {
   margin-bottom: -1.5rem !important; }
 
 .mb-n5 {
   margin-bottom: -2rem !important; }
 
 .mb-n6 {
   margin-bottom: -2.5rem !important; }
 
 .mb-n7 {
   margin-bottom: -3rem !important; }
 
 .mb-n8 {
   margin-bottom: -3.5rem !important; }
 
 .mb-n9 {
   margin-bottom: -4rem !important; }
 
 .mb-n10 {
   margin-bottom: -5rem !important; }
 
 .mb-n11 {
   margin-bottom: -6rem !important; }
 
 .mb-n12 {
   margin-bottom: -7rem !important; }
 
 .ms-n1 {
   margin-left: -0.25rem !important; }
 
 .ms-n2 {
   margin-left: -0.5rem !important; }
 
 .ms-n3 {
   margin-left: -1rem !important; }
 
 .ms-n4 {
   margin-left: -1.5rem !important; }
 
 .ms-n5 {
   margin-left: -2rem !important; }
 
 .ms-n6 {
   margin-left: -2.5rem !important; }
 
 .ms-n7 {
   margin-left: -3rem !important; }
 
 .ms-n8 {
   margin-left: -3.5rem !important; }
 
 .ms-n9 {
   margin-left: -4rem !important; }
 
 .ms-n10 {
   margin-left: -5rem !important; }
 
 .ms-n11 {
   margin-left: -6rem !important; }
 
 .ms-n12 {
   margin-left: -7rem !important; }
 
 .p-0 {
   padding: 0 !important; }
 
 .p-1 {
   padding: 0.25rem !important; }
 
 .p-2 {
   padding: 0.5rem !important; }
 
 .p-3 {
   padding: 1rem !important; }
 
 .p-4 {
   padding: 1.5rem !important; }
 
 .p-5 {
   padding: 2rem !important; }
 
 .p-6 {
   padding: 2.5rem !important; }
 
 .p-7 {
   padding: 3rem !important; }
 
 .p-8 {
   padding: 3.5rem !important; }
 
 .p-9 {
   padding: 4rem !important; }
 
 .p-10 {
   padding: 5rem !important; }
 
 .p-11 {
   padding: 6rem !important; }
 
 .p-12 {
   padding: 7rem !important; }
 
 .px-0 {
   padding-right: 0 !important;
   padding-left: 0 !important; }
 
 .px-1 {
   padding-right: 0.25rem !important;
   padding-left: 0.25rem !important; }
 
 .px-2 {
   padding-right: 0.5rem !important;
   padding-left: 0.5rem !important; }
 
 .px-3 {
   padding-right: 1rem !important;
   padding-left: 1rem !important; }
 
 .px-4 {
   padding-right: 1.5rem !important;
   padding-left: 1.5rem !important; }
 
 .px-5 {
   padding-right: 2rem !important;
   padding-left: 2rem !important; }
 
 .px-6 {
   padding-right: 2.5rem !important;
   padding-left: 2.5rem !important; }
 
 .px-7 {
   padding-right: 3rem !important;
   padding-left: 3rem !important; }
 
 .px-8 {
   padding-right: 3.5rem !important;
   padding-left: 3.5rem !important; }
 
 .px-9 {
   padding-right: 4rem !important;
   padding-left: 4rem !important; }
 
 .px-10 {
   padding-right: 5rem !important;
   padding-left: 5rem !important; }
 
 .px-11 {
   padding-right: 6rem !important;
   padding-left: 6rem !important; }
 
 .px-12 {
   padding-right: 7rem !important;
   padding-left: 7rem !important; }
 
 .py-0 {
   padding-top: 0 !important;
   padding-bottom: 0 !important; }
 
 .py-1 {
   padding-top: 0.25rem !important;
   padding-bottom: 0.25rem !important; }
 
 .py-2 {
   padding-top: 0.5rem !important;
   padding-bottom: 0.5rem !important; }
 
 .py-3 {
   padding-top: 1rem !important;
   padding-bottom: 1rem !important; }
 
 .py-4 {
   padding-top: 1.5rem !important;
   padding-bottom: 1.5rem !important; }
 
 .py-5 {
   padding-top: 2rem !important;
   padding-bottom: 2rem !important; }
 
 .py-6 {
   padding-top: 2.5rem !important;
   padding-bottom: 2.5rem !important; }
 
 .py-7 {
   padding-top: 3rem !important;
   padding-bottom: 3rem !important; }
 
 .py-8 {
   padding-top: 3.5rem !important;
   padding-bottom: 3.5rem !important; }
 
 .py-9 {
   padding-top: 4rem !important;
   padding-bottom: 4rem !important; }
 
 .py-10 {
   padding-top: 5rem !important;
   padding-bottom: 5rem !important; }
 
 .py-11 {
   padding-top: 6rem !important;
   padding-bottom: 6rem !important; }
 
 .py-12 {
   padding-top: 7rem !important;
   padding-bottom: 7rem !important; }
 
 .pt-0 {
   padding-top: 0 !important; }
 
 .pt-1 {
   padding-top: 0.25rem !important; }
 
 .pt-2 {
   padding-top: 0.5rem !important; }
 
 .pt-3 {
   padding-top: 1rem !important; }
 
 .pt-4 {
   padding-top: 1.5rem !important; }
 
 .pt-5 {
   padding-top: 2rem !important; }
 
 .pt-6 {
   padding-top: 2.5rem !important; }
 
 .pt-7 {
   padding-top: 3rem !important; }
 
 .pt-8 {
   padding-top: 3.5rem !important; }
 
 .pt-9 {
   padding-top: 4rem !important; }
 
 .pt-10 {
   padding-top: 5rem !important; }
 
 .pt-11 {
   padding-top: 6rem !important; }
 
 .pt-12 {
   padding-top: 7rem !important; }
 
 .pe-0 {
   padding-right: 0 !important; }
 
 .pe-1 {
   padding-right: 0.25rem !important; }
 
 .pe-2 {
   padding-right: 0.5rem !important; }
 
 .pe-3 {
   padding-right: 1rem !important; }
 
 .pe-4 {
   padding-right: 1.5rem !important; }
 
 .pe-5 {
   padding-right: 2rem !important; }
 
 .pe-6 {
   padding-right: 2.5rem !important; }
 
 .pe-7 {
   padding-right: 3rem !important; }
 
 .pe-8 {
   padding-right: 3.5rem !important; }
 
 .pe-9 {
   padding-right: 4rem !important; }
 
 .pe-10 {
   padding-right: 5rem !important; }
 
 .pe-11 {
   padding-right: 6rem !important; }
 
 .pe-12 {
   padding-right: 7rem !important; }
 
 .pb-0 {
   padding-bottom: 0 !important; }
 
 .pb-1 {
   padding-bottom: 0.25rem !important; }
 
 .pb-2 {
   padding-bottom: 0.5rem !important; }
 
 .pb-3 {
   padding-bottom: 1rem !important; }
 
 .pb-4 {
   padding-bottom: 1.5rem !important; }
 
 .pb-5 {
   padding-bottom: 2rem !important; }
 
 .pb-6 {
   padding-bottom: 2.5rem !important; }
 
 .pb-7 {
   padding-bottom: 3rem !important; }
 
 .pb-8 {
   padding-bottom: 3.5rem !important; }
 
 .pb-9 {
   padding-bottom: 4rem !important; }
 
 .pb-10 {
   padding-bottom: 5rem !important; }
 
 .pb-11 {
   padding-bottom: 6rem !important; }
 
 .pb-12 {
   padding-bottom: 7rem !important; }
 
 .ps-0 {
   padding-left: 0 !important; }
 
 .ps-1 {
   padding-left: 0.25rem !important; }
 
 .ps-2 {
   padding-left: 0.5rem !important; }
 
 .ps-3 {
   padding-left: 1rem !important; }
 
 .ps-4 {
   padding-left: 1.5rem !important; }
 
 .ps-5 {
   padding-left: 2rem !important; }
 
 .ps-6 {
   padding-left: 2.5rem !important; }
 
 .ps-7 {
   padding-left: 3rem !important; }
 
 .ps-8 {
   padding-left: 3.5rem !important; }
 
 .ps-9 {
   padding-left: 4rem !important; }
 
 .ps-10 {
   padding-left: 5rem !important; }
 
 .ps-11 {
   padding-left: 6rem !important; }
 
 .ps-12 {
   padding-left: 7rem !important; }
 
 .fs-1 {
   font-size: calc(1.375rem + 1.5vw) !important; }
 
 .fs-2 {
   font-size: calc(1.325rem + 0.9vw) !important; }
 
 .fs-3 {
   font-size: calc(1.3rem + 0.6vw) !important; }
 
 .fs-4 {
   font-size: calc(1.275rem + 0.3vw) !important; }
 
 .fs-5 {
   font-size: 1.25rem !important; }
 
 .fs-6 {
   font-size: 1rem !important; }
 
 .fst-italic {
   font-style: italic !important; }
 
 .fst-normal {
   font-style: normal !important; }
 
 .fw-light {
   font-weight: 300 !important; }
 
 .fw-lighter {
   font-weight: lighter !important; }
 
 .fw-normal {
   font-weight: 400 !important; }
 
 .fw-bold {
   font-weight: 700 !important; }
 
 .fw-bolder {
   font-weight: bolder !important; }
 
 .text-lowercase {
   text-transform: lowercase !important; }
 
 .text-uppercase {
   text-transform: uppercase !important; }
 
 .text-capitalize {
   text-transform: capitalize !important; }
 
 .text-start {
   text-align: left !important; }
 
 .text-end {
   text-align: right !important; }
 
 .text-center {
   text-align: center !important; }
 
 .text-primary {
   color: #FE4F70 !important; }
 
 .text-secondary {
   color: #6c757d !important; }
 
 .text-success {
   color: #2dca8c !important; }
 
 .text-info {
   color: #50b5ff !important; }
 
 .text-warning {
   color: #ff9f1c !important; }
 
 .text-danger {
   color: #ff5c75 !important; }
 
 .text-light {
   color: #f1f6fd !important; }
 
 .text-dark {
   color: #203656 !important; }
 
 .text-white {
   color: #ffffff !important; }
 
 .text-body {
   color: #8F9BAD !important; }
 
 .text-muted {
   color: #3a3a3a !important; }
 
 .text-black-50 {
   color: rgba(0, 0, 0, 0.5) !important; }
 
 .text-white-50 {
   color: rgba(255, 255, 255, 0.5) !important; }
 
 .text-reset {
   color: inherit !important; }
 
 .lh-1 {
   line-height: 1 !important; }
 
 .lh-sm {
   line-height: 1.25 !important; }
 
 .lh-base {
   line-height: 1.7 !important; }
 
 .lh-lg {
   line-height: 2 !important; }
 
 .bg-primary {
   background-color: #FE4F70 !important; }
 
 .bg-secondary {
   background-color: #6c757d !important; }
 
 .bg-success {
   background-color: #2dca8c !important; }
 
 .bg-info {
   background-color: #50b5ff !important; }
 
 .bg-warning {
   background-color: #ff9f1c !important; }
 
 .bg-danger {
   background-color: #ff5c75 !important; }
 
 .bg-light {
   background-color: #f1f6fd !important; }
 
 .bg-dark {
   background-color: #203656 !important; }
 
 .bg-body {
   background-color: #ffffff !important; }
 
 .bg-white {
   background-color: #ffffff !important; }
 
 .bg-transparent {
   background-color: transparent !important; }
 
 .bg-gradient {
   background-image: var(--bs-gradient) !important; }
 
 .text-wrap {
   white-space: normal !important; }
 
 .text-nowrap {
   white-space: nowrap !important; }
 
 .text-decoration-none {
   text-decoration: none !important; }
 
 .text-decoration-underline {
   text-decoration: underline !important; }
 
 .text-decoration-line-through {
   text-decoration: line-through !important; }
 
 /* rtl:begin:remove */
 .text-break {
   word-wrap: break-word !important;
   word-break: break-word !important; }
 
 /* rtl:end:remove */
 .font-monospace {
   font-family: var(--bs-font-monospace) !important; }
 
 .user-select-all {
   user-select: all !important; }
 
 .user-select-auto {
   user-select: auto !important; }
 
 .user-select-none {
   user-select: none !important; }
 
 .pe-none {
   pointer-events: none !important; }
 
 .pe-auto {
   pointer-events: auto !important; }
 
 .rounded {
   border-radius: 0.25rem !important; }
 
 .rounded-0 {
   border-radius: 0 !important; }
 
 .rounded-1 {
   border-radius: 0.2rem !important; }
 
 .rounded-2 {
   border-radius: 0.25rem !important; }
 
 .rounded-3 {
   border-radius: 0.3rem !important; }
 
 .rounded-circle {
   border-radius: 50% !important; }
 
 .rounded-pill {
   border-radius: 50rem !important; }
 
 .rounded-top {
   border-top-left-radius: 0.25rem !important;
   border-top-right-radius: 0.25rem !important; }
 
 .rounded-end {
   border-top-right-radius: 0.25rem !important;
   border-bottom-right-radius: 0.25rem !important; }
 
 .rounded-bottom {
   border-bottom-right-radius: 0.25rem !important;
   border-bottom-left-radius: 0.25rem !important; }
 
 .rounded-start {
   border-bottom-left-radius: 0.25rem !important;
   border-top-left-radius: 0.25rem !important; }
 
 .visible {
   visibility: visible !important; }
 
 .invisible {
   visibility: hidden !important; }
 
 @media (min-width: 576px) {
   .float-sm-start {
     float: left !important; }
   .float-sm-end {
     float: right !important; }
   .float-sm-none {
     float: none !important; }
   .d-sm-inline {
     display: inline !important; }
   .d-sm-inline-block {
     display: inline-block !important; }
   .d-sm-block {
     display: block !important; }
   .d-sm-grid {
     display: grid !important; }
   .d-sm-table {
     display: table !important; }
   .d-sm-table-row {
     display: table-row !important; }
   .d-sm-table-cell {
     display: table-cell !important; }
   .d-sm-flex {
     display: flex !important; }
   .d-sm-inline-flex {
     display: inline-flex !important; }
   .d-sm-none {
     display: none !important; }
   .flex-sm-fill {
     flex: 1 1 auto !important; }
   .flex-sm-row {
     flex-direction: row !important; }
   .flex-sm-column {
     flex-direction: column !important; }
   .flex-sm-row-reverse {
     flex-direction: row-reverse !important; }
   .flex-sm-column-reverse {
     flex-direction: column-reverse !important; }
   .flex-sm-grow-0 {
     flex-grow: 0 !important; }
   .flex-sm-grow-1 {
     flex-grow: 1 !important; }
   .flex-sm-shrink-0 {
     flex-shrink: 0 !important; }
   .flex-sm-shrink-1 {
     flex-shrink: 1 !important; }
   .flex-sm-wrap {
     flex-wrap: wrap !important; }
   .flex-sm-nowrap {
     flex-wrap: nowrap !important; }
   .flex-sm-wrap-reverse {
     flex-wrap: wrap-reverse !important; }
   .gap-sm-0 {
     gap: 0 !important; }
   .gap-sm-1 {
     gap: 0.25rem !important; }
   .gap-sm-2 {
     gap: 0.5rem !important; }
   .gap-sm-3 {
     gap: 1rem !important; }
   .gap-sm-4 {
     gap: 1.5rem !important; }
   .gap-sm-5 {
     gap: 2rem !important; }
   .gap-sm-6 {
     gap: 2.5rem !important; }
   .gap-sm-7 {
     gap: 3rem !important; }
   .gap-sm-8 {
     gap: 3.5rem !important; }
   .gap-sm-9 {
     gap: 4rem !important; }
   .gap-sm-10 {
     gap: 5rem !important; }
   .gap-sm-11 {
     gap: 6rem !important; }
   .gap-sm-12 {
     gap: 7rem !important; }
   .justify-content-sm-start {
     justify-content: flex-start !important; }
   .justify-content-sm-end {
     justify-content: flex-end !important; }
   .justify-content-sm-center {
     justify-content: center !important; }
   .justify-content-sm-between {
     justify-content: space-between !important; }
   .justify-content-sm-around {
     justify-content: space-around !important; }
   .justify-content-sm-evenly {
     justify-content: space-evenly !important; }
   .align-items-sm-start {
     align-items: flex-start !important; }
   .align-items-sm-end {
     align-items: flex-end !important; }
   .align-items-sm-center {
     align-items: center !important; }
   .align-items-sm-baseline {
     align-items: baseline !important; }
   .align-items-sm-stretch {
     align-items: stretch !important; }
   .align-content-sm-start {
     align-content: flex-start !important; }
   .align-content-sm-end {
     align-content: flex-end !important; }
   .align-content-sm-center {
     align-content: center !important; }
   .align-content-sm-between {
     align-content: space-between !important; }
   .align-content-sm-around {
     align-content: space-around !important; }
   .align-content-sm-stretch {
     align-content: stretch !important; }
   .align-self-sm-auto {
     align-self: auto !important; }
   .align-self-sm-start {
     align-self: flex-start !important; }
   .align-self-sm-end {
     align-self: flex-end !important; }
   .align-self-sm-center {
     align-self: center !important; }
   .align-self-sm-baseline {
     align-self: baseline !important; }
   .align-self-sm-stretch {
     align-self: stretch !important; }
   .order-sm-first {
     order: -1 !important; }
   .order-sm-0 {
     order: 0 !important; }
   .order-sm-1 {
     order: 1 !important; }
   .order-sm-2 {
     order: 2 !important; }
   .order-sm-3 {
     order: 3 !important; }
   .order-sm-4 {
     order: 4 !important; }
   .order-sm-5 {
     order: 5 !important; }
   .order-sm-last {
     order: 6 !important; }
   .m-sm-0 {
     margin: 0 !important; }
   .m-sm-1 {
     margin: 0.25rem !important; }
   .m-sm-2 {
     margin: 0.5rem !important; }
   .m-sm-3 {
     margin: 1rem !important; }
   .m-sm-4 {
     margin: 1.5rem !important; }
   .m-sm-5 {
     margin: 2rem !important; }
   .m-sm-6 {
     margin: 2.5rem !important; }
   .m-sm-7 {
     margin: 3rem !important; }
   .m-sm-8 {
     margin: 3.5rem !important; }
   .m-sm-9 {
     margin: 4rem !important; }
   .m-sm-10 {
     margin: 5rem !important; }
   .m-sm-11 {
     margin: 6rem !important; }
   .m-sm-12 {
     margin: 7rem !important; }
   .m-sm-auto {
     margin: auto !important; }
   .mx-sm-0 {
     margin-right: 0 !important;
     margin-left: 0 !important; }
   .mx-sm-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important; }
   .mx-sm-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important; }
   .mx-sm-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important; }
   .mx-sm-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important; }
   .mx-sm-5 {
     margin-right: 2rem !important;
     margin-left: 2rem !important; }
   .mx-sm-6 {
     margin-right: 2.5rem !important;
     margin-left: 2.5rem !important; }
   .mx-sm-7 {
     margin-right: 3rem !important;
     margin-left: 3rem !important; }
   .mx-sm-8 {
     margin-right: 3.5rem !important;
     margin-left: 3.5rem !important; }
   .mx-sm-9 {
     margin-right: 4rem !important;
     margin-left: 4rem !important; }
   .mx-sm-10 {
     margin-right: 5rem !important;
     margin-left: 5rem !important; }
   .mx-sm-11 {
     margin-right: 6rem !important;
     margin-left: 6rem !important; }
   .mx-sm-12 {
     margin-right: 7rem !important;
     margin-left: 7rem !important; }
   .mx-sm-auto {
     margin-right: auto !important;
     margin-left: auto !important; }
   .my-sm-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important; }
   .my-sm-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important; }
   .my-sm-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important; }
   .my-sm-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important; }
   .my-sm-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important; }
   .my-sm-5 {
     margin-top: 2rem !important;
     margin-bottom: 2rem !important; }
   .my-sm-6 {
     margin-top: 2.5rem !important;
     margin-bottom: 2.5rem !important; }
   .my-sm-7 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important; }
   .my-sm-8 {
     margin-top: 3.5rem !important;
     margin-bottom: 3.5rem !important; }
   .my-sm-9 {
     margin-top: 4rem !important;
     margin-bottom: 4rem !important; }
   .my-sm-10 {
     margin-top: 5rem !important;
     margin-bottom: 5rem !important; }
   .my-sm-11 {
     margin-top: 6rem !important;
     margin-bottom: 6rem !important; }
   .my-sm-12 {
     margin-top: 7rem !important;
     margin-bottom: 7rem !important; }
   .my-sm-auto {
     margin-top: auto !important;
     margin-bottom: auto !important; }
   .mt-sm-0 {
     margin-top: 0 !important; }
   .mt-sm-1 {
     margin-top: 0.25rem !important; }
   .mt-sm-2 {
     margin-top: 0.5rem !important; }
   .mt-sm-3 {
     margin-top: 1rem !important; }
   .mt-sm-4 {
     margin-top: 1.5rem !important; }
   .mt-sm-5 {
     margin-top: 2rem !important; }
   .mt-sm-6 {
     margin-top: 2.5rem !important; }
   .mt-sm-7 {
     margin-top: 3rem !important; }
   .mt-sm-8 {
     margin-top: 3.5rem !important; }
   .mt-sm-9 {
     margin-top: 4rem !important; }
   .mt-sm-10 {
     margin-top: 5rem !important; }
   .mt-sm-11 {
     margin-top: 6rem !important; }
   .mt-sm-12 {
     margin-top: 7rem !important; }
   .mt-sm-auto {
     margin-top: auto !important; }
   .me-sm-0 {
     margin-right: 0 !important; }
   .me-sm-1 {
     margin-right: 0.25rem !important; }
   .me-sm-2 {
     margin-right: 0.5rem !important; }
   .me-sm-3 {
     margin-right: 1rem !important; }
   .me-sm-4 {
     margin-right: 1.5rem !important; }
   .me-sm-5 {
     margin-right: 2rem !important; }
   .me-sm-6 {
     margin-right: 2.5rem !important; }
   .me-sm-7 {
     margin-right: 3rem !important; }
   .me-sm-8 {
     margin-right: 3.5rem !important; }
   .me-sm-9 {
     margin-right: 4rem !important; }
   .me-sm-10 {
     margin-right: 5rem !important; }
   .me-sm-11 {
     margin-right: 6rem !important; }
   .me-sm-12 {
     margin-right: 7rem !important; }
   .me-sm-auto {
     margin-right: auto !important; }
   .mb-sm-0 {
     margin-bottom: 0 !important; }
   .mb-sm-1 {
     margin-bottom: 0.25rem !important; }
   .mb-sm-2 {
     margin-bottom: 0.5rem !important; }
   .mb-sm-3 {
     margin-bottom: 1rem !important; }
   .mb-sm-4 {
     margin-bottom: 1.5rem !important; }
   .mb-sm-5 {
     margin-bottom: 2rem !important; }
   .mb-sm-6 {
     margin-bottom: 2.5rem !important; }
   .mb-sm-7 {
     margin-bottom: 3rem !important; }
   .mb-sm-8 {
     margin-bottom: 3.5rem !important; }
   .mb-sm-9 {
     margin-bottom: 4rem !important; }
   .mb-sm-10 {
     margin-bottom: 5rem !important; }
   .mb-sm-11 {
     margin-bottom: 6rem !important; }
   .mb-sm-12 {
     margin-bottom: 7rem !important; }
   .mb-sm-auto {
     margin-bottom: auto !important; }
   .ms-sm-0 {
     margin-left: 0 !important; }
   .ms-sm-1 {
     margin-left: 0.25rem !important; }
   .ms-sm-2 {
     margin-left: 0.5rem !important; }
   .ms-sm-3 {
     margin-left: 1rem !important; }
   .ms-sm-4 {
     margin-left: 1.5rem !important; }
   .ms-sm-5 {
     margin-left: 2rem !important; }
   .ms-sm-6 {
     margin-left: 2.5rem !important; }
   .ms-sm-7 {
     margin-left: 3rem !important; }
   .ms-sm-8 {
     margin-left: 3.5rem !important; }
   .ms-sm-9 {
     margin-left: 4rem !important; }
   .ms-sm-10 {
     margin-left: 5rem !important; }
   .ms-sm-11 {
     margin-left: 6rem !important; }
   .ms-sm-12 {
     margin-left: 7rem !important; }
   .ms-sm-auto {
     margin-left: auto !important; }
   .m-sm-n1 {
     margin: -0.25rem !important; }
   .m-sm-n2 {
     margin: -0.5rem !important; }
   .m-sm-n3 {
     margin: -1rem !important; }
   .m-sm-n4 {
     margin: -1.5rem !important; }
   .m-sm-n5 {
     margin: -2rem !important; }
   .m-sm-n6 {
     margin: -2.5rem !important; }
   .m-sm-n7 {
     margin: -3rem !important; }
   .m-sm-n8 {
     margin: -3.5rem !important; }
   .m-sm-n9 {
     margin: -4rem !important; }
   .m-sm-n10 {
     margin: -5rem !important; }
   .m-sm-n11 {
     margin: -6rem !important; }
   .m-sm-n12 {
     margin: -7rem !important; }
   .mx-sm-n1 {
     margin-right: -0.25rem !important;
     margin-left: -0.25rem !important; }
   .mx-sm-n2 {
     margin-right: -0.5rem !important;
     margin-left: -0.5rem !important; }
   .mx-sm-n3 {
     margin-right: -1rem !important;
     margin-left: -1rem !important; }
   .mx-sm-n4 {
     margin-right: -1.5rem !important;
     margin-left: -1.5rem !important; }
   .mx-sm-n5 {
     margin-right: -2rem !important;
     margin-left: -2rem !important; }
   .mx-sm-n6 {
     margin-right: -2.5rem !important;
     margin-left: -2.5rem !important; }
   .mx-sm-n7 {
     margin-right: -3rem !important;
     margin-left: -3rem !important; }
   .mx-sm-n8 {
     margin-right: -3.5rem !important;
     margin-left: -3.5rem !important; }
   .mx-sm-n9 {
     margin-right: -4rem !important;
     margin-left: -4rem !important; }
   .mx-sm-n10 {
     margin-right: -5rem !important;
     margin-left: -5rem !important; }
   .mx-sm-n11 {
     margin-right: -6rem !important;
     margin-left: -6rem !important; }
   .mx-sm-n12 {
     margin-right: -7rem !important;
     margin-left: -7rem !important; }
   .my-sm-n1 {
     margin-top: -0.25rem !important;
     margin-bottom: -0.25rem !important; }
   .my-sm-n2 {
     margin-top: -0.5rem !important;
     margin-bottom: -0.5rem !important; }
   .my-sm-n3 {
     margin-top: -1rem !important;
     margin-bottom: -1rem !important; }
   .my-sm-n4 {
     margin-top: -1.5rem !important;
     margin-bottom: -1.5rem !important; }
   .my-sm-n5 {
     margin-top: -2rem !important;
     margin-bottom: -2rem !important; }
   .my-sm-n6 {
     margin-top: -2.5rem !important;
     margin-bottom: -2.5rem !important; }
   .my-sm-n7 {
     margin-top: -3rem !important;
     margin-bottom: -3rem !important; }
   .my-sm-n8 {
     margin-top: -3.5rem !important;
     margin-bottom: -3.5rem !important; }
   .my-sm-n9 {
     margin-top: -4rem !important;
     margin-bottom: -4rem !important; }
   .my-sm-n10 {
     margin-top: -5rem !important;
     margin-bottom: -5rem !important; }
   .my-sm-n11 {
     margin-top: -6rem !important;
     margin-bottom: -6rem !important; }
   .my-sm-n12 {
     margin-top: -7rem !important;
     margin-bottom: -7rem !important; }
   .mt-sm-n1 {
     margin-top: -0.25rem !important; }
   .mt-sm-n2 {
     margin-top: -0.5rem !important; }
   .mt-sm-n3 {
     margin-top: -1rem !important; }
   .mt-sm-n4 {
     margin-top: -1.5rem !important; }
   .mt-sm-n5 {
     margin-top: -2rem !important; }
   .mt-sm-n6 {
     margin-top: -2.5rem !important; }
   .mt-sm-n7 {
     margin-top: -3rem !important; }
   .mt-sm-n8 {
     margin-top: -3.5rem !important; }
   .mt-sm-n9 {
     margin-top: -4rem !important; }
   .mt-sm-n10 {
     margin-top: -5rem !important; }
   .mt-sm-n11 {
     margin-top: -6rem !important; }
   .mt-sm-n12 {
     margin-top: -7rem !important; }
   .me-sm-n1 {
     margin-right: -0.25rem !important; }
   .me-sm-n2 {
     margin-right: -0.5rem !important; }
   .me-sm-n3 {
     margin-right: -1rem !important; }
   .me-sm-n4 {
     margin-right: -1.5rem !important; }
   .me-sm-n5 {
     margin-right: -2rem !important; }
   .me-sm-n6 {
     margin-right: -2.5rem !important; }
   .me-sm-n7 {
     margin-right: -3rem !important; }
   .me-sm-n8 {
     margin-right: -3.5rem !important; }
   .me-sm-n9 {
     margin-right: -4rem !important; }
   .me-sm-n10 {
     margin-right: -5rem !important; }
   .me-sm-n11 {
     margin-right: -6rem !important; }
   .me-sm-n12 {
     margin-right: -7rem !important; }
   .mb-sm-n1 {
     margin-bottom: -0.25rem !important; }
   .mb-sm-n2 {
     margin-bottom: -0.5rem !important; }
   .mb-sm-n3 {
     margin-bottom: -1rem !important; }
   .mb-sm-n4 {
     margin-bottom: -1.5rem !important; }
   .mb-sm-n5 {
     margin-bottom: -2rem !important; }
   .mb-sm-n6 {
     margin-bottom: -2.5rem !important; }
   .mb-sm-n7 {
     margin-bottom: -3rem !important; }
   .mb-sm-n8 {
     margin-bottom: -3.5rem !important; }
   .mb-sm-n9 {
     margin-bottom: -4rem !important; }
   .mb-sm-n10 {
     margin-bottom: -5rem !important; }
   .mb-sm-n11 {
     margin-bottom: -6rem !important; }
   .mb-sm-n12 {
     margin-bottom: -7rem !important; }
   .ms-sm-n1 {
     margin-left: -0.25rem !important; }
   .ms-sm-n2 {
     margin-left: -0.5rem !important; }
   .ms-sm-n3 {
     margin-left: -1rem !important; }
   .ms-sm-n4 {
     margin-left: -1.5rem !important; }
   .ms-sm-n5 {
     margin-left: -2rem !important; }
   .ms-sm-n6 {
     margin-left: -2.5rem !important; }
   .ms-sm-n7 {
     margin-left: -3rem !important; }
   .ms-sm-n8 {
     margin-left: -3.5rem !important; }
   .ms-sm-n9 {
     margin-left: -4rem !important; }
   .ms-sm-n10 {
     margin-left: -5rem !important; }
   .ms-sm-n11 {
     margin-left: -6rem !important; }
   .ms-sm-n12 {
     margin-left: -7rem !important; }
   .p-sm-0 {
     padding: 0 !important; }
   .p-sm-1 {
     padding: 0.25rem !important; }
   .p-sm-2 {
     padding: 0.5rem !important; }
   .p-sm-3 {
     padding: 1rem !important; }
   .p-sm-4 {
     padding: 1.5rem !important; }
   .p-sm-5 {
     padding: 2rem !important; }
   .p-sm-6 {
     padding: 2.5rem !important; }
   .p-sm-7 {
     padding: 3rem !important; }
   .p-sm-8 {
     padding: 3.5rem !important; }
   .p-sm-9 {
     padding: 4rem !important; }
   .p-sm-10 {
     padding: 5rem !important; }
   .p-sm-11 {
     padding: 6rem !important; }
   .p-sm-12 {
     padding: 7rem !important; }
   .px-sm-0 {
     padding-right: 0 !important;
     padding-left: 0 !important; }
   .px-sm-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important; }
   .px-sm-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important; }
   .px-sm-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important; }
   .px-sm-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important; }
   .px-sm-5 {
     padding-right: 2rem !important;
     padding-left: 2rem !important; }
   .px-sm-6 {
     padding-right: 2.5rem !important;
     padding-left: 2.5rem !important; }
   .px-sm-7 {
     padding-right: 3rem !important;
     padding-left: 3rem !important; }
   .px-sm-8 {
     padding-right: 3.5rem !important;
     padding-left: 3.5rem !important; }
   .px-sm-9 {
     padding-right: 4rem !important;
     padding-left: 4rem !important; }
   .px-sm-10 {
     padding-right: 5rem !important;
     padding-left: 5rem !important; }
   .px-sm-11 {
     padding-right: 6rem !important;
     padding-left: 6rem !important; }
   .px-sm-12 {
     padding-right: 7rem !important;
     padding-left: 7rem !important; }
   .py-sm-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important; }
   .py-sm-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important; }
   .py-sm-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important; }
   .py-sm-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important; }
   .py-sm-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important; }
   .py-sm-5 {
     padding-top: 2rem !important;
     padding-bottom: 2rem !important; }
   .py-sm-6 {
     padding-top: 2.5rem !important;
     padding-bottom: 2.5rem !important; }
   .py-sm-7 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important; }
   .py-sm-8 {
     padding-top: 3.5rem !important;
     padding-bottom: 3.5rem !important; }
   .py-sm-9 {
     padding-top: 4rem !important;
     padding-bottom: 4rem !important; }
   .py-sm-10 {
     padding-top: 5rem !important;
     padding-bottom: 5rem !important; }
   .py-sm-11 {
     padding-top: 6rem !important;
     padding-bottom: 6rem !important; }
   .py-sm-12 {
     padding-top: 7rem !important;
     padding-bottom: 7rem !important; }
   .pt-sm-0 {
     padding-top: 0 !important; }
   .pt-sm-1 {
     padding-top: 0.25rem !important; }
   .pt-sm-2 {
     padding-top: 0.5rem !important; }
   .pt-sm-3 {
     padding-top: 1rem !important; }
   .pt-sm-4 {
     padding-top: 1.5rem !important; }
   .pt-sm-5 {
     padding-top: 2rem !important; }
   .pt-sm-6 {
     padding-top: 2.5rem !important; }
   .pt-sm-7 {
     padding-top: 3rem !important; }
   .pt-sm-8 {
     padding-top: 3.5rem !important; }
   .pt-sm-9 {
     padding-top: 4rem !important; }
   .pt-sm-10 {
     padding-top: 5rem !important; }
   .pt-sm-11 {
     padding-top: 6rem !important; }
   .pt-sm-12 {
     padding-top: 7rem !important; }
   .pe-sm-0 {
     padding-right: 0 !important; }
   .pe-sm-1 {
     padding-right: 0.25rem !important; }
   .pe-sm-2 {
     padding-right: 0.5rem !important; }
   .pe-sm-3 {
     padding-right: 1rem !important; }
   .pe-sm-4 {
     padding-right: 1.5rem !important; }
   .pe-sm-5 {
     padding-right: 2rem !important; }
   .pe-sm-6 {
     padding-right: 2.5rem !important; }
   .pe-sm-7 {
     padding-right: 3rem !important; }
   .pe-sm-8 {
     padding-right: 3.5rem !important; }
   .pe-sm-9 {
     padding-right: 4rem !important; }
   .pe-sm-10 {
     padding-right: 5rem !important; }
   .pe-sm-11 {
     padding-right: 6rem !important; }
   .pe-sm-12 {
     padding-right: 7rem !important; }
   .pb-sm-0 {
     padding-bottom: 0 !important; }
   .pb-sm-1 {
     padding-bottom: 0.25rem !important; }
   .pb-sm-2 {
     padding-bottom: 0.5rem !important; }
   .pb-sm-3 {
     padding-bottom: 1rem !important; }
   .pb-sm-4 {
     padding-bottom: 1.5rem !important; }
   .pb-sm-5 {
     padding-bottom: 2rem !important; }
   .pb-sm-6 {
     padding-bottom: 2.5rem !important; }
   .pb-sm-7 {
     padding-bottom: 3rem !important; }
   .pb-sm-8 {
     padding-bottom: 3.5rem !important; }
   .pb-sm-9 {
     padding-bottom: 4rem !important; }
   .pb-sm-10 {
     padding-bottom: 5rem !important; }
   .pb-sm-11 {
     padding-bottom: 6rem !important; }
   .pb-sm-12 {
     padding-bottom: 7rem !important; }
   .ps-sm-0 {
     padding-left: 0 !important; }
   .ps-sm-1 {
     padding-left: 0.25rem !important; }
   .ps-sm-2 {
     padding-left: 0.5rem !important; }
   .ps-sm-3 {
     padding-left: 1rem !important; }
   .ps-sm-4 {
     padding-left: 1.5rem !important; }
   .ps-sm-5 {
     padding-left: 2rem !important; }
   .ps-sm-6 {
     padding-left: 2.5rem !important; }
   .ps-sm-7 {
     padding-left: 3rem !important; }
   .ps-sm-8 {
     padding-left: 3.5rem !important; }
   .ps-sm-9 {
     padding-left: 4rem !important; }
   .ps-sm-10 {
     padding-left: 5rem !important; }
   .ps-sm-11 {
     padding-left: 6rem !important; }
   .ps-sm-12 {
     padding-left: 7rem !important; }
   .text-sm-start {
     text-align: left !important; }
   .text-sm-end {
     text-align: right !important; }
   .text-sm-center {
     text-align: center !important; } }
 
 @media (min-width: 768px) {
   .float-md-start {
     float: left !important; }
   .float-md-end {
     float: right !important; }
   .float-md-none {
     float: none !important; }
   .d-md-inline {
     display: inline !important; }
   .d-md-inline-block {
     display: inline-block !important; }
   .d-md-block {
     display: block !important; }
   .d-md-grid {
     display: grid !important; }
   .d-md-table {
     display: table !important; }
   .d-md-table-row {
     display: table-row !important; }
   .d-md-table-cell {
     display: table-cell !important; }
   .d-md-flex {
     display: flex !important; }
   .d-md-inline-flex {
     display: inline-flex !important; }
   .d-md-none {
     display: none !important; }
   .flex-md-fill {
     flex: 1 1 auto !important; }
   .flex-md-row {
     flex-direction: row !important; }
   .flex-md-column {
     flex-direction: column !important; }
   .flex-md-row-reverse {
     flex-direction: row-reverse !important; }
   .flex-md-column-reverse {
     flex-direction: column-reverse !important; }
   .flex-md-grow-0 {
     flex-grow: 0 !important; }
   .flex-md-grow-1 {
     flex-grow: 1 !important; }
   .flex-md-shrink-0 {
     flex-shrink: 0 !important; }
   .flex-md-shrink-1 {
     flex-shrink: 1 !important; }
   .flex-md-wrap {
     flex-wrap: wrap !important; }
   .flex-md-nowrap {
     flex-wrap: nowrap !important; }
   .flex-md-wrap-reverse {
     flex-wrap: wrap-reverse !important; }
   .gap-md-0 {
     gap: 0 !important; }
   .gap-md-1 {
     gap: 0.25rem !important; }
   .gap-md-2 {
     gap: 0.5rem !important; }
   .gap-md-3 {
     gap: 1rem !important; }
   .gap-md-4 {
     gap: 1.5rem !important; }
   .gap-md-5 {
     gap: 2rem !important; }
   .gap-md-6 {
     gap: 2.5rem !important; }
   .gap-md-7 {
     gap: 3rem !important; }
   .gap-md-8 {
     gap: 3.5rem !important; }
   .gap-md-9 {
     gap: 4rem !important; }
   .gap-md-10 {
     gap: 5rem !important; }
   .gap-md-11 {
     gap: 6rem !important; }
   .gap-md-12 {
     gap: 7rem !important; }
   .justify-content-md-start {
     justify-content: flex-start !important; }
   .justify-content-md-end {
     justify-content: flex-end !important; }
   .justify-content-md-center {
     justify-content: center !important; }
   .justify-content-md-between {
     justify-content: space-between !important; }
   .justify-content-md-around {
     justify-content: space-around !important; }
   .justify-content-md-evenly {
     justify-content: space-evenly !important; }
   .align-items-md-start {
     align-items: flex-start !important; }
   .align-items-md-end {
     align-items: flex-end !important; }
   .align-items-md-center {
     align-items: center !important; }
   .align-items-md-baseline {
     align-items: baseline !important; }
   .align-items-md-stretch {
     align-items: stretch !important; }
   .align-content-md-start {
     align-content: flex-start !important; }
   .align-content-md-end {
     align-content: flex-end !important; }
   .align-content-md-center {
     align-content: center !important; }
   .align-content-md-between {
     align-content: space-between !important; }
   .align-content-md-around {
     align-content: space-around !important; }
   .align-content-md-stretch {
     align-content: stretch !important; }
   .align-self-md-auto {
     align-self: auto !important; }
   .align-self-md-start {
     align-self: flex-start !important; }
   .align-self-md-end {
     align-self: flex-end !important; }
   .align-self-md-center {
     align-self: center !important; }
   .align-self-md-baseline {
     align-self: baseline !important; }
   .align-self-md-stretch {
     align-self: stretch !important; }
   .order-md-first {
     order: -1 !important; }
   .order-md-0 {
     order: 0 !important; }
   .order-md-1 {
     order: 1 !important; }
   .order-md-2 {
     order: 2 !important; }
   .order-md-3 {
     order: 3 !important; }
   .order-md-4 {
     order: 4 !important; }
   .order-md-5 {
     order: 5 !important; }
   .order-md-last {
     order: 6 !important; }
   .m-md-0 {
     margin: 0 !important; }
   .m-md-1 {
     margin: 0.25rem !important; }
   .m-md-2 {
     margin: 0.5rem !important; }
   .m-md-3 {
     margin: 1rem !important; }
   .m-md-4 {
     margin: 1.5rem !important; }
   .m-md-5 {
     margin: 2rem !important; }
   .m-md-6 {
     margin: 2.5rem !important; }
   .m-md-7 {
     margin: 3rem !important; }
   .m-md-8 {
     margin: 3.5rem !important; }
   .m-md-9 {
     margin: 4rem !important; }
   .m-md-10 {
     margin: 5rem !important; }
   .m-md-11 {
     margin: 6rem !important; }
   .m-md-12 {
     margin: 7rem !important; }
   .m-md-auto {
     margin: auto !important; }
   .mx-md-0 {
     margin-right: 0 !important;
     margin-left: 0 !important; }
   .mx-md-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important; }
   .mx-md-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important; }
   .mx-md-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important; }
   .mx-md-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important; }
   .mx-md-5 {
     margin-right: 2rem !important;
     margin-left: 2rem !important; }
   .mx-md-6 {
     margin-right: 2.5rem !important;
     margin-left: 2.5rem !important; }
   .mx-md-7 {
     margin-right: 3rem !important;
     margin-left: 3rem !important; }
   .mx-md-8 {
     margin-right: 3.5rem !important;
     margin-left: 3.5rem !important; }
   .mx-md-9 {
     margin-right: 4rem !important;
     margin-left: 4rem !important; }
   .mx-md-10 {
     margin-right: 5rem !important;
     margin-left: 5rem !important; }
   .mx-md-11 {
     margin-right: 6rem !important;
     margin-left: 6rem !important; }
   .mx-md-12 {
     margin-right: 7rem !important;
     margin-left: 7rem !important; }
   .mx-md-auto {
     margin-right: auto !important;
     margin-left: auto !important; }
   .my-md-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important; }
   .my-md-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important; }
   .my-md-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important; }
   .my-md-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important; }
   .my-md-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important; }
   .my-md-5 {
     margin-top: 2rem !important;
     margin-bottom: 2rem !important; }
   .my-md-6 {
     margin-top: 2.5rem !important;
     margin-bottom: 2.5rem !important; }
   .my-md-7 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important; }
   .my-md-8 {
     margin-top: 3.5rem !important;
     margin-bottom: 3.5rem !important; }
   .my-md-9 {
     margin-top: 4rem !important;
     margin-bottom: 4rem !important; }
   .my-md-10 {
     margin-top: 5rem !important;
     margin-bottom: 5rem !important; }
   .my-md-11 {
     margin-top: 6rem !important;
     margin-bottom: 6rem !important; }
   .my-md-12 {
     margin-top: 7rem !important;
     margin-bottom: 7rem !important; }
   .my-md-auto {
     margin-top: auto !important;
     margin-bottom: auto !important; }
   .mt-md-0 {
     margin-top: 0 !important; }
   .mt-md-1 {
     margin-top: 0.25rem !important; }
   .mt-md-2 {
     margin-top: 0.5rem !important; }
   .mt-md-3 {
     margin-top: 1rem !important; }
   .mt-md-4 {
     margin-top: 1.5rem !important; }
   .mt-md-5 {
     margin-top: 2rem !important; }
   .mt-md-6 {
     margin-top: 2.5rem !important; }
   .mt-md-7 {
     margin-top: 3rem !important; }
   .mt-md-8 {
     margin-top: 3.5rem !important; }
   .mt-md-9 {
     margin-top: 4rem !important; }
   .mt-md-10 {
     margin-top: 5rem !important; }
   .mt-md-11 {
     margin-top: 6rem !important; }
   .mt-md-12 {
     margin-top: 7rem !important; }
   .mt-md-auto {
     margin-top: auto !important; }
   .me-md-0 {
     margin-right: 0 !important; }
   .me-md-1 {
     margin-right: 0.25rem !important; }
   .me-md-2 {
     margin-right: 0.5rem !important; }
   .me-md-3 {
     margin-right: 1rem !important; }
   .me-md-4 {
     margin-right: 1.5rem !important; }
   .me-md-5 {
     margin-right: 2rem !important; }
   .me-md-6 {
     margin-right: 2.5rem !important; }
   .me-md-7 {
     margin-right: 3rem !important; }
   .me-md-8 {
     margin-right: 3.5rem !important; }
   .me-md-9 {
     margin-right: 4rem !important; }
   .me-md-10 {
     margin-right: 5rem !important; }
   .me-md-11 {
     margin-right: 6rem !important; }
   .me-md-12 {
     margin-right: 7rem !important; }
   .me-md-auto {
     margin-right: auto !important; }
   .mb-md-0 {
     margin-bottom: 0 !important; }
   .mb-md-1 {
     margin-bottom: 0.25rem !important; }
   .mb-md-2 {
     margin-bottom: 0.5rem !important; }
   .mb-md-3 {
     margin-bottom: 1rem !important; }
   .mb-md-4 {
     margin-bottom: 1.5rem !important; }
   .mb-md-5 {
     margin-bottom: 2rem !important; }
   .mb-md-6 {
     margin-bottom: 2.5rem !important; }
   .mb-md-7 {
     margin-bottom: 3rem !important; }
   .mb-md-8 {
     margin-bottom: 3.5rem !important; }
   .mb-md-9 {
     margin-bottom: 4rem !important; }
   .mb-md-10 {
     margin-bottom: 5rem !important; }
   .mb-md-11 {
     margin-bottom: 6rem !important; }
   .mb-md-12 {
     margin-bottom: 7rem !important; }
   .mb-md-auto {
     margin-bottom: auto !important; }
   .ms-md-0 {
     margin-left: 0 !important; }
   .ms-md-1 {
     margin-left: 0.25rem !important; }
   .ms-md-2 {
     margin-left: 0.5rem !important; }
   .ms-md-3 {
     margin-left: 1rem !important; }
   .ms-md-4 {
     margin-left: 1.5rem !important; }
   .ms-md-5 {
     margin-left: 2rem !important; }
   .ms-md-6 {
     margin-left: 2.5rem !important; }
   .ms-md-7 {
     margin-left: 3rem !important; }
   .ms-md-8 {
     margin-left: 3.5rem !important; }
   .ms-md-9 {
     margin-left: 4rem !important; }
   .ms-md-10 {
     margin-left: 5rem !important; }
   .ms-md-11 {
     margin-left: 6rem !important; }
   .ms-md-12 {
     margin-left: 7rem !important; }
   .ms-md-auto {
     margin-left: auto !important; }
   .m-md-n1 {
     margin: -0.25rem !important; }
   .m-md-n2 {
     margin: -0.5rem !important; }
   .m-md-n3 {
     margin: -1rem !important; }
   .m-md-n4 {
     margin: -1.5rem !important; }
   .m-md-n5 {
     margin: -2rem !important; }
   .m-md-n6 {
     margin: -2.5rem !important; }
   .m-md-n7 {
     margin: -3rem !important; }
   .m-md-n8 {
     margin: -3.5rem !important; }
   .m-md-n9 {
     margin: -4rem !important; }
   .m-md-n10 {
     margin: -5rem !important; }
   .m-md-n11 {
     margin: -6rem !important; }
   .m-md-n12 {
     margin: -7rem !important; }
   .mx-md-n1 {
     margin-right: -0.25rem !important;
     margin-left: -0.25rem !important; }
   .mx-md-n2 {
     margin-right: -0.5rem !important;
     margin-left: -0.5rem !important; }
   .mx-md-n3 {
     margin-right: -1rem !important;
     margin-left: -1rem !important; }
   .mx-md-n4 {
     margin-right: -1.5rem !important;
     margin-left: -1.5rem !important; }
   .mx-md-n5 {
     margin-right: -2rem !important;
     margin-left: -2rem !important; }
   .mx-md-n6 {
     margin-right: -2.5rem !important;
     margin-left: -2.5rem !important; }
   .mx-md-n7 {
     margin-right: -3rem !important;
     margin-left: -3rem !important; }
   .mx-md-n8 {
     margin-right: -3.5rem !important;
     margin-left: -3.5rem !important; }
   .mx-md-n9 {
     margin-right: -4rem !important;
     margin-left: -4rem !important; }
   .mx-md-n10 {
     margin-right: -5rem !important;
     margin-left: -5rem !important; }
   .mx-md-n11 {
     margin-right: -6rem !important;
     margin-left: -6rem !important; }
   .mx-md-n12 {
     margin-right: -7rem !important;
     margin-left: -7rem !important; }
   .my-md-n1 {
     margin-top: -0.25rem !important;
     margin-bottom: -0.25rem !important; }
   .my-md-n2 {
     margin-top: -0.5rem !important;
     margin-bottom: -0.5rem !important; }
   .my-md-n3 {
     margin-top: -1rem !important;
     margin-bottom: -1rem !important; }
   .my-md-n4 {
     margin-top: -1.5rem !important;
     margin-bottom: -1.5rem !important; }
   .my-md-n5 {
     margin-top: -2rem !important;
     margin-bottom: -2rem !important; }
   .my-md-n6 {
     margin-top: -2.5rem !important;
     margin-bottom: -2.5rem !important; }
   .my-md-n7 {
     margin-top: -3rem !important;
     margin-bottom: -3rem !important; }
   .my-md-n8 {
     margin-top: -3.5rem !important;
     margin-bottom: -3.5rem !important; }
   .my-md-n9 {
     margin-top: -4rem !important;
     margin-bottom: -4rem !important; }
   .my-md-n10 {
     margin-top: -5rem !important;
     margin-bottom: -5rem !important; }
   .my-md-n11 {
     margin-top: -6rem !important;
     margin-bottom: -6rem !important; }
   .my-md-n12 {
     margin-top: -7rem !important;
     margin-bottom: -7rem !important; }
   .mt-md-n1 {
     margin-top: -0.25rem !important; }
   .mt-md-n2 {
     margin-top: -0.5rem !important; }
   .mt-md-n3 {
     margin-top: -1rem !important; }
   .mt-md-n4 {
     margin-top: -1.5rem !important; }
   .mt-md-n5 {
     margin-top: -2rem !important; }
   .mt-md-n6 {
     margin-top: -2.5rem !important; }
   .mt-md-n7 {
     margin-top: -3rem !important; }
   .mt-md-n8 {
     margin-top: -3.5rem !important; }
   .mt-md-n9 {
     margin-top: -4rem !important; }
   .mt-md-n10 {
     margin-top: -5rem !important; }
   .mt-md-n11 {
     margin-top: -6rem !important; }
   .mt-md-n12 {
     margin-top: -7rem !important; }
   .me-md-n1 {
     margin-right: -0.25rem !important; }
   .me-md-n2 {
     margin-right: -0.5rem !important; }
   .me-md-n3 {
     margin-right: -1rem !important; }
   .me-md-n4 {
     margin-right: -1.5rem !important; }
   .me-md-n5 {
     margin-right: -2rem !important; }
   .me-md-n6 {
     margin-right: -2.5rem !important; }
   .me-md-n7 {
     margin-right: -3rem !important; }
   .me-md-n8 {
     margin-right: -3.5rem !important; }
   .me-md-n9 {
     margin-right: -4rem !important; }
   .me-md-n10 {
     margin-right: -5rem !important; }
   .me-md-n11 {
     margin-right: -6rem !important; }
   .me-md-n12 {
     margin-right: -7rem !important; }
   .mb-md-n1 {
     margin-bottom: -0.25rem !important; }
   .mb-md-n2 {
     margin-bottom: -0.5rem !important; }
   .mb-md-n3 {
     margin-bottom: -1rem !important; }
   .mb-md-n4 {
     margin-bottom: -1.5rem !important; }
   .mb-md-n5 {
     margin-bottom: -2rem !important; }
   .mb-md-n6 {
     margin-bottom: -2.5rem !important; }
   .mb-md-n7 {
     margin-bottom: -3rem !important; }
   .mb-md-n8 {
     margin-bottom: -3.5rem !important; }
   .mb-md-n9 {
     margin-bottom: -4rem !important; }
   .mb-md-n10 {
     margin-bottom: -5rem !important; }
   .mb-md-n11 {
     margin-bottom: -6rem !important; }
   .mb-md-n12 {
     margin-bottom: -7rem !important; }
   .ms-md-n1 {
     margin-left: -0.25rem !important; }
   .ms-md-n2 {
     margin-left: -0.5rem !important; }
   .ms-md-n3 {
     margin-left: -1rem !important; }
   .ms-md-n4 {
     margin-left: -1.5rem !important; }
   .ms-md-n5 {
     margin-left: -2rem !important; }
   .ms-md-n6 {
     margin-left: -2.5rem !important; }
   .ms-md-n7 {
     margin-left: -3rem !important; }
   .ms-md-n8 {
     margin-left: -3.5rem !important; }
   .ms-md-n9 {
     margin-left: -4rem !important; }
   .ms-md-n10 {
     margin-left: -5rem !important; }
   .ms-md-n11 {
     margin-left: -6rem !important; }
   .ms-md-n12 {
     margin-left: -7rem !important; }
   .p-md-0 {
     padding: 0 !important; }
   .p-md-1 {
     padding: 0.25rem !important; }
   .p-md-2 {
     padding: 0.5rem !important; }
   .p-md-3 {
     padding: 1rem !important; }
   .p-md-4 {
     padding: 1.5rem !important; }
   .p-md-5 {
     padding: 2rem !important; }
   .p-md-6 {
     padding: 2.5rem !important; }
   .p-md-7 {
     padding: 3rem !important; }
   .p-md-8 {
     padding: 3.5rem !important; }
   .p-md-9 {
     padding: 4rem !important; }
   .p-md-10 {
     padding: 5rem !important; }
   .p-md-11 {
     padding: 6rem !important; }
   .p-md-12 {
     padding: 7rem !important; }
   .px-md-0 {
     padding-right: 0 !important;
     padding-left: 0 !important; }
   .px-md-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important; }
   .px-md-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important; }
   .px-md-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important; }
   .px-md-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important; }
   .px-md-5 {
     padding-right: 2rem !important;
     padding-left: 2rem !important; }
   .px-md-6 {
     padding-right: 2.5rem !important;
     padding-left: 2.5rem !important; }
   .px-md-7 {
     padding-right: 3rem !important;
     padding-left: 3rem !important; }
   .px-md-8 {
     padding-right: 3.5rem !important;
     padding-left: 3.5rem !important; }
   .px-md-9 {
     padding-right: 4rem !important;
     padding-left: 4rem !important; }
   .px-md-10 {
     padding-right: 5rem !important;
     padding-left: 5rem !important; }
   .px-md-11 {
     padding-right: 6rem !important;
     padding-left: 6rem !important; }
   .px-md-12 {
     padding-right: 7rem !important;
     padding-left: 7rem !important; }
   .py-md-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important; }
   .py-md-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important; }
   .py-md-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important; }
   .py-md-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important; }
   .py-md-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important; }
   .py-md-5 {
     padding-top: 2rem !important;
     padding-bottom: 2rem !important; }
   .py-md-6 {
     padding-top: 2.5rem !important;
     padding-bottom: 2.5rem !important; }
   .py-md-7 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important; }
   .py-md-8 {
     padding-top: 3.5rem !important;
     padding-bottom: 3.5rem !important; }
   .py-md-9 {
     padding-top: 4rem !important;
     padding-bottom: 4rem !important; }
   .py-md-10 {
     padding-top: 5rem !important;
     padding-bottom: 5rem !important; }
   .py-md-11 {
     padding-top: 6rem !important;
     padding-bottom: 6rem !important; }
   .py-md-12 {
     padding-top: 7rem !important;
     padding-bottom: 7rem !important; }
   .pt-md-0 {
     padding-top: 0 !important; }
   .pt-md-1 {
     padding-top: 0.25rem !important; }
   .pt-md-2 {
     padding-top: 0.5rem !important; }
   .pt-md-3 {
     padding-top: 1rem !important; }
   .pt-md-4 {
     padding-top: 1.5rem !important; }
   .pt-md-5 {
     padding-top: 2rem !important; }
   .pt-md-6 {
     padding-top: 2.5rem !important; }
   .pt-md-7 {
     padding-top: 3rem !important; }
   .pt-md-8 {
     padding-top: 3.5rem !important; }
   .pt-md-9 {
     padding-top: 4rem !important; }
   .pt-md-10 {
     padding-top: 5rem !important; }
   .pt-md-11 {
     padding-top: 6rem !important; }
   .pt-md-12 {
     padding-top: 7rem !important; }
   .pe-md-0 {
     padding-right: 0 !important; }
   .pe-md-1 {
     padding-right: 0.25rem !important; }
   .pe-md-2 {
     padding-right: 0.5rem !important; }
   .pe-md-3 {
     padding-right: 1rem !important; }
   .pe-md-4 {
     padding-right: 1.5rem !important; }
   .pe-md-5 {
     padding-right: 2rem !important; }
   .pe-md-6 {
     padding-right: 2.5rem !important; }
   .pe-md-7 {
     padding-right: 3rem !important; }
   .pe-md-8 {
     padding-right: 3.5rem !important; }
   .pe-md-9 {
     padding-right: 4rem !important; }
   .pe-md-10 {
     padding-right: 5rem !important; }
   .pe-md-11 {
     padding-right: 6rem !important; }
   .pe-md-12 {
     padding-right: 7rem !important; }
   .pb-md-0 {
     padding-bottom: 0 !important; }
   .pb-md-1 {
     padding-bottom: 0.25rem !important; }
   .pb-md-2 {
     padding-bottom: 0.5rem !important; }
   .pb-md-3 {
     padding-bottom: 1rem !important; }
   .pb-md-4 {
     padding-bottom: 1.5rem !important; }
   .pb-md-5 {
     padding-bottom: 2rem !important; }
   .pb-md-6 {
     padding-bottom: 2.5rem !important; }
   .pb-md-7 {
     padding-bottom: 3rem !important; }
   .pb-md-8 {
     padding-bottom: 3.5rem !important; }
   .pb-md-9 {
     padding-bottom: 4rem !important; }
   .pb-md-10 {
     padding-bottom: 5rem !important; }
   .pb-md-11 {
     padding-bottom: 6rem !important; }
   .pb-md-12 {
     padding-bottom: 7rem !important; }
   .ps-md-0 {
     padding-left: 0 !important; }
   .ps-md-1 {
     padding-left: 0.25rem !important; }
   .ps-md-2 {
     padding-left: 0.5rem !important; }
   .ps-md-3 {
     padding-left: 1rem !important; }
   .ps-md-4 {
     padding-left: 1.5rem !important; }
   .ps-md-5 {
     padding-left: 2rem !important; }
   .ps-md-6 {
     padding-left: 2.5rem !important; }
   .ps-md-7 {
     padding-left: 3rem !important; }
   .ps-md-8 {
     padding-left: 3.5rem !important; }
   .ps-md-9 {
     padding-left: 4rem !important; }
   .ps-md-10 {
     padding-left: 5rem !important; }
   .ps-md-11 {
     padding-left: 6rem !important; }
   .ps-md-12 {
     padding-left: 7rem !important; }
   .text-md-start {
     text-align: left !important; }
   .text-md-end {
     text-align: right !important; }
   .text-md-center {
     text-align: center !important; } }
 
 @media (min-width: 992px) {
   .float-lg-start {
     float: left !important; }
   .float-lg-end {
     float: right !important; }
   .float-lg-none {
     float: none !important; }
   .d-lg-inline {
     display: inline !important; }
   .d-lg-inline-block {
     display: inline-block !important; }
   .d-lg-block {
     display: block !important; }
   .d-lg-grid {
     display: grid !important; }
   .d-lg-table {
     display: table !important; }
   .d-lg-table-row {
     display: table-row !important; }
   .d-lg-table-cell {
     display: table-cell !important; }
   .d-lg-flex {
     display: flex !important; }
   .d-lg-inline-flex {
     display: inline-flex !important; }
   .d-lg-none {
     display: none !important; }
   .flex-lg-fill {
     flex: 1 1 auto !important; }
   .flex-lg-row {
     flex-direction: row !important; }
   .flex-lg-column {
     flex-direction: column !important; }
   .flex-lg-row-reverse {
     flex-direction: row-reverse !important; }
   .flex-lg-column-reverse {
     flex-direction: column-reverse !important; }
   .flex-lg-grow-0 {
     flex-grow: 0 !important; }
   .flex-lg-grow-1 {
     flex-grow: 1 !important; }
   .flex-lg-shrink-0 {
     flex-shrink: 0 !important; }
   .flex-lg-shrink-1 {
     flex-shrink: 1 !important; }
   .flex-lg-wrap {
     flex-wrap: wrap !important; }
   .flex-lg-nowrap {
     flex-wrap: nowrap !important; }
   .flex-lg-wrap-reverse {
     flex-wrap: wrap-reverse !important; }
   .gap-lg-0 {
     gap: 0 !important; }
   .gap-lg-1 {
     gap: 0.25rem !important; }
   .gap-lg-2 {
     gap: 0.5rem !important; }
   .gap-lg-3 {
     gap: 1rem !important; }
   .gap-lg-4 {
     gap: 1.5rem !important; }
   .gap-lg-5 {
     gap: 2rem !important; }
   .gap-lg-6 {
     gap: 2.5rem !important; }
   .gap-lg-7 {
     gap: 3rem !important; }
   .gap-lg-8 {
     gap: 3.5rem !important; }
   .gap-lg-9 {
     gap: 4rem !important; }
   .gap-lg-10 {
     gap: 5rem !important; }
   .gap-lg-11 {
     gap: 6rem !important; }
   .gap-lg-12 {
     gap: 7rem !important; }
   .justify-content-lg-start {
     justify-content: flex-start !important; }
   .justify-content-lg-end {
     justify-content: flex-end !important; }
   .justify-content-lg-center {
     justify-content: center !important; }
   .justify-content-lg-between {
     justify-content: space-between !important; }
   .justify-content-lg-around {
     justify-content: space-around !important; }
   .justify-content-lg-evenly {
     justify-content: space-evenly !important; }
   .align-items-lg-start {
     align-items: flex-start !important; }
   .align-items-lg-end {
     align-items: flex-end !important; }
   .align-items-lg-center {
     align-items: center !important; }
   .align-items-lg-baseline {
     align-items: baseline !important; }
   .align-items-lg-stretch {
     align-items: stretch !important; }
   .align-content-lg-start {
     align-content: flex-start !important; }
   .align-content-lg-end {
     align-content: flex-end !important; }
   .align-content-lg-center {
     align-content: center !important; }
   .align-content-lg-between {
     align-content: space-between !important; }
   .align-content-lg-around {
     align-content: space-around !important; }
   .align-content-lg-stretch {
     align-content: stretch !important; }
   .align-self-lg-auto {
     align-self: auto !important; }
   .align-self-lg-start {
     align-self: flex-start !important; }
   .align-self-lg-end {
     align-self: flex-end !important; }
   .align-self-lg-center {
     align-self: center !important; }
   .align-self-lg-baseline {
     align-self: baseline !important; }
   .align-self-lg-stretch {
     align-self: stretch !important; }
   .order-lg-first {
     order: -1 !important; }
   .order-lg-0 {
     order: 0 !important; }
   .order-lg-1 {
     order: 1 !important; }
   .order-lg-2 {
     order: 2 !important; }
   .order-lg-3 {
     order: 3 !important; }
   .order-lg-4 {
     order: 4 !important; }
   .order-lg-5 {
     order: 5 !important; }
   .order-lg-last {
     order: 6 !important; }
   .m-lg-0 {
     margin: 0 !important; }
   .m-lg-1 {
     margin: 0.25rem !important; }
   .m-lg-2 {
     margin: 0.5rem !important; }
   .m-lg-3 {
     margin: 1rem !important; }
   .m-lg-4 {
     margin: 1.5rem !important; }
   .m-lg-5 {
     margin: 2rem !important; }
   .m-lg-6 {
     margin: 2.5rem !important; }
   .m-lg-7 {
     margin: 3rem !important; }
   .m-lg-8 {
     margin: 3.5rem !important; }
   .m-lg-9 {
     margin: 4rem !important; }
   .m-lg-10 {
     margin: 5rem !important; }
   .m-lg-11 {
     margin: 6rem !important; }
   .m-lg-12 {
     margin: 7rem !important; }
   .m-lg-auto {
     margin: auto !important; }
   .mx-lg-0 {
     margin-right: 0 !important;
     margin-left: 0 !important; }
   .mx-lg-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important; }
   .mx-lg-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important; }
   .mx-lg-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important; }
   .mx-lg-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important; }
   .mx-lg-5 {
     margin-right: 2rem !important;
     margin-left: 2rem !important; }
   .mx-lg-6 {
     margin-right: 2.5rem !important;
     margin-left: 2.5rem !important; }
   .mx-lg-7 {
     margin-right: 3rem !important;
     margin-left: 3rem !important; }
   .mx-lg-8 {
     margin-right: 3.5rem !important;
     margin-left: 3.5rem !important; }
   .mx-lg-9 {
     margin-right: 4rem !important;
     margin-left: 4rem !important; }
   .mx-lg-10 {
     margin-right: 5rem !important;
     margin-left: 5rem !important; }
   .mx-lg-11 {
     margin-right: 6rem !important;
     margin-left: 6rem !important; }
   .mx-lg-12 {
     margin-right: 7rem !important;
     margin-left: 7rem !important; }
   .mx-lg-auto {
     margin-right: auto !important;
     margin-left: auto !important; }
   .my-lg-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important; }
   .my-lg-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important; }
   .my-lg-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important; }
   .my-lg-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important; }
   .my-lg-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important; }
   .my-lg-5 {
     margin-top: 2rem !important;
     margin-bottom: 2rem !important; }
   .my-lg-6 {
     margin-top: 2.5rem !important;
     margin-bottom: 2.5rem !important; }
   .my-lg-7 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important; }
   .my-lg-8 {
     margin-top: 3.5rem !important;
     margin-bottom: 3.5rem !important; }
   .my-lg-9 {
     margin-top: 4rem !important;
     margin-bottom: 4rem !important; }
   .my-lg-10 {
     margin-top: 5rem !important;
     margin-bottom: 5rem !important; }
   .my-lg-11 {
     margin-top: 6rem !important;
     margin-bottom: 6rem !important; }
   .my-lg-12 {
     margin-top: 7rem !important;
     margin-bottom: 7rem !important; }
   .my-lg-auto {
     margin-top: auto !important;
     margin-bottom: auto !important; }
   .mt-lg-0 {
     margin-top: 0 !important; }
   .mt-lg-1 {
     margin-top: 0.25rem !important; }
   .mt-lg-2 {
     margin-top: 0.5rem !important; }
   .mt-lg-3 {
     margin-top: 1rem !important; }
   .mt-lg-4 {
     margin-top: 1.5rem !important; }
   .mt-lg-5 {
     margin-top: 2rem !important; }
   .mt-lg-6 {
     margin-top: 2.5rem !important; }
   .mt-lg-7 {
     margin-top: 3rem !important; }
   .mt-lg-8 {
     margin-top: 3.5rem !important; }
   .mt-lg-9 {
     margin-top: 4rem !important; }
   .mt-lg-10 {
     margin-top: 5rem !important; }
   .mt-lg-11 {
     margin-top: 6rem !important; }
   .mt-lg-12 {
     margin-top: 7rem !important; }
   .mt-lg-auto {
     margin-top: auto !important; }
   .me-lg-0 {
     margin-right: 0 !important; }
   .me-lg-1 {
     margin-right: 0.25rem !important; }
   .me-lg-2 {
     margin-right: 0.5rem !important; }
   .me-lg-3 {
     margin-right: 1rem !important; }
   .me-lg-4 {
     margin-right: 1.5rem !important; }
   .me-lg-5 {
     margin-right: 2rem !important; }
   .me-lg-6 {
     margin-right: 2.5rem !important; }
   .me-lg-7 {
     margin-right: 3rem !important; }
   .me-lg-8 {
     margin-right: 3.5rem !important; }
   .me-lg-9 {
     margin-right: 4rem !important; }
   .me-lg-10 {
     margin-right: 5rem !important; }
   .me-lg-11 {
     margin-right: 6rem !important; }
   .me-lg-12 {
     margin-right: 7rem !important; }
   .me-lg-auto {
     margin-right: auto !important; }
   .mb-lg-0 {
     margin-bottom: 0 !important; }
   .mb-lg-1 {
     margin-bottom: 0.25rem !important; }
   .mb-lg-2 {
     margin-bottom: 0.5rem !important; }
   .mb-lg-3 {
     margin-bottom: 1rem !important; }
   .mb-lg-4 {
     margin-bottom: 1.5rem !important; }
   .mb-lg-5 {
     margin-bottom: 2rem !important; }
   .mb-lg-6 {
     margin-bottom: 2.5rem !important; }
   .mb-lg-7 {
     margin-bottom: 3rem !important; }
   .mb-lg-8 {
     margin-bottom: 3.5rem !important; }
   .mb-lg-9 {
     margin-bottom: 4rem !important; }
   .mb-lg-10 {
     margin-bottom: 5rem !important; }
   .mb-lg-11 {
     margin-bottom: 6rem !important; }
   .mb-lg-12 {
     margin-bottom: 7rem !important; }
   .mb-lg-auto {
     margin-bottom: auto !important; }
   .ms-lg-0 {
     margin-left: 0 !important; }
   .ms-lg-1 {
     margin-left: 0.25rem !important; }
   .ms-lg-2 {
     margin-left: 0.5rem !important; }
   .ms-lg-3 {
     margin-left: 1rem !important; }
   .ms-lg-4 {
     margin-left: 1.5rem !important; }
   .ms-lg-5 {
     margin-left: 2rem !important; }
   .ms-lg-6 {
     margin-left: 2.5rem !important; }
   .ms-lg-7 {
     margin-left: 3rem !important; }
   .ms-lg-8 {
     margin-left: 3.5rem !important; }
   .ms-lg-9 {
     margin-left: 4rem !important; }
   .ms-lg-10 {
     margin-left: 5rem !important; }
   .ms-lg-11 {
     margin-left: 6rem !important; }
   .ms-lg-12 {
     margin-left: 7rem !important; }
   .ms-lg-auto {
     margin-left: auto !important; }
   .m-lg-n1 {
     margin: -0.25rem !important; }
   .m-lg-n2 {
     margin: -0.5rem !important; }
   .m-lg-n3 {
     margin: -1rem !important; }
   .m-lg-n4 {
     margin: -1.5rem !important; }
   .m-lg-n5 {
     margin: -2rem !important; }
   .m-lg-n6 {
     margin: -2.5rem !important; }
   .m-lg-n7 {
     margin: -3rem !important; }
   .m-lg-n8 {
     margin: -3.5rem !important; }
   .m-lg-n9 {
     margin: -4rem !important; }
   .m-lg-n10 {
     margin: -5rem !important; }
   .m-lg-n11 {
     margin: -6rem !important; }
   .m-lg-n12 {
     margin: -7rem !important; }
   .mx-lg-n1 {
     margin-right: -0.25rem !important;
     margin-left: -0.25rem !important; }
   .mx-lg-n2 {
     margin-right: -0.5rem !important;
     margin-left: -0.5rem !important; }
   .mx-lg-n3 {
     margin-right: -1rem !important;
     margin-left: -1rem !important; }
   .mx-lg-n4 {
     margin-right: -1.5rem !important;
     margin-left: -1.5rem !important; }
   .mx-lg-n5 {
     margin-right: -2rem !important;
     margin-left: -2rem !important; }
   .mx-lg-n6 {
     margin-right: -2.5rem !important;
     margin-left: -2.5rem !important; }
   .mx-lg-n7 {
     margin-right: -3rem !important;
     margin-left: -3rem !important; }
   .mx-lg-n8 {
     margin-right: -3.5rem !important;
     margin-left: -3.5rem !important; }
   .mx-lg-n9 {
     margin-right: -4rem !important;
     margin-left: -4rem !important; }
   .mx-lg-n10 {
     margin-right: -5rem !important;
     margin-left: -5rem !important; }
   .mx-lg-n11 {
     margin-right: -6rem !important;
     margin-left: -6rem !important; }
   .mx-lg-n12 {
     margin-right: -7rem !important;
     margin-left: -7rem !important; }
   .my-lg-n1 {
     margin-top: -0.25rem !important;
     margin-bottom: -0.25rem !important; }
   .my-lg-n2 {
     margin-top: -0.5rem !important;
     margin-bottom: -0.5rem !important; }
   .my-lg-n3 {
     margin-top: -1rem !important;
     margin-bottom: -1rem !important; }
   .my-lg-n4 {
     margin-top: -1.5rem !important;
     margin-bottom: -1.5rem !important; }
   .my-lg-n5 {
     margin-top: -2rem !important;
     margin-bottom: -2rem !important; }
   .my-lg-n6 {
     margin-top: -2.5rem !important;
     margin-bottom: -2.5rem !important; }
   .my-lg-n7 {
     margin-top: -3rem !important;
     margin-bottom: -3rem !important; }
   .my-lg-n8 {
     margin-top: -3.5rem !important;
     margin-bottom: -3.5rem !important; }
   .my-lg-n9 {
     margin-top: -4rem !important;
     margin-bottom: -4rem !important; }
   .my-lg-n10 {
     margin-top: -5rem !important;
     margin-bottom: -5rem !important; }
   .my-lg-n11 {
     margin-top: -6rem !important;
     margin-bottom: -6rem !important; }
   .my-lg-n12 {
     margin-top: -7rem !important;
     margin-bottom: -7rem !important; }
   .mt-lg-n1 {
     margin-top: -0.25rem !important; }
   .mt-lg-n2 {
     margin-top: -0.5rem !important; }
   .mt-lg-n3 {
     margin-top: -1rem !important; }
   .mt-lg-n4 {
     margin-top: -1.5rem !important; }
   .mt-lg-n5 {
     margin-top: -2rem !important; }
   .mt-lg-n6 {
     margin-top: -2.5rem !important; }
   .mt-lg-n7 {
     margin-top: -3rem !important; }
   .mt-lg-n8 {
     margin-top: -3.5rem !important; }
   .mt-lg-n9 {
     margin-top: -4rem !important; }
   .mt-lg-n10 {
     margin-top: -5rem !important; }
   .mt-lg-n11 {
     margin-top: -6rem !important; }
   .mt-lg-n12 {
     margin-top: -7rem !important; }
   .me-lg-n1 {
     margin-right: -0.25rem !important; }
   .me-lg-n2 {
     margin-right: -0.5rem !important; }
   .me-lg-n3 {
     margin-right: -1rem !important; }
   .me-lg-n4 {
     margin-right: -1.5rem !important; }
   .me-lg-n5 {
     margin-right: -2rem !important; }
   .me-lg-n6 {
     margin-right: -2.5rem !important; }
   .me-lg-n7 {
     margin-right: -3rem !important; }
   .me-lg-n8 {
     margin-right: -3.5rem !important; }
   .me-lg-n9 {
     margin-right: -4rem !important; }
   .me-lg-n10 {
     margin-right: -5rem !important; }
   .me-lg-n11 {
     margin-right: -6rem !important; }
   .me-lg-n12 {
     margin-right: -7rem !important; }
   .mb-lg-n1 {
     margin-bottom: -0.25rem !important; }
   .mb-lg-n2 {
     margin-bottom: -0.5rem !important; }
   .mb-lg-n3 {
     margin-bottom: -1rem !important; }
   .mb-lg-n4 {
     margin-bottom: -1.5rem !important; }
   .mb-lg-n5 {
     margin-bottom: -2rem !important; }
   .mb-lg-n6 {
     margin-bottom: -2.5rem !important; }
   .mb-lg-n7 {
     margin-bottom: -3rem !important; }
   .mb-lg-n8 {
     margin-bottom: -3.5rem !important; }
   .mb-lg-n9 {
     margin-bottom: -4rem !important; }
   .mb-lg-n10 {
     margin-bottom: -5rem !important; }
   .mb-lg-n11 {
     margin-bottom: -6rem !important; }
   .mb-lg-n12 {
     margin-bottom: -7rem !important; }
   .ms-lg-n1 {
     margin-left: -0.25rem !important; }
   .ms-lg-n2 {
     margin-left: -0.5rem !important; }
   .ms-lg-n3 {
     margin-left: -1rem !important; }
   .ms-lg-n4 {
     margin-left: -1.5rem !important; }
   .ms-lg-n5 {
     margin-left: -2rem !important; }
   .ms-lg-n6 {
     margin-left: -2.5rem !important; }
   .ms-lg-n7 {
     margin-left: -3rem !important; }
   .ms-lg-n8 {
     margin-left: -3.5rem !important; }
   .ms-lg-n9 {
     margin-left: -4rem !important; }
   .ms-lg-n10 {
     margin-left: -5rem !important; }
   .ms-lg-n11 {
     margin-left: -6rem !important; }
   .ms-lg-n12 {
     margin-left: -7rem !important; }
   .p-lg-0 {
     padding: 0 !important; }
   .p-lg-1 {
     padding: 0.25rem !important; }
   .p-lg-2 {
     padding: 0.5rem !important; }
   .p-lg-3 {
     padding: 1rem !important; }
   .p-lg-4 {
     padding: 1.5rem !important; }
   .p-lg-5 {
     padding: 2rem !important; }
   .p-lg-6 {
     padding: 2.5rem !important; }
   .p-lg-7 {
     padding: 3rem !important; }
   .p-lg-8 {
     padding: 3.5rem !important; }
   .p-lg-9 {
     padding: 4rem !important; }
   .p-lg-10 {
     padding: 5rem !important; }
   .p-lg-11 {
     padding: 6rem !important; }
   .p-lg-12 {
     padding: 7rem !important; }
   .px-lg-0 {
     padding-right: 0 !important;
     padding-left: 0 !important; }
   .px-lg-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important; }
   .px-lg-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important; }
   .px-lg-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important; }
   .px-lg-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important; }
   .px-lg-5 {
     padding-right: 2rem !important;
     padding-left: 2rem !important; }
   .px-lg-6 {
     padding-right: 2.5rem !important;
     padding-left: 2.5rem !important; }
   .px-lg-7 {
     padding-right: 3rem !important;
     padding-left: 3rem !important; }
   .px-lg-8 {
     padding-right: 3.5rem !important;
     padding-left: 3.5rem !important; }
   .px-lg-9 {
     padding-right: 4rem !important;
     padding-left: 4rem !important; }
   .px-lg-10 {
     padding-right: 5rem !important;
     padding-left: 5rem !important; }
   .px-lg-11 {
     padding-right: 6rem !important;
     padding-left: 6rem !important; }
   .px-lg-12 {
     padding-right: 7rem !important;
     padding-left: 7rem !important; }
   .py-lg-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important; }
   .py-lg-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important; }
   .py-lg-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important; }
   .py-lg-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important; }
   .py-lg-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important; }
   .py-lg-5 {
     padding-top: 2rem !important;
     padding-bottom: 2rem !important; }
   .py-lg-6 {
     padding-top: 2.5rem !important;
     padding-bottom: 2.5rem !important; }
   .py-lg-7 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important; }
   .py-lg-8 {
     padding-top: 3.5rem !important;
     padding-bottom: 3.5rem !important; }
   .py-lg-9 {
     padding-top: 4rem !important;
     padding-bottom: 4rem !important; }
   .py-lg-10 {
     padding-top: 5rem !important;
     padding-bottom: 5rem !important; }
   .py-lg-11 {
     padding-top: 6rem !important;
     padding-bottom: 6rem !important; }
   .py-lg-12 {
     padding-top: 7rem !important;
     padding-bottom: 7rem !important; }
   .pt-lg-0 {
     padding-top: 0 !important; }
   .pt-lg-1 {
     padding-top: 0.25rem !important; }
   .pt-lg-2 {
     padding-top: 0.5rem !important; }
   .pt-lg-3 {
     padding-top: 1rem !important; }
   .pt-lg-4 {
     padding-top: 1.5rem !important; }
   .pt-lg-5 {
     padding-top: 2rem !important; }
   .pt-lg-6 {
     padding-top: 2.5rem !important; }
   .pt-lg-7 {
     padding-top: 3rem !important; }
   .pt-lg-8 {
     padding-top: 3.5rem !important; }
   .pt-lg-9 {
     padding-top: 4rem !important; }
   .pt-lg-10 {
     padding-top: 5rem !important; }
   .pt-lg-11 {
     padding-top: 6rem !important; }
   .pt-lg-12 {
     padding-top: 7rem !important; }
   .pe-lg-0 {
     padding-right: 0 !important; }
   .pe-lg-1 {
     padding-right: 0.25rem !important; }
   .pe-lg-2 {
     padding-right: 0.5rem !important; }
   .pe-lg-3 {
     padding-right: 1rem !important; }
   .pe-lg-4 {
     padding-right: 1.5rem !important; }
   .pe-lg-5 {
     padding-right: 2rem !important; }
   .pe-lg-6 {
     padding-right: 2.5rem !important; }
   .pe-lg-7 {
     padding-right: 3rem !important; }
   .pe-lg-8 {
     padding-right: 3.5rem !important; }
   .pe-lg-9 {
     padding-right: 4rem !important; }
   .pe-lg-10 {
     padding-right: 5rem !important; }
   .pe-lg-11 {
     padding-right: 6rem !important; }
   .pe-lg-12 {
     padding-right: 7rem !important; }
   .pb-lg-0 {
     padding-bottom: 0 !important; }
   .pb-lg-1 {
     padding-bottom: 0.25rem !important; }
   .pb-lg-2 {
     padding-bottom: 0.5rem !important; }
   .pb-lg-3 {
     padding-bottom: 1rem !important; }
   .pb-lg-4 {
     padding-bottom: 1.5rem !important; }
   .pb-lg-5 {
     padding-bottom: 2rem !important; }
   .pb-lg-6 {
     padding-bottom: 2.5rem !important; }
   .pb-lg-7 {
     padding-bottom: 3rem !important; }
   .pb-lg-8 {
     padding-bottom: 3.5rem !important; }
   .pb-lg-9 {
     padding-bottom: 4rem !important; }
   .pb-lg-10 {
     padding-bottom: 5rem !important; }
   .pb-lg-11 {
     padding-bottom: 6rem !important; }
   .pb-lg-12 {
     padding-bottom: 7rem !important; }
   .ps-lg-0 {
     padding-left: 0 !important; }
   .ps-lg-1 {
     padding-left: 0.25rem !important; }
   .ps-lg-2 {
     padding-left: 0.5rem !important; }
   .ps-lg-3 {
     padding-left: 1rem !important; }
   .ps-lg-4 {
     padding-left: 1.5rem !important; }
   .ps-lg-5 {
     padding-left: 2rem !important; }
   .ps-lg-6 {
     padding-left: 2.5rem !important; }
   .ps-lg-7 {
     padding-left: 3rem !important; }
   .ps-lg-8 {
     padding-left: 3.5rem !important; }
   .ps-lg-9 {
     padding-left: 4rem !important; }
   .ps-lg-10 {
     padding-left: 5rem !important; }
   .ps-lg-11 {
     padding-left: 6rem !important; }
   .ps-lg-12 {
     padding-left: 7rem !important; }
   .text-lg-start {
     text-align: left !important; }
   .text-lg-end {
     text-align: right !important; }
   .text-lg-center {
     text-align: center !important; } }
 
 @media (min-width: 1200px) {
   .float-xl-start {
     float: left !important; }
   .float-xl-end {
     float: right !important; }
   .float-xl-none {
     float: none !important; }
   .d-xl-inline {
     display: inline !important; }
   .d-xl-inline-block {
     display: inline-block !important; }
   .d-xl-block {
     display: block !important; }
   .d-xl-grid {
     display: grid !important; }
   .d-xl-table {
     display: table !important; }
   .d-xl-table-row {
     display: table-row !important; }
   .d-xl-table-cell {
     display: table-cell !important; }
   .d-xl-flex {
     display: flex !important; }
   .d-xl-inline-flex {
     display: inline-flex !important; }
   .d-xl-none {
     display: none !important; }
   .flex-xl-fill {
     flex: 1 1 auto !important; }
   .flex-xl-row {
     flex-direction: row !important; }
   .flex-xl-column {
     flex-direction: column !important; }
   .flex-xl-row-reverse {
     flex-direction: row-reverse !important; }
   .flex-xl-column-reverse {
     flex-direction: column-reverse !important; }
   .flex-xl-grow-0 {
     flex-grow: 0 !important; }
   .flex-xl-grow-1 {
     flex-grow: 1 !important; }
   .flex-xl-shrink-0 {
     flex-shrink: 0 !important; }
   .flex-xl-shrink-1 {
     flex-shrink: 1 !important; }
   .flex-xl-wrap {
     flex-wrap: wrap !important; }
   .flex-xl-nowrap {
     flex-wrap: nowrap !important; }
   .flex-xl-wrap-reverse {
     flex-wrap: wrap-reverse !important; }
   .gap-xl-0 {
     gap: 0 !important; }
   .gap-xl-1 {
     gap: 0.25rem !important; }
   .gap-xl-2 {
     gap: 0.5rem !important; }
   .gap-xl-3 {
     gap: 1rem !important; }
   .gap-xl-4 {
     gap: 1.5rem !important; }
   .gap-xl-5 {
     gap: 2rem !important; }
   .gap-xl-6 {
     gap: 2.5rem !important; }
   .gap-xl-7 {
     gap: 3rem !important; }
   .gap-xl-8 {
     gap: 3.5rem !important; }
   .gap-xl-9 {
     gap: 4rem !important; }
   .gap-xl-10 {
     gap: 5rem !important; }
   .gap-xl-11 {
     gap: 6rem !important; }
   .gap-xl-12 {
     gap: 7rem !important; }
   .justify-content-xl-start {
     justify-content: flex-start !important; }
   .justify-content-xl-end {
     justify-content: flex-end !important; }
   .justify-content-xl-center {
     justify-content: center !important; }
   .justify-content-xl-between {
     justify-content: space-between !important; }
   .justify-content-xl-around {
     justify-content: space-around !important; }
   .justify-content-xl-evenly {
     justify-content: space-evenly !important; }
   .align-items-xl-start {
     align-items: flex-start !important; }
   .align-items-xl-end {
     align-items: flex-end !important; }
   .align-items-xl-center {
     align-items: center !important; }
   .align-items-xl-baseline {
     align-items: baseline !important; }
   .align-items-xl-stretch {
     align-items: stretch !important; }
   .align-content-xl-start {
     align-content: flex-start !important; }
   .align-content-xl-end {
     align-content: flex-end !important; }
   .align-content-xl-center {
     align-content: center !important; }
   .align-content-xl-between {
     align-content: space-between !important; }
   .align-content-xl-around {
     align-content: space-around !important; }
   .align-content-xl-stretch {
     align-content: stretch !important; }
   .align-self-xl-auto {
     align-self: auto !important; }
   .align-self-xl-start {
     align-self: flex-start !important; }
   .align-self-xl-end {
     align-self: flex-end !important; }
   .align-self-xl-center {
     align-self: center !important; }
   .align-self-xl-baseline {
     align-self: baseline !important; }
   .align-self-xl-stretch {
     align-self: stretch !important; }
   .order-xl-first {
     order: -1 !important; }
   .order-xl-0 {
     order: 0 !important; }
   .order-xl-1 {
     order: 1 !important; }
   .order-xl-2 {
     order: 2 !important; }
   .order-xl-3 {
     order: 3 !important; }
   .order-xl-4 {
     order: 4 !important; }
   .order-xl-5 {
     order: 5 !important; }
   .order-xl-last {
     order: 6 !important; }
   .m-xl-0 {
     margin: 0 !important; }
   .m-xl-1 {
     margin: 0.25rem !important; }
   .m-xl-2 {
     margin: 0.5rem !important; }
   .m-xl-3 {
     margin: 1rem !important; }
   .m-xl-4 {
     margin: 1.5rem !important; }
   .m-xl-5 {
     margin: 2rem !important; }
   .m-xl-6 {
     margin: 2.5rem !important; }
   .m-xl-7 {
     margin: 3rem !important; }
   .m-xl-8 {
     margin: 3.5rem !important; }
   .m-xl-9 {
     margin: 4rem !important; }
   .m-xl-10 {
     margin: 5rem !important; }
   .m-xl-11 {
     margin: 6rem !important; }
   .m-xl-12 {
     margin: 7rem !important; }
   .m-xl-auto {
     margin: auto !important; }
   .mx-xl-0 {
     margin-right: 0 !important;
     margin-left: 0 !important; }
   .mx-xl-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important; }
   .mx-xl-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important; }
   .mx-xl-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important; }
   .mx-xl-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important; }
   .mx-xl-5 {
     margin-right: 2rem !important;
     margin-left: 2rem !important; }
   .mx-xl-6 {
     margin-right: 2.5rem !important;
     margin-left: 2.5rem !important; }
   .mx-xl-7 {
     margin-right: 3rem !important;
     margin-left: 3rem !important; }
   .mx-xl-8 {
     margin-right: 3.5rem !important;
     margin-left: 3.5rem !important; }
   .mx-xl-9 {
     margin-right: 4rem !important;
     margin-left: 4rem !important; }
   .mx-xl-10 {
     margin-right: 5rem !important;
     margin-left: 5rem !important; }
   .mx-xl-11 {
     margin-right: 6rem !important;
     margin-left: 6rem !important; }
   .mx-xl-12 {
     margin-right: 7rem !important;
     margin-left: 7rem !important; }
   .mx-xl-auto {
     margin-right: auto !important;
     margin-left: auto !important; }
   .my-xl-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important; }
   .my-xl-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important; }
   .my-xl-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important; }
   .my-xl-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important; }
   .my-xl-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important; }
   .my-xl-5 {
     margin-top: 2rem !important;
     margin-bottom: 2rem !important; }
   .my-xl-6 {
     margin-top: 2.5rem !important;
     margin-bottom: 2.5rem !important; }
   .my-xl-7 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important; }
   .my-xl-8 {
     margin-top: 3.5rem !important;
     margin-bottom: 3.5rem !important; }
   .my-xl-9 {
     margin-top: 4rem !important;
     margin-bottom: 4rem !important; }
   .my-xl-10 {
     margin-top: 5rem !important;
     margin-bottom: 5rem !important; }
   .my-xl-11 {
     margin-top: 6rem !important;
     margin-bottom: 6rem !important; }
   .my-xl-12 {
     margin-top: 7rem !important;
     margin-bottom: 7rem !important; }
   .my-xl-auto {
     margin-top: auto !important;
     margin-bottom: auto !important; }
   .mt-xl-0 {
     margin-top: 0 !important; }
   .mt-xl-1 {
     margin-top: 0.25rem !important; }
   .mt-xl-2 {
     margin-top: 0.5rem !important; }
   .mt-xl-3 {
     margin-top: 1rem !important; }
   .mt-xl-4 {
     margin-top: 1.5rem !important; }
   .mt-xl-5 {
     margin-top: 2rem !important; }
   .mt-xl-6 {
     margin-top: 2.5rem !important; }
   .mt-xl-7 {
     margin-top: 3rem !important; }
   .mt-xl-8 {
     margin-top: 3.5rem !important; }
   .mt-xl-9 {
     margin-top: 4rem !important; }
   .mt-xl-10 {
     margin-top: 5rem !important; }
   .mt-xl-11 {
     margin-top: 6rem !important; }
   .mt-xl-12 {
     margin-top: 7rem !important; }
   .mt-xl-auto {
     margin-top: auto !important; }
   .me-xl-0 {
     margin-right: 0 !important; }
   .me-xl-1 {
     margin-right: 0.25rem !important; }
   .me-xl-2 {
     margin-right: 0.5rem !important; }
   .me-xl-3 {
     margin-right: 1rem !important; }
   .me-xl-4 {
     margin-right: 1.5rem !important; }
   .me-xl-5 {
     margin-right: 2rem !important; }
   .me-xl-6 {
     margin-right: 2.5rem !important; }
   .me-xl-7 {
     margin-right: 3rem !important; }
   .me-xl-8 {
     margin-right: 3.5rem !important; }
   .me-xl-9 {
     margin-right: 4rem !important; }
   .me-xl-10 {
     margin-right: 5rem !important; }
   .me-xl-11 {
     margin-right: 6rem !important; }
   .me-xl-12 {
     margin-right: 7rem !important; }
   .me-xl-auto {
     margin-right: auto !important; }
   .mb-xl-0 {
     margin-bottom: 0 !important; }
   .mb-xl-1 {
     margin-bottom: 0.25rem !important; }
   .mb-xl-2 {
     margin-bottom: 0.5rem !important; }
   .mb-xl-3 {
     margin-bottom: 1rem !important; }
   .mb-xl-4 {
     margin-bottom: 1.5rem !important; }
   .mb-xl-5 {
     margin-bottom: 2rem !important; }
   .mb-xl-6 {
     margin-bottom: 2.5rem !important; }
   .mb-xl-7 {
     margin-bottom: 3rem !important; }
   .mb-xl-8 {
     margin-bottom: 3.5rem !important; }
   .mb-xl-9 {
     margin-bottom: 4rem !important; }
   .mb-xl-10 {
     margin-bottom: 5rem !important; }
   .mb-xl-11 {
     margin-bottom: 6rem !important; }
   .mb-xl-12 {
     margin-bottom: 7rem !important; }
   .mb-xl-auto {
     margin-bottom: auto !important; }
   .ms-xl-0 {
     margin-left: 0 !important; }
   .ms-xl-1 {
     margin-left: 0.25rem !important; }
   .ms-xl-2 {
     margin-left: 0.5rem !important; }
   .ms-xl-3 {
     margin-left: 1rem !important; }
   .ms-xl-4 {
     margin-left: 1.5rem !important; }
   .ms-xl-5 {
     margin-left: 2rem !important; }
   .ms-xl-6 {
     margin-left: 2.5rem !important; }
   .ms-xl-7 {
     margin-left: 3rem !important; }
   .ms-xl-8 {
     margin-left: 3.5rem !important; }
   .ms-xl-9 {
     margin-left: 4rem !important; }
   .ms-xl-10 {
     margin-left: 5rem !important; }
   .ms-xl-11 {
     margin-left: 6rem !important; }
   .ms-xl-12 {
     margin-left: 7rem !important; }
   .ms-xl-auto {
     margin-left: auto !important; }
   .m-xl-n1 {
     margin: -0.25rem !important; }
   .m-xl-n2 {
     margin: -0.5rem !important; }
   .m-xl-n3 {
     margin: -1rem !important; }
   .m-xl-n4 {
     margin: -1.5rem !important; }
   .m-xl-n5 {
     margin: -2rem !important; }
   .m-xl-n6 {
     margin: -2.5rem !important; }
   .m-xl-n7 {
     margin: -3rem !important; }
   .m-xl-n8 {
     margin: -3.5rem !important; }
   .m-xl-n9 {
     margin: -4rem !important; }
   .m-xl-n10 {
     margin: -5rem !important; }
   .m-xl-n11 {
     margin: -6rem !important; }
   .m-xl-n12 {
     margin: -7rem !important; }
   .mx-xl-n1 {
     margin-right: -0.25rem !important;
     margin-left: -0.25rem !important; }
   .mx-xl-n2 {
     margin-right: -0.5rem !important;
     margin-left: -0.5rem !important; }
   .mx-xl-n3 {
     margin-right: -1rem !important;
     margin-left: -1rem !important; }
   .mx-xl-n4 {
     margin-right: -1.5rem !important;
     margin-left: -1.5rem !important; }
   .mx-xl-n5 {
     margin-right: -2rem !important;
     margin-left: -2rem !important; }
   .mx-xl-n6 {
     margin-right: -2.5rem !important;
     margin-left: -2.5rem !important; }
   .mx-xl-n7 {
     margin-right: -3rem !important;
     margin-left: -3rem !important; }
   .mx-xl-n8 {
     margin-right: -3.5rem !important;
     margin-left: -3.5rem !important; }
   .mx-xl-n9 {
     margin-right: -4rem !important;
     margin-left: -4rem !important; }
   .mx-xl-n10 {
     margin-right: -5rem !important;
     margin-left: -5rem !important; }
   .mx-xl-n11 {
     margin-right: -6rem !important;
     margin-left: -6rem !important; }
   .mx-xl-n12 {
     margin-right: -7rem !important;
     margin-left: -7rem !important; }
   .my-xl-n1 {
     margin-top: -0.25rem !important;
     margin-bottom: -0.25rem !important; }
   .my-xl-n2 {
     margin-top: -0.5rem !important;
     margin-bottom: -0.5rem !important; }
   .my-xl-n3 {
     margin-top: -1rem !important;
     margin-bottom: -1rem !important; }
   .my-xl-n4 {
     margin-top: -1.5rem !important;
     margin-bottom: -1.5rem !important; }
   .my-xl-n5 {
     margin-top: -2rem !important;
     margin-bottom: -2rem !important; }
   .my-xl-n6 {
     margin-top: -2.5rem !important;
     margin-bottom: -2.5rem !important; }
   .my-xl-n7 {
     margin-top: -3rem !important;
     margin-bottom: -3rem !important; }
   .my-xl-n8 {
     margin-top: -3.5rem !important;
     margin-bottom: -3.5rem !important; }
   .my-xl-n9 {
     margin-top: -4rem !important;
     margin-bottom: -4rem !important; }
   .my-xl-n10 {
     margin-top: -5rem !important;
     margin-bottom: -5rem !important; }
   .my-xl-n11 {
     margin-top: -6rem !important;
     margin-bottom: -6rem !important; }
   .my-xl-n12 {
     margin-top: -7rem !important;
     margin-bottom: -7rem !important; }
   .mt-xl-n1 {
     margin-top: -0.25rem !important; }
   .mt-xl-n2 {
     margin-top: -0.5rem !important; }
   .mt-xl-n3 {
     margin-top: -1rem !important; }
   .mt-xl-n4 {
     margin-top: -1.5rem !important; }
   .mt-xl-n5 {
     margin-top: -2rem !important; }
   .mt-xl-n6 {
     margin-top: -2.5rem !important; }
   .mt-xl-n7 {
     margin-top: -3rem !important; }
   .mt-xl-n8 {
     margin-top: -3.5rem !important; }
   .mt-xl-n9 {
     margin-top: -4rem !important; }
   .mt-xl-n10 {
     margin-top: -5rem !important; }
   .mt-xl-n11 {
     margin-top: -6rem !important; }
   .mt-xl-n12 {
     margin-top: -7rem !important; }
   .me-xl-n1 {
     margin-right: -0.25rem !important; }
   .me-xl-n2 {
     margin-right: -0.5rem !important; }
   .me-xl-n3 {
     margin-right: -1rem !important; }
   .me-xl-n4 {
     margin-right: -1.5rem !important; }
   .me-xl-n5 {
     margin-right: -2rem !important; }
   .me-xl-n6 {
     margin-right: -2.5rem !important; }
   .me-xl-n7 {
     margin-right: -3rem !important; }
   .me-xl-n8 {
     margin-right: -3.5rem !important; }
   .me-xl-n9 {
     margin-right: -4rem !important; }
   .me-xl-n10 {
     margin-right: -5rem !important; }
   .me-xl-n11 {
     margin-right: -6rem !important; }
   .me-xl-n12 {
     margin-right: -7rem !important; }
   .mb-xl-n1 {
     margin-bottom: -0.25rem !important; }
   .mb-xl-n2 {
     margin-bottom: -0.5rem !important; }
   .mb-xl-n3 {
     margin-bottom: -1rem !important; }
   .mb-xl-n4 {
     margin-bottom: -1.5rem !important; }
   .mb-xl-n5 {
     margin-bottom: -2rem !important; }
   .mb-xl-n6 {
     margin-bottom: -2.5rem !important; }
   .mb-xl-n7 {
     margin-bottom: -3rem !important; }
   .mb-xl-n8 {
     margin-bottom: -3.5rem !important; }
   .mb-xl-n9 {
     margin-bottom: -4rem !important; }
   .mb-xl-n10 {
     margin-bottom: -5rem !important; }
   .mb-xl-n11 {
     margin-bottom: -6rem !important; }
   .mb-xl-n12 {
     margin-bottom: -7rem !important; }
   .ms-xl-n1 {
     margin-left: -0.25rem !important; }
   .ms-xl-n2 {
     margin-left: -0.5rem !important; }
   .ms-xl-n3 {
     margin-left: -1rem !important; }
   .ms-xl-n4 {
     margin-left: -1.5rem !important; }
   .ms-xl-n5 {
     margin-left: -2rem !important; }
   .ms-xl-n6 {
     margin-left: -2.5rem !important; }
   .ms-xl-n7 {
     margin-left: -3rem !important; }
   .ms-xl-n8 {
     margin-left: -3.5rem !important; }
   .ms-xl-n9 {
     margin-left: -4rem !important; }
   .ms-xl-n10 {
     margin-left: -5rem !important; }
   .ms-xl-n11 {
     margin-left: -6rem !important; }
   .ms-xl-n12 {
     margin-left: -7rem !important; }
   .p-xl-0 {
     padding: 0 !important; }
   .p-xl-1 {
     padding: 0.25rem !important; }
   .p-xl-2 {
     padding: 0.5rem !important; }
   .p-xl-3 {
     padding: 1rem !important; }
   .p-xl-4 {
     padding: 1.5rem !important; }
   .p-xl-5 {
     padding: 2rem !important; }
   .p-xl-6 {
     padding: 2.5rem !important; }
   .p-xl-7 {
     padding: 3rem !important; }
   .p-xl-8 {
     padding: 3.5rem !important; }
   .p-xl-9 {
     padding: 4rem !important; }
   .p-xl-10 {
     padding: 5rem !important; }
   .p-xl-11 {
     padding: 6rem !important; }
   .p-xl-12 {
     padding: 7rem !important; }
   .px-xl-0 {
     padding-right: 0 !important;
     padding-left: 0 !important; }
   .px-xl-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important; }
   .px-xl-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important; }
   .px-xl-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important; }
   .px-xl-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important; }
   .px-xl-5 {
     padding-right: 2rem !important;
     padding-left: 2rem !important; }
   .px-xl-6 {
     padding-right: 2.5rem !important;
     padding-left: 2.5rem !important; }
   .px-xl-7 {
     padding-right: 3rem !important;
     padding-left: 3rem !important; }
   .px-xl-8 {
     padding-right: 3.5rem !important;
     padding-left: 3.5rem !important; }
   .px-xl-9 {
     padding-right: 4rem !important;
     padding-left: 4rem !important; }
   .px-xl-10 {
     padding-right: 5rem !important;
     padding-left: 5rem !important; }
   .px-xl-11 {
     padding-right: 6rem !important;
     padding-left: 6rem !important; }
   .px-xl-12 {
     padding-right: 7rem !important;
     padding-left: 7rem !important; }
   .py-xl-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important; }
   .py-xl-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important; }
   .py-xl-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important; }
   .py-xl-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important; }
   .py-xl-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important; }
   .py-xl-5 {
     padding-top: 2rem !important;
     padding-bottom: 2rem !important; }
   .py-xl-6 {
     padding-top: 2.5rem !important;
     padding-bottom: 2.5rem !important; }
   .py-xl-7 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important; }
   .py-xl-8 {
     padding-top: 3.5rem !important;
     padding-bottom: 3.5rem !important; }
   .py-xl-9 {
     padding-top: 4rem !important;
     padding-bottom: 4rem !important; }
   .py-xl-10 {
     padding-top: 5rem !important;
     padding-bottom: 5rem !important; }
   .py-xl-11 {
     padding-top: 6rem !important;
     padding-bottom: 6rem !important; }
   .py-xl-12 {
     padding-top: 7rem !important;
     padding-bottom: 7rem !important; }
   .pt-xl-0 {
     padding-top: 0 !important; }
   .pt-xl-1 {
     padding-top: 0.25rem !important; }
   .pt-xl-2 {
     padding-top: 0.5rem !important; }
   .pt-xl-3 {
     padding-top: 1rem !important; }
   .pt-xl-4 {
     padding-top: 1.5rem !important; }
   .pt-xl-5 {
     padding-top: 2rem !important; }
   .pt-xl-6 {
     padding-top: 2.5rem !important; }
   .pt-xl-7 {
     padding-top: 3rem !important; }
   .pt-xl-8 {
     padding-top: 3.5rem !important; }
   .pt-xl-9 {
     padding-top: 4rem !important; }
   .pt-xl-10 {
     padding-top: 5rem !important; }
   .pt-xl-11 {
     padding-top: 6rem !important; }
   .pt-xl-12 {
     padding-top: 7rem !important; }
   .pe-xl-0 {
     padding-right: 0 !important; }
   .pe-xl-1 {
     padding-right: 0.25rem !important; }
   .pe-xl-2 {
     padding-right: 0.5rem !important; }
   .pe-xl-3 {
     padding-right: 1rem !important; }
   .pe-xl-4 {
     padding-right: 1.5rem !important; }
   .pe-xl-5 {
     padding-right: 2rem !important; }
   .pe-xl-6 {
     padding-right: 2.5rem !important; }
   .pe-xl-7 {
     padding-right: 3rem !important; }
   .pe-xl-8 {
     padding-right: 3.5rem !important; }
   .pe-xl-9 {
     padding-right: 4rem !important; }
   .pe-xl-10 {
     padding-right: 5rem !important; }
   .pe-xl-11 {
     padding-right: 6rem !important; }
   .pe-xl-12 {
     padding-right: 7rem !important; }
   .pb-xl-0 {
     padding-bottom: 0 !important; }
   .pb-xl-1 {
     padding-bottom: 0.25rem !important; }
   .pb-xl-2 {
     padding-bottom: 0.5rem !important; }
   .pb-xl-3 {
     padding-bottom: 1rem !important; }
   .pb-xl-4 {
     padding-bottom: 1.5rem !important; }
   .pb-xl-5 {
     padding-bottom: 2rem !important; }
   .pb-xl-6 {
     padding-bottom: 2.5rem !important; }
   .pb-xl-7 {
     padding-bottom: 3rem !important; }
   .pb-xl-8 {
     padding-bottom: 3.5rem !important; }
   .pb-xl-9 {
     padding-bottom: 4rem !important; }
   .pb-xl-10 {
     padding-bottom: 5rem !important; }
   .pb-xl-11 {
     padding-bottom: 6rem !important; }
   .pb-xl-12 {
     padding-bottom: 7rem !important; }
   .ps-xl-0 {
     padding-left: 0 !important; }
   .ps-xl-1 {
     padding-left: 0.25rem !important; }
   .ps-xl-2 {
     padding-left: 0.5rem !important; }
   .ps-xl-3 {
     padding-left: 1rem !important; }
   .ps-xl-4 {
     padding-left: 1.5rem !important; }
   .ps-xl-5 {
     padding-left: 2rem !important; }
   .ps-xl-6 {
     padding-left: 2.5rem !important; }
   .ps-xl-7 {
     padding-left: 3rem !important; }
   .ps-xl-8 {
     padding-left: 3.5rem !important; }
   .ps-xl-9 {
     padding-left: 4rem !important; }
   .ps-xl-10 {
     padding-left: 5rem !important; }
   .ps-xl-11 {
     padding-left: 6rem !important; }
   .ps-xl-12 {
     padding-left: 7rem !important; }
   .text-xl-start {
     text-align: left !important; }
   .text-xl-end {
     text-align: right !important; }
   .text-xl-center {
     text-align: center !important; } }
 
 @media (min-width: 1400px) {
   .float-xxl-start {
     float: left !important; }
   .float-xxl-end {
     float: right !important; }
   .float-xxl-none {
     float: none !important; }
   .d-xxl-inline {
     display: inline !important; }
   .d-xxl-inline-block {
     display: inline-block !important; }
   .d-xxl-block {
     display: block !important; }
   .d-xxl-grid {
     display: grid !important; }
   .d-xxl-table {
     display: table !important; }
   .d-xxl-table-row {
     display: table-row !important; }
   .d-xxl-table-cell {
     display: table-cell !important; }
   .d-xxl-flex {
     display: flex !important; }
   .d-xxl-inline-flex {
     display: inline-flex !important; }
   .d-xxl-none {
     display: none !important; }
   .flex-xxl-fill {
     flex: 1 1 auto !important; }
   .flex-xxl-row {
     flex-direction: row !important; }
   .flex-xxl-column {
     flex-direction: column !important; }
   .flex-xxl-row-reverse {
     flex-direction: row-reverse !important; }
   .flex-xxl-column-reverse {
     flex-direction: column-reverse !important; }
   .flex-xxl-grow-0 {
     flex-grow: 0 !important; }
   .flex-xxl-grow-1 {
     flex-grow: 1 !important; }
   .flex-xxl-shrink-0 {
     flex-shrink: 0 !important; }
   .flex-xxl-shrink-1 {
     flex-shrink: 1 !important; }
   .flex-xxl-wrap {
     flex-wrap: wrap !important; }
   .flex-xxl-nowrap {
     flex-wrap: nowrap !important; }
   .flex-xxl-wrap-reverse {
     flex-wrap: wrap-reverse !important; }
   .gap-xxl-0 {
     gap: 0 !important; }
   .gap-xxl-1 {
     gap: 0.25rem !important; }
   .gap-xxl-2 {
     gap: 0.5rem !important; }
   .gap-xxl-3 {
     gap: 1rem !important; }
   .gap-xxl-4 {
     gap: 1.5rem !important; }
   .gap-xxl-5 {
     gap: 2rem !important; }
   .gap-xxl-6 {
     gap: 2.5rem !important; }
   .gap-xxl-7 {
     gap: 3rem !important; }
   .gap-xxl-8 {
     gap: 3.5rem !important; }
   .gap-xxl-9 {
     gap: 4rem !important; }
   .gap-xxl-10 {
     gap: 5rem !important; }
   .gap-xxl-11 {
     gap: 6rem !important; }
   .gap-xxl-12 {
     gap: 7rem !important; }
   .justify-content-xxl-start {
     justify-content: flex-start !important; }
   .justify-content-xxl-end {
     justify-content: flex-end !important; }
   .justify-content-xxl-center {
     justify-content: center !important; }
   .justify-content-xxl-between {
     justify-content: space-between !important; }
   .justify-content-xxl-around {
     justify-content: space-around !important; }
   .justify-content-xxl-evenly {
     justify-content: space-evenly !important; }
   .align-items-xxl-start {
     align-items: flex-start !important; }
   .align-items-xxl-end {
     align-items: flex-end !important; }
   .align-items-xxl-center {
     align-items: center !important; }
   .align-items-xxl-baseline {
     align-items: baseline !important; }
   .align-items-xxl-stretch {
     align-items: stretch !important; }
   .align-content-xxl-start {
     align-content: flex-start !important; }
   .align-content-xxl-end {
     align-content: flex-end !important; }
   .align-content-xxl-center {
     align-content: center !important; }
   .align-content-xxl-between {
     align-content: space-between !important; }
   .align-content-xxl-around {
     align-content: space-around !important; }
   .align-content-xxl-stretch {
     align-content: stretch !important; }
   .align-self-xxl-auto {
     align-self: auto !important; }
   .align-self-xxl-start {
     align-self: flex-start !important; }
   .align-self-xxl-end {
     align-self: flex-end !important; }
   .align-self-xxl-center {
     align-self: center !important; }
   .align-self-xxl-baseline {
     align-self: baseline !important; }
   .align-self-xxl-stretch {
     align-self: stretch !important; }
   .order-xxl-first {
     order: -1 !important; }
   .order-xxl-0 {
     order: 0 !important; }
   .order-xxl-1 {
     order: 1 !important; }
   .order-xxl-2 {
     order: 2 !important; }
   .order-xxl-3 {
     order: 3 !important; }
   .order-xxl-4 {
     order: 4 !important; }
   .order-xxl-5 {
     order: 5 !important; }
   .order-xxl-last {
     order: 6 !important; }
   .m-xxl-0 {
     margin: 0 !important; }
   .m-xxl-1 {
     margin: 0.25rem !important; }
   .m-xxl-2 {
     margin: 0.5rem !important; }
   .m-xxl-3 {
     margin: 1rem !important; }
   .m-xxl-4 {
     margin: 1.5rem !important; }
   .m-xxl-5 {
     margin: 2rem !important; }
   .m-xxl-6 {
     margin: 2.5rem !important; }
   .m-xxl-7 {
     margin: 3rem !important; }
   .m-xxl-8 {
     margin: 3.5rem !important; }
   .m-xxl-9 {
     margin: 4rem !important; }
   .m-xxl-10 {
     margin: 5rem !important; }
   .m-xxl-11 {
     margin: 6rem !important; }
   .m-xxl-12 {
     margin: 7rem !important; }
   .m-xxl-auto {
     margin: auto !important; }
   .mx-xxl-0 {
     margin-right: 0 !important;
     margin-left: 0 !important; }
   .mx-xxl-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important; }
   .mx-xxl-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important; }
   .mx-xxl-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important; }
   .mx-xxl-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important; }
   .mx-xxl-5 {
     margin-right: 2rem !important;
     margin-left: 2rem !important; }
   .mx-xxl-6 {
     margin-right: 2.5rem !important;
     margin-left: 2.5rem !important; }
   .mx-xxl-7 {
     margin-right: 3rem !important;
     margin-left: 3rem !important; }
   .mx-xxl-8 {
     margin-right: 3.5rem !important;
     margin-left: 3.5rem !important; }
   .mx-xxl-9 {
     margin-right: 4rem !important;
     margin-left: 4rem !important; }
   .mx-xxl-10 {
     margin-right: 5rem !important;
     margin-left: 5rem !important; }
   .mx-xxl-11 {
     margin-right: 6rem !important;
     margin-left: 6rem !important; }
   .mx-xxl-12 {
     margin-right: 7rem !important;
     margin-left: 7rem !important; }
   .mx-xxl-auto {
     margin-right: auto !important;
     margin-left: auto !important; }
   .my-xxl-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important; }
   .my-xxl-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important; }
   .my-xxl-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important; }
   .my-xxl-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important; }
   .my-xxl-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important; }
   .my-xxl-5 {
     margin-top: 2rem !important;
     margin-bottom: 2rem !important; }
   .my-xxl-6 {
     margin-top: 2.5rem !important;
     margin-bottom: 2.5rem !important; }
   .my-xxl-7 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important; }
   .my-xxl-8 {
     margin-top: 3.5rem !important;
     margin-bottom: 3.5rem !important; }
   .my-xxl-9 {
     margin-top: 4rem !important;
     margin-bottom: 4rem !important; }
   .my-xxl-10 {
     margin-top: 5rem !important;
     margin-bottom: 5rem !important; }
   .my-xxl-11 {
     margin-top: 6rem !important;
     margin-bottom: 6rem !important; }
   .my-xxl-12 {
     margin-top: 7rem !important;
     margin-bottom: 7rem !important; }
   .my-xxl-auto {
     margin-top: auto !important;
     margin-bottom: auto !important; }
   .mt-xxl-0 {
     margin-top: 0 !important; }
   .mt-xxl-1 {
     margin-top: 0.25rem !important; }
   .mt-xxl-2 {
     margin-top: 0.5rem !important; }
   .mt-xxl-3 {
     margin-top: 1rem !important; }
   .mt-xxl-4 {
     margin-top: 1.5rem !important; }
   .mt-xxl-5 {
     margin-top: 2rem !important; }
   .mt-xxl-6 {
     margin-top: 2.5rem !important; }
   .mt-xxl-7 {
     margin-top: 3rem !important; }
   .mt-xxl-8 {
     margin-top: 3.5rem !important; }
   .mt-xxl-9 {
     margin-top: 4rem !important; }
   .mt-xxl-10 {
     margin-top: 5rem !important; }
   .mt-xxl-11 {
     margin-top: 6rem !important; }
   .mt-xxl-12 {
     margin-top: 7rem !important; }
   .mt-xxl-auto {
     margin-top: auto !important; }
   .me-xxl-0 {
     margin-right: 0 !important; }
   .me-xxl-1 {
     margin-right: 0.25rem !important; }
   .me-xxl-2 {
     margin-right: 0.5rem !important; }
   .me-xxl-3 {
     margin-right: 1rem !important; }
   .me-xxl-4 {
     margin-right: 1.5rem !important; }
   .me-xxl-5 {
     margin-right: 2rem !important; }
   .me-xxl-6 {
     margin-right: 2.5rem !important; }
   .me-xxl-7 {
     margin-right: 3rem !important; }
   .me-xxl-8 {
     margin-right: 3.5rem !important; }
   .me-xxl-9 {
     margin-right: 4rem !important; }
   .me-xxl-10 {
     margin-right: 5rem !important; }
   .me-xxl-11 {
     margin-right: 6rem !important; }
   .me-xxl-12 {
     margin-right: 7rem !important; }
   .me-xxl-auto {
     margin-right: auto !important; }
   .mb-xxl-0 {
     margin-bottom: 0 !important; }
   .mb-xxl-1 {
     margin-bottom: 0.25rem !important; }
   .mb-xxl-2 {
     margin-bottom: 0.5rem !important; }
   .mb-xxl-3 {
     margin-bottom: 1rem !important; }
   .mb-xxl-4 {
     margin-bottom: 1.5rem !important; }
   .mb-xxl-5 {
     margin-bottom: 2rem !important; }
   .mb-xxl-6 {
     margin-bottom: 2.5rem !important; }
   .mb-xxl-7 {
     margin-bottom: 3rem !important; }
   .mb-xxl-8 {
     margin-bottom: 3.5rem !important; }
   .mb-xxl-9 {
     margin-bottom: 4rem !important; }
   .mb-xxl-10 {
     margin-bottom: 5rem !important; }
   .mb-xxl-11 {
     margin-bottom: 6rem !important; }
   .mb-xxl-12 {
     margin-bottom: 7rem !important; }
   .mb-xxl-auto {
     margin-bottom: auto !important; }
   .ms-xxl-0 {
     margin-left: 0 !important; }
   .ms-xxl-1 {
     margin-left: 0.25rem !important; }
   .ms-xxl-2 {
     margin-left: 0.5rem !important; }
   .ms-xxl-3 {
     margin-left: 1rem !important; }
   .ms-xxl-4 {
     margin-left: 1.5rem !important; }
   .ms-xxl-5 {
     margin-left: 2rem !important; }
   .ms-xxl-6 {
     margin-left: 2.5rem !important; }
   .ms-xxl-7 {
     margin-left: 3rem !important; }
   .ms-xxl-8 {
     margin-left: 3.5rem !important; }
   .ms-xxl-9 {
     margin-left: 4rem !important; }
   .ms-xxl-10 {
     margin-left: 5rem !important; }
   .ms-xxl-11 {
     margin-left: 6rem !important; }
   .ms-xxl-12 {
     margin-left: 7rem !important; }
   .ms-xxl-auto {
     margin-left: auto !important; }
   .m-xxl-n1 {
     margin: -0.25rem !important; }
   .m-xxl-n2 {
     margin: -0.5rem !important; }
   .m-xxl-n3 {
     margin: -1rem !important; }
   .m-xxl-n4 {
     margin: -1.5rem !important; }
   .m-xxl-n5 {
     margin: -2rem !important; }
   .m-xxl-n6 {
     margin: -2.5rem !important; }
   .m-xxl-n7 {
     margin: -3rem !important; }
   .m-xxl-n8 {
     margin: -3.5rem !important; }
   .m-xxl-n9 {
     margin: -4rem !important; }
   .m-xxl-n10 {
     margin: -5rem !important; }
   .m-xxl-n11 {
     margin: -6rem !important; }
   .m-xxl-n12 {
     margin: -7rem !important; }
   .mx-xxl-n1 {
     margin-right: -0.25rem !important;
     margin-left: -0.25rem !important; }
   .mx-xxl-n2 {
     margin-right: -0.5rem !important;
     margin-left: -0.5rem !important; }
   .mx-xxl-n3 {
     margin-right: -1rem !important;
     margin-left: -1rem !important; }
   .mx-xxl-n4 {
     margin-right: -1.5rem !important;
     margin-left: -1.5rem !important; }
   .mx-xxl-n5 {
     margin-right: -2rem !important;
     margin-left: -2rem !important; }
   .mx-xxl-n6 {
     margin-right: -2.5rem !important;
     margin-left: -2.5rem !important; }
   .mx-xxl-n7 {
     margin-right: -3rem !important;
     margin-left: -3rem !important; }
   .mx-xxl-n8 {
     margin-right: -3.5rem !important;
     margin-left: -3.5rem !important; }
   .mx-xxl-n9 {
     margin-right: -4rem !important;
     margin-left: -4rem !important; }
   .mx-xxl-n10 {
     margin-right: -5rem !important;
     margin-left: -5rem !important; }
   .mx-xxl-n11 {
     margin-right: -6rem !important;
     margin-left: -6rem !important; }
   .mx-xxl-n12 {
     margin-right: -7rem !important;
     margin-left: -7rem !important; }
   .my-xxl-n1 {
     margin-top: -0.25rem !important;
     margin-bottom: -0.25rem !important; }
   .my-xxl-n2 {
     margin-top: -0.5rem !important;
     margin-bottom: -0.5rem !important; }
   .my-xxl-n3 {
     margin-top: -1rem !important;
     margin-bottom: -1rem !important; }
   .my-xxl-n4 {
     margin-top: -1.5rem !important;
     margin-bottom: -1.5rem !important; }
   .my-xxl-n5 {
     margin-top: -2rem !important;
     margin-bottom: -2rem !important; }
   .my-xxl-n6 {
     margin-top: -2.5rem !important;
     margin-bottom: -2.5rem !important; }
   .my-xxl-n7 {
     margin-top: -3rem !important;
     margin-bottom: -3rem !important; }
   .my-xxl-n8 {
     margin-top: -3.5rem !important;
     margin-bottom: -3.5rem !important; }
   .my-xxl-n9 {
     margin-top: -4rem !important;
     margin-bottom: -4rem !important; }
   .my-xxl-n10 {
     margin-top: -5rem !important;
     margin-bottom: -5rem !important; }
   .my-xxl-n11 {
     margin-top: -6rem !important;
     margin-bottom: -6rem !important; }
   .my-xxl-n12 {
     margin-top: -7rem !important;
     margin-bottom: -7rem !important; }
   .mt-xxl-n1 {
     margin-top: -0.25rem !important; }
   .mt-xxl-n2 {
     margin-top: -0.5rem !important; }
   .mt-xxl-n3 {
     margin-top: -1rem !important; }
   .mt-xxl-n4 {
     margin-top: -1.5rem !important; }
   .mt-xxl-n5 {
     margin-top: -2rem !important; }
   .mt-xxl-n6 {
     margin-top: -2.5rem !important; }
   .mt-xxl-n7 {
     margin-top: -3rem !important; }
   .mt-xxl-n8 {
     margin-top: -3.5rem !important; }
   .mt-xxl-n9 {
     margin-top: -4rem !important; }
   .mt-xxl-n10 {
     margin-top: -5rem !important; }
   .mt-xxl-n11 {
     margin-top: -6rem !important; }
   .mt-xxl-n12 {
     margin-top: -7rem !important; }
   .me-xxl-n1 {
     margin-right: -0.25rem !important; }
   .me-xxl-n2 {
     margin-right: -0.5rem !important; }
   .me-xxl-n3 {
     margin-right: -1rem !important; }
   .me-xxl-n4 {
     margin-right: -1.5rem !important; }
   .me-xxl-n5 {
     margin-right: -2rem !important; }
   .me-xxl-n6 {
     margin-right: -2.5rem !important; }
   .me-xxl-n7 {
     margin-right: -3rem !important; }
   .me-xxl-n8 {
     margin-right: -3.5rem !important; }
   .me-xxl-n9 {
     margin-right: -4rem !important; }
   .me-xxl-n10 {
     margin-right: -5rem !important; }
   .me-xxl-n11 {
     margin-right: -6rem !important; }
   .me-xxl-n12 {
     margin-right: -7rem !important; }
   .mb-xxl-n1 {
     margin-bottom: -0.25rem !important; }
   .mb-xxl-n2 {
     margin-bottom: -0.5rem !important; }
   .mb-xxl-n3 {
     margin-bottom: -1rem !important; }
   .mb-xxl-n4 {
     margin-bottom: -1.5rem !important; }
   .mb-xxl-n5 {
     margin-bottom: -2rem !important; }
   .mb-xxl-n6 {
     margin-bottom: -2.5rem !important; }
   .mb-xxl-n7 {
     margin-bottom: -3rem !important; }
   .mb-xxl-n8 {
     margin-bottom: -3.5rem !important; }
   .mb-xxl-n9 {
     margin-bottom: -4rem !important; }
   .mb-xxl-n10 {
     margin-bottom: -5rem !important; }
   .mb-xxl-n11 {
     margin-bottom: -6rem !important; }
   .mb-xxl-n12 {
     margin-bottom: -7rem !important; }
   .ms-xxl-n1 {
     margin-left: -0.25rem !important; }
   .ms-xxl-n2 {
     margin-left: -0.5rem !important; }
   .ms-xxl-n3 {
     margin-left: -1rem !important; }
   .ms-xxl-n4 {
     margin-left: -1.5rem !important; }
   .ms-xxl-n5 {
     margin-left: -2rem !important; }
   .ms-xxl-n6 {
     margin-left: -2.5rem !important; }
   .ms-xxl-n7 {
     margin-left: -3rem !important; }
   .ms-xxl-n8 {
     margin-left: -3.5rem !important; }
   .ms-xxl-n9 {
     margin-left: -4rem !important; }
   .ms-xxl-n10 {
     margin-left: -5rem !important; }
   .ms-xxl-n11 {
     margin-left: -6rem !important; }
   .ms-xxl-n12 {
     margin-left: -7rem !important; }
   .p-xxl-0 {
     padding: 0 !important; }
   .p-xxl-1 {
     padding: 0.25rem !important; }
   .p-xxl-2 {
     padding: 0.5rem !important; }
   .p-xxl-3 {
     padding: 1rem !important; }
   .p-xxl-4 {
     padding: 1.5rem !important; }
   .p-xxl-5 {
     padding: 2rem !important; }
   .p-xxl-6 {
     padding: 2.5rem !important; }
   .p-xxl-7 {
     padding: 3rem !important; }
   .p-xxl-8 {
     padding: 3.5rem !important; }
   .p-xxl-9 {
     padding: 4rem !important; }
   .p-xxl-10 {
     padding: 5rem !important; }
   .p-xxl-11 {
     padding: 6rem !important; }
   .p-xxl-12 {
     padding: 7rem !important; }
   .px-xxl-0 {
     padding-right: 0 !important;
     padding-left: 0 !important; }
   .px-xxl-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important; }
   .px-xxl-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important; }
   .px-xxl-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important; }
   .px-xxl-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important; }
   .px-xxl-5 {
     padding-right: 2rem !important;
     padding-left: 2rem !important; }
   .px-xxl-6 {
     padding-right: 2.5rem !important;
     padding-left: 2.5rem !important; }
   .px-xxl-7 {
     padding-right: 3rem !important;
     padding-left: 3rem !important; }
   .px-xxl-8 {
     padding-right: 3.5rem !important;
     padding-left: 3.5rem !important; }
   .px-xxl-9 {
     padding-right: 4rem !important;
     padding-left: 4rem !important; }
   .px-xxl-10 {
     padding-right: 5rem !important;
     padding-left: 5rem !important; }
   .px-xxl-11 {
     padding-right: 6rem !important;
     padding-left: 6rem !important; }
   .px-xxl-12 {
     padding-right: 7rem !important;
     padding-left: 7rem !important; }
   .py-xxl-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important; }
   .py-xxl-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important; }
   .py-xxl-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important; }
   .py-xxl-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important; }
   .py-xxl-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important; }
   .py-xxl-5 {
     padding-top: 2rem !important;
     padding-bottom: 2rem !important; }
   .py-xxl-6 {
     padding-top: 2.5rem !important;
     padding-bottom: 2.5rem !important; }
   .py-xxl-7 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important; }
   .py-xxl-8 {
     padding-top: 3.5rem !important;
     padding-bottom: 3.5rem !important; }
   .py-xxl-9 {
     padding-top: 4rem !important;
     padding-bottom: 4rem !important; }
   .py-xxl-10 {
     padding-top: 5rem !important;
     padding-bottom: 5rem !important; }
   .py-xxl-11 {
     padding-top: 6rem !important;
     padding-bottom: 6rem !important; }
   .py-xxl-12 {
     padding-top: 7rem !important;
     padding-bottom: 7rem !important; }
   .pt-xxl-0 {
     padding-top: 0 !important; }
   .pt-xxl-1 {
     padding-top: 0.25rem !important; }
   .pt-xxl-2 {
     padding-top: 0.5rem !important; }
   .pt-xxl-3 {
     padding-top: 1rem !important; }
   .pt-xxl-4 {
     padding-top: 1.5rem !important; }
   .pt-xxl-5 {
     padding-top: 2rem !important; }
   .pt-xxl-6 {
     padding-top: 2.5rem !important; }
   .pt-xxl-7 {
     padding-top: 3rem !important; }
   .pt-xxl-8 {
     padding-top: 3.5rem !important; }
   .pt-xxl-9 {
     padding-top: 4rem !important; }
   .pt-xxl-10 {
     padding-top: 5rem !important; }
   .pt-xxl-11 {
     padding-top: 6rem !important; }
   .pt-xxl-12 {
     padding-top: 7rem !important; }
   .pe-xxl-0 {
     padding-right: 0 !important; }
   .pe-xxl-1 {
     padding-right: 0.25rem !important; }
   .pe-xxl-2 {
     padding-right: 0.5rem !important; }
   .pe-xxl-3 {
     padding-right: 1rem !important; }
   .pe-xxl-4 {
     padding-right: 1.5rem !important; }
   .pe-xxl-5 {
     padding-right: 2rem !important; }
   .pe-xxl-6 {
     padding-right: 2.5rem !important; }
   .pe-xxl-7 {
     padding-right: 3rem !important; }
   .pe-xxl-8 {
     padding-right: 3.5rem !important; }
   .pe-xxl-9 {
     padding-right: 4rem !important; }
   .pe-xxl-10 {
     padding-right: 5rem !important; }
   .pe-xxl-11 {
     padding-right: 6rem !important; }
   .pe-xxl-12 {
     padding-right: 7rem !important; }
   .pb-xxl-0 {
     padding-bottom: 0 !important; }
   .pb-xxl-1 {
     padding-bottom: 0.25rem !important; }
   .pb-xxl-2 {
     padding-bottom: 0.5rem !important; }
   .pb-xxl-3 {
     padding-bottom: 1rem !important; }
   .pb-xxl-4 {
     padding-bottom: 1.5rem !important; }
   .pb-xxl-5 {
     padding-bottom: 2rem !important; }
   .pb-xxl-6 {
     padding-bottom: 2.5rem !important; }
   .pb-xxl-7 {
     padding-bottom: 3rem !important; }
   .pb-xxl-8 {
     padding-bottom: 3.5rem !important; }
   .pb-xxl-9 {
     padding-bottom: 4rem !important; }
   .pb-xxl-10 {
     padding-bottom: 5rem !important; }
   .pb-xxl-11 {
     padding-bottom: 6rem !important; }
   .pb-xxl-12 {
     padding-bottom: 7rem !important; }
   .ps-xxl-0 {
     padding-left: 0 !important; }
   .ps-xxl-1 {
     padding-left: 0.25rem !important; }
   .ps-xxl-2 {
     padding-left: 0.5rem !important; }
   .ps-xxl-3 {
     padding-left: 1rem !important; }
   .ps-xxl-4 {
     padding-left: 1.5rem !important; }
   .ps-xxl-5 {
     padding-left: 2rem !important; }
   .ps-xxl-6 {
     padding-left: 2.5rem !important; }
   .ps-xxl-7 {
     padding-left: 3rem !important; }
   .ps-xxl-8 {
     padding-left: 3.5rem !important; }
   .ps-xxl-9 {
     padding-left: 4rem !important; }
   .ps-xxl-10 {
     padding-left: 5rem !important; }
   .ps-xxl-11 {
     padding-left: 6rem !important; }
   .ps-xxl-12 {
     padding-left: 7rem !important; }
   .text-xxl-start {
     text-align: left !important; }
   .text-xxl-end {
     text-align: right !important; }
   .text-xxl-center {
     text-align: center !important; } }
 
 @media (min-width: 1200px) {
   .fs-1 {
     font-size: 2.5rem !important; }
   .fs-2 {
     font-size: 2rem !important; }
   .fs-3 {
     font-size: 1.75rem !important; }
   .fs-4 {
     font-size: 1.5rem !important; }
   .fs-sm-1 {
     font-size: 2.5rem !important; }
   .fs-sm-2 {
     font-size: 2rem !important; }
   .fs-sm-3 {
     font-size: 1.75rem !important; }
   .fs-sm-4 {
     font-size: 1.5rem !important; }
   .fs-md-1 {
     font-size: 2.5rem !important; }
   .fs-md-2 {
     font-size: 2rem !important; }
   .fs-md-3 {
     font-size: 1.75rem !important; }
   .fs-md-4 {
     font-size: 1.5rem !important; }
   .fs-lg-1 {
     font-size: 2.5rem !important; }
   .fs-lg-2 {
     font-size: 2rem !important; }
   .fs-lg-3 {
     font-size: 1.75rem !important; }
   .fs-lg-4 {
     font-size: 1.5rem !important; } }
 
 @media print {
   .d-print-inline {
     display: inline !important; }
   .d-print-inline-block {
     display: inline-block !important; }
   .d-print-block {
     display: block !important; }
   .d-print-grid {
     display: grid !important; }
   .d-print-table {
     display: table !important; }
   .d-print-table-row {
     display: table-row !important; }
   .d-print-table-cell {
     display: table-cell !important; }
   .d-print-flex {
     display: flex !important; }
   .d-print-inline-flex {
     display: inline-flex !important; }
   .d-print-none {
     display: none !important; } }
 
 .preloader {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   background: #ffffff;
   z-index: 99999; }
   .preloader .spinner-border {
     width: 40px;
     height: 40px;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     margin: auto; }
 
 .display-1,
 .display-2,
 .display-3,
 .display-4 {
   color: #203656;
   letter-spacing: -.02em; }
   .white-text .display-1, .white-text
   .display-2, .white-text
   .display-3, .white-text
   .display-4 {
     color: #ffffff; }
 
 h1, .h1 {
   margin-bottom: 1rem;
   line-height: 1.2; }
 
 @media (min-width: 768px) {
   .display-1,
   .display-2,
   .display-3,
   .display-4 {
     margin-bottom: 1.5rem; } }
 
 .text-white h1, .text-white .h1, .text-white h2, .text-white .h2, .text-white h3, .text-white .h3, .text-white h4, .text-white .h4, .text-white h5, .text-white .h5, .text-white h6, .text-white .h6, .text-white
 .h1, .text-white
 .h2, .text-white
 .h3, .text-white
 .h4, .text-white
 .h5, .text-white
 .h6 {
   color: #ffffff; }
 
 .white-link a {
   color: rgba(255, 255, 255, 0.6); }
   .white-link a:hover {
     color: #ffffff; }
 
 .dark-link a {
   color: #8F9BAD; }
   .dark-link a:hover {
     color: #203656; }
 
 .primary-link a {
   color: #203656; }
   .primary-link a:hover {
     color: #FE4F70; }
 
 mark, .mark,
 .mark {
   color: inherit;
   padding: 0;
   background: 0 0;
   background-image: linear-gradient(120deg, rgba(254, 79, 112, 0.4) 0, rgba(254, 79, 112, 0.4) 100%);
   background-repeat: no-repeat;
   background-size: 100% .3em;
   background-position: 0 80%;
   position: relative; }
 
 @media (max-width: 767.98px) {
   .lead {
     font-size: 1rem; } }
 
 /* Font wight
 --------------------*/
 .font-w-100 {
   font-weight: 100; }
 
 .font-w-200 {
   font-weight: 200; }
 
 .font-w-300 {
   font-weight: 300; }
 
 .font-w-400 {
   font-weight: 400; }
 
 .font-w-500 {
   font-weight: 500; }
 
 .font-w-600 {
   font-weight: 600; }
 
 .font-w-700 {
   font-weight: 700; }
 
 .font-w-800 {
   font-weight: 800; }
 
 .font-w-900 {
   font-weight: 900; }
 
 /* Letter spacing
 -------------------------------*/
 .letter-spacing-1 {
   letter-spacing: 1px; }
 
 .letter-spacing-2 {
   letter-spacing: 2px; }
 
 .letter-spacing-3 {
   letter-spacing: 3px; }
 
 .letter-spacing-4 {
   letter-spacing: 4px; }
 
 .letter-spacing-5 {
   letter-spacing: 5px; }
 
 .letter-spacing-6 {
   letter-spacing: 6px; }
 
 .letter-spacing-7 {
   letter-spacing: 7px; }
 
 .letter-spacing-8 {
   letter-spacing: 8px; }
 
 .letter-spacing-9 {
   letter-spacing: 9px; }
 
 .letter-spacing-10 {
   letter-spacing: 10px; }
 
 .after-k:after {
   content: "K"; }
 
 .after-p:after {
   content: "%"; }
 
 .after-plus:after {
   content: "+"; }
 
 .btn .btn--text:not(:last-child) {
   margin-right: .75em; }
 
 .btn .btn--text:not(:first-child) {
   margin-left: .75em; }
 
 .link-effect {
   background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
   background-size: 0px 2px;
   background-position: 0px 95%;
   background-repeat: no-repeat;
   transition: ease all 0.35s; }
   .link-effect:hover {
     background-size: 100% 2px; }
 
 .link-effect-box .link-effect-in {
   background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
   background-size: 0px 2px;
   background-position: 0px 95%;
   background-repeat: no-repeat;
   transition: ease all 0.35s; }
 
 .link-effect-box:hover .link-effect-in {
   background-size: 100% 2px; }
 
 .btn-primary-light {
   color: #FE4F70;
   background-color: rgba(254, 79, 112, 0.2); }
   .btn-primary-light:hover {
     color: #ffffff;
     background-color: #FE4F70; }
 
 .btn-secondary-light {
   color: #6c757d;
   background-color: rgba(108, 117, 125, 0.2); }
   .btn-secondary-light:hover {
     color: #ffffff;
     background-color: #6c757d; }
 
 .btn-success-light {
   color: #2dca8c;
   background-color: rgba(45, 202, 140, 0.2); }
   .btn-success-light:hover {
     color: #ffffff;
     background-color: #2dca8c; }
 
 .btn-info-light {
   color: #50b5ff;
   background-color: rgba(80, 181, 255, 0.2); }
   .btn-info-light:hover {
     color: #ffffff;
     background-color: #50b5ff; }
 
 .btn-warning-light {
   color: #ff9f1c;
   background-color: rgba(255, 159, 28, 0.2); }
   .btn-warning-light:hover {
     color: #ffffff;
     background-color: #ff9f1c; }
 
 .btn-danger-light {
   color: #ff5c75;
   background-color: rgba(255, 92, 117, 0.2); }
   .btn-danger-light:hover {
     color: #ffffff;
     background-color: #ff5c75; }
 
 .btn-light-light {
   color: #f1f6fd;
   background-color: rgba(241, 246, 253, 0.2); }
   .btn-light-light:hover {
     color: #ffffff;
     background-color: #f1f6fd; }
 
 .btn-black-light {
   color: #000;
   background-color: rgba(0, 0, 0, 0.2); }
   .btn-black-light:hover {
     color: #ffffff;
     background-color: #000; }
 
 .btn-dark-light {
   color: #203656;
   background-color: rgba(32, 54, 86, 0.2); }
   .btn-dark-light:hover {
     color: #ffffff;
     background-color: #203656; }
 
 .section {
   padding-top: 3rem;
   padding-bottom: 3rem; }
   @media (min-width: 768px) {
     .section {
       padding-top: 5rem;
       padding-bottom: 5rem; } }
   @media (min-width: 992px) {
     .section {
       padding-top: 6.25rem;
       padding-bottom: 6.25rem; } }
 
 .section-heading {
   margin-bottom: 2.5rem; }
   @media (min-width: 768px) {
     .section-heading {
       margin-bottom: 2rem; } }
   @media (min-width: 992px) {
     .section-heading {
       margin-bottom: 3rem; } }
 
 .min-vh-50 {
   min-height: 50vh; }
 
 .min-vh-85 {
   min-height: 85vh; }
 
 .min-vh-65 {
   min-height: 65vh; }
 
 .min-h-150px {
   min-height: 150px; }
 
 .min-h-250px {
   min-height: 250px; }
 
 .min-h-350px {
   min-height: 350px; }
 
 .w-5px {
   width: 5px; }
 
 .w-10px {
   width: 10px; }
 
 .w-15px {
   width: 15px; }
 
 .w-25px {
   width: 25px; }
 
 .w-30px {
   width: 30px; }
 
 .w-35px {
   width: 35px; }
 
 .w-40px {
   width: 40px; }
 
 .w-45px {
   width: 45px; }
 
 .w-50px {
   width: 50px; }
 
 .w-55px {
   width: 55px; }
 
 .w-60px {
   width: 60px; }
 
 .w-65px {
   width: 65px; }
 
 .w-75px {
   width: 75px; }
 
 .w-80px {
   width: 80px; }
 
 .w-85px {
   width: 85px; }
 
 .w-90px {
   width: 90px; }
 
 .w-95px {
   width: 95px; }
 
 .w-100px {
   width: 100px; }
 
 .w-autopx {
   width: autopx; }
 
 .w-5 {
   width: 5% !important; }
 
 .w-10 {
   width: 10% !important; }
 
 .w-15 {
   width: 15% !important; }
 
 .w-25 {
   width: 25% !important; }
 
 .w-30 {
   width: 30% !important; }
 
 .w-35 {
   width: 35% !important; }
 
 .w-40 {
   width: 40% !important; }
 
 .w-45 {
   width: 45% !important; }
 
 .w-50 {
   width: 50% !important; }
 
 .w-55 {
   width: 55% !important; }
 
 .w-60 {
   width: 60% !important; }
 
 .w-65 {
   width: 65% !important; }
 
 .w-75 {
   width: 75% !important; }
 
 .w-80 {
   width: 80% !important; }
 
 .w-85 {
   width: 85% !important; }
 
 .w-90 {
   width: 90% !important; }
 
 .w-95 {
   width: 95% !important; }
 
 .w-100 {
   width: 100% !important; }
 
 .w-auto {
   width: auto !important; }
 
 .h-1px {
   height: 1px; }
 
 .h-2px {
   height: 2px; }
 
 .h-3px {
   height: 3px; }
 
 .h-4px {
   height: 4px; }
 
 .h-5px {
   height: 5px; }
 
 .h-6px {
   height: 6px; }
 
 .h-7px {
   height: 7px; }
 
 .h-8px {
   height: 8px; }
 
 .h-9px {
   height: 9px; }
 
 @media (min-width: 576px) {
   .w-sm-5 {
     width: 5% !important; }
   .w-sm-10 {
     width: 10% !important; }
   .w-sm-15 {
     width: 15% !important; }
   .w-sm-25 {
     width: 25% !important; }
   .w-sm-30 {
     width: 30% !important; }
   .w-sm-35 {
     width: 35% !important; }
   .w-sm-40 {
     width: 40% !important; }
   .w-sm-45 {
     width: 45% !important; }
   .w-sm-50 {
     width: 50% !important; }
   .w-sm-55 {
     width: 55% !important; }
   .w-sm-60 {
     width: 60% !important; }
   .w-sm-65 {
     width: 65% !important; }
   .w-sm-75 {
     width: 75% !important; }
   .w-sm-80 {
     width: 80% !important; }
   .w-sm-85 {
     width: 85% !important; }
   .w-sm-90 {
     width: 90% !important; }
   .w-sm-95 {
     width: 95% !important; }
   .w-sm-100 {
     width: 100% !important; }
   .w-sm-auto {
     width: auto !important; } }
 
 @media (min-width: 768px) {
   .w-md-5 {
     width: 5% !important; }
   .w-md-10 {
     width: 10% !important; }
   .w-md-15 {
     width: 15% !important; }
   .w-md-25 {
     width: 25% !important; }
   .w-md-30 {
     width: 30% !important; }
   .w-md-35 {
     width: 35% !important; }
   .w-md-40 {
     width: 40% !important; }
   .w-md-45 {
     width: 45% !important; }
   .w-md-50 {
     width: 50% !important; }
   .w-md-55 {
     width: 55% !important; }
   .w-md-60 {
     width: 60% !important; }
   .w-md-65 {
     width: 65% !important; }
   .w-md-75 {
     width: 75% !important; }
   .w-md-80 {
     width: 80% !important; }
   .w-md-85 {
     width: 85% !important; }
   .w-md-90 {
     width: 90% !important; }
   .w-md-95 {
     width: 95% !important; }
   .w-md-100 {
     width: 100% !important; }
   .w-md-auto {
     width: auto !important; } }
 
 @media (min-width: 992px) {
   .w-lg-5 {
     width: 5% !important; }
   .w-lg-10 {
     width: 10% !important; }
   .w-lg-15 {
     width: 15% !important; }
   .w-lg-25 {
     width: 25% !important; }
   .w-lg-30 {
     width: 30% !important; }
   .w-lg-35 {
     width: 35% !important; }
   .w-lg-40 {
     width: 40% !important; }
   .w-lg-45 {
     width: 45% !important; }
   .w-lg-50 {
     width: 50% !important; }
   .w-lg-55 {
     width: 55% !important; }
   .w-lg-60 {
     width: 60% !important; }
   .w-lg-65 {
     width: 65% !important; }
   .w-lg-75 {
     width: 75% !important; }
   .w-lg-80 {
     width: 80% !important; }
   .w-lg-85 {
     width: 85% !important; }
   .w-lg-90 {
     width: 90% !important; }
   .w-lg-95 {
     width: 95% !important; }
   .w-lg-100 {
     width: 100% !important; }
   .w-lg-auto {
     width: auto !important; } }
 
 @media (min-width: 1200px) {
   .w-xl-5 {
     width: 5% !important; }
   .w-xl-10 {
     width: 10% !important; }
   .w-xl-15 {
     width: 15% !important; }
   .w-xl-25 {
     width: 25% !important; }
   .w-xl-30 {
     width: 30% !important; }
   .w-xl-35 {
     width: 35% !important; }
   .w-xl-40 {
     width: 40% !important; }
   .w-xl-45 {
     width: 45% !important; }
   .w-xl-50 {
     width: 50% !important; }
   .w-xl-55 {
     width: 55% !important; }
   .w-xl-60 {
     width: 60% !important; }
   .w-xl-65 {
     width: 65% !important; }
   .w-xl-75 {
     width: 75% !important; }
   .w-xl-80 {
     width: 80% !important; }
   .w-xl-85 {
     width: 85% !important; }
   .w-xl-90 {
     width: 90% !important; }
   .w-xl-95 {
     width: 95% !important; }
   .w-xl-100 {
     width: 100% !important; }
   .w-xl-auto {
     width: auto !important; } }
 
 .card {
   box-shadow: none; }
   .card > .list-group {
     border-top: none;
     border-bottom: none; }
   .card.no-shadow {
     box-shadow: none; }
 
 .card-header {
   border-bottom: 0;
   padding-bottom: 0; }
   .card-header + .list-group .list-group-item:first-child {
     border-top: none; }
 
 .nav-tabs .nav-link {
   color: #8F9BAD; }
 
 .nav-link {
   color: #5a5b75; }
   .nav-link.active {
     color: #101010; }
   .nav-link:hover {
     color: #101010; }
 
 .text-gray-100 {
   color: #f7f7ff; }
 
 .text-gray-200 {
   color: #eff2f7; }
 
 .text-gray-300 {
   color: #e2e8f0; }
 
 .text-gray-400 {
   color: #cbd5e0; }
 
 .text-gray-500 {
   color: #a0aec0; }
 
 .text-gray-600 {
   color: #3a3a3a; }
 
 .text-gray-700 {
   color: #333333; }
 
 .text-gray-800 {
   color: #101010; }
 
 .text-gray-900 {
   color: #080808; }
 
 .text-white-65 {
   color: rgba(255, 255, 255, 0.65) !important; }
 
 .bg-cover {
   background-size: cover; }
 
 .bg-right-center {
   background-position: center right; }
 
 .bg-no-repeat {
   background-repeat: no-repeat; }
 
 .bg-center {
   background-position: center; }
 
 .bg-black {
   background: #000; }
 
 .bg-gray-100 {
   background-color: #f7f7ff !important; }
 
 .bg-gray-200 {
   background-color: #eff2f7 !important; }
 
 .bg-gray-300 {
   background-color: #e2e8f0 !important; }
 
 .bg-gray-400 {
   background-color: #cbd5e0 !important; }
 
 .bg-gray-500 {
   background-color: #a0aec0 !important; }
 
 .bg-gray-600 {
   background-color: #3a3a3a !important; }
 
 .bg-gray-700 {
   background-color: #333333 !important; }
 
 .bg-gray-800 {
   background-color: #101010 !important; }
 
 .bg-gray-900 {
   background-color: #080808 !important; }
 
 .bg-primary-light {
   background-color: rgba(254, 79, 112, 0.2); }
 
 .bg-secondary-light {
   background-color: rgba(108, 117, 125, 0.2); }
 
 .bg-success-light {
   background-color: rgba(45, 202, 140, 0.2); }
 
 .bg-info-light {
   background-color: rgba(80, 181, 255, 0.2); }
 
 .bg-warning-light {
   background-color: rgba(255, 159, 28, 0.2); }
 
 .bg-danger-light {
   background-color: rgba(255, 92, 117, 0.2); }
 
 .bg-light-light {
   background-color: rgba(241, 246, 253, 0.2); }
 
 .bg-black-light {
   background-color: rgba(0, 0, 0, 0.2); }
 
 .bg-dark-light {
   background-color: rgba(32, 54, 86, 0.2); }
 
 .primary-after:after {
   background-color: #FE4F70; }
 
 .secondary-after:after {
   background-color: #6c757d; }
 
 .success-after:after {
   background-color: #2dca8c; }
 
 .info-after:after {
   background-color: #50b5ff; }
 
 .warning-after:after {
   background-color: #ff9f1c; }
 
 .danger-after:after {
   background-color: #ff5c75; }
 
 .light-after:after {
   background-color: #f1f6fd; }
 
 .black-after:after {
   background-color: #000; }
 
 .dark-after:after {
   background-color: #203656; }
 
 .bg-primary-gradient {
   background: linear-gradient(180deg, #FE4F70 50%, #fe1c47 100%) repeat-x !important; }
 
 .bg-secondary-gradient {
   background: linear-gradient(180deg, #6c757d 50%, #545b62 100%) repeat-x !important; }
 
 .bg-success-gradient {
   background: linear-gradient(180deg, #2dca8c 50%, #24a06f 100%) repeat-x !important; }
 
 .bg-info-gradient {
   background: linear-gradient(180deg, #50b5ff 50%, #1d9fff 100%) repeat-x !important; }
 
 .bg-warning-gradient {
   background: linear-gradient(180deg, #ff9f1c 50%, #e88600 100%) repeat-x !important; }
 
 .bg-danger-gradient {
   background: linear-gradient(180deg, #ff5c75 50%, #ff294a 100%) repeat-x !important; }
 
 .bg-light-gradient {
   background: linear-gradient(180deg, #f1f6fd 50%, #c4d9f7 100%) repeat-x !important; }
 
 .bg-black-gradient {
   background: linear-gradient(180deg, #000 50%, black 100%) repeat-x !important; }
 
 .bg-dark-gradient {
   background: linear-gradient(180deg, #203656 50%, #121f31 100%) repeat-x !important; }
 
 .bg-primary-gradient-opacity {
   background: linear-gradient(180deg, rgba(254, 79, 112, 0.2) 30%, #fe1c47 100%) repeat-x !important; }
 
 .bg-secondary-gradient-opacity {
   background: linear-gradient(180deg, rgba(108, 117, 125, 0.2) 30%, #545b62 100%) repeat-x !important; }
 
 .bg-success-gradient-opacity {
   background: linear-gradient(180deg, rgba(45, 202, 140, 0.2) 30%, #24a06f 100%) repeat-x !important; }
 
 .bg-info-gradient-opacity {
   background: linear-gradient(180deg, rgba(80, 181, 255, 0.2) 30%, #1d9fff 100%) repeat-x !important; }
 
 .bg-warning-gradient-opacity {
   background: linear-gradient(180deg, rgba(255, 159, 28, 0.2) 30%, #e88600 100%) repeat-x !important; }
 
 .bg-danger-gradient-opacity {
   background: linear-gradient(180deg, rgba(255, 92, 117, 0.2) 30%, #ff294a 100%) repeat-x !important; }
 
 .bg-light-gradient-opacity {
   background: linear-gradient(180deg, rgba(241, 246, 253, 0.2) 30%, #c4d9f7 100%) repeat-x !important; }
 
 .bg-black-gradient-opacity {
   background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 30%, black 100%) repeat-x !important; }
 
 .bg-dark-gradient-opacity {
   background: linear-gradient(180deg, rgba(32, 54, 86, 0.2) 30%, #121f31 100%) repeat-x !important; }
 
 /* Effect Section
 ------------------------*/
 .effect-section {
   position: relative;
   overflow: hidden; }
 
 @media (min-width: 992px) {
   .sticky-lg-top-header {
     top: 100px; }
   .sticky-lg-top-50 {
     top: 50px; } }
 
 .after-shadow {
   z-index: 1;
   position: relative; }
   .after-shadow:after {
     content: "";
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     margin: auto;
     border-radius: 50%;
     animation: pulse-border 1500ms ease-out infinite;
     pointer-events: none;
     background: currentColor;
     z-index: -1; }
 
 img {
   max-width: 100%; }
 
 .object-fit {
   -o-object-fit: cover;
   object-fit: cover; }
 
 @media (min-width: 992px) {
   .img-lg-120 {
     max-width: 120%; }
   .img-lg-140 {
     max-width: 140%; } }
 
 @media (min-width: 768px) {
   .img-md-120 {
     max-width: 120%; }
   .img-md-140 {
     max-width: 140%; } }
 
 .icon {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   vertical-align: middle;
   border-radius: 0.375rem;
   width: 3rem;
   height: 3rem; }
   .icon svg,
   .icon i {
     font-size: 1.125rem; }
   .icon .svg_icon {
     width: 1.85rem;
     height: 1.85rem; }
   .icon-sm {
     width: 2rem;
     height: 2rem; }
     .icon-sm svg,
     .icon-sm i {
       font-size: .75rem; }
     .icon-sm .svg_icon {
       width: .85rem;
       height: .85rem; }
   .icon-xl {
     width: 5rem;
     height: 5rem; }
     .icon-xl svg,
     .icon-xl i {
       font-size: 2.25rem; }
     .icon-xl .svg_icon {
       width: 3rem;
       height: 3rem; }
   .icon-lg {
     width: 4rem;
     height: 4rem; }
     .icon-lg svg,
     .icon-lg i {
       font-size: 1.75rem; }
     .icon-lg .svg_icon {
       width: 2.25rem;
       height: 2.25rem; }
   .icon-xs {
     width: 1rem;
     height: 1rem; }
     .icon-xs svg,
     .icon-xs i {
       font-size: .5rem; }
     .icon-xs .svg_icon {
       width: 0.75rem;
       height: 0.75rem; }
 
 .icon-primary {
   color: #ffffff;
   background-color: #FE4F70; }
 
 .icon-secondary {
   color: #ffffff;
   background-color: #6c757d; }
 
 .icon-success {
   color: #ffffff;
   background-color: #2dca8c; }
 
 .icon-info {
   color: #ffffff;
   background-color: #50b5ff; }
 
 .icon-warning {
   color: #ffffff;
   background-color: #ff9f1c; }
 
 .icon-danger {
   color: #ffffff;
   background-color: #ff5c75; }
 
 .icon-light {
   color: #203656;
   background-color: #f1f6fd; }
 
 .icon-black {
   color: #ffffff;
   background-color: #000; }
 
 .icon-dark {
   color: #ffffff;
   background-color: #203656; }
 
 a.icon-primary:hover {
   color: #ffffff;
   background-color: #fe1c47; }
 
 a.icon-secondary:hover {
   color: #ffffff;
   background-color: #545b62; }
 
 a.icon-success:hover {
   color: #ffffff;
   background-color: #24a06f; }
 
 a.icon-info:hover {
   color: #ffffff;
   background-color: #1d9fff; }
 
 a.icon-warning:hover {
   color: #ffffff;
   background-color: #e88600; }
 
 a.icon-danger:hover {
   color: #ffffff;
   background-color: #ff294a; }
 
 a.icon-light:hover {
   color: #203656;
   background-color: #c4d9f7; }
 
 a.icon-black:hover {
   color: #ffffff;
   background-color: black; }
 
 a.icon-dark:hover {
   color: #ffffff;
   background-color: #121f31; }
 
 .icon-primary-light {
   color: #FE4F70;
   background-color: rgba(254, 79, 112, 0.2); }
 
 .icon-secondary-light {
   color: #6c757d;
   background-color: rgba(108, 117, 125, 0.2); }
 
 .icon-success-light {
   color: #2dca8c;
   background-color: rgba(45, 202, 140, 0.2); }
 
 .icon-info-light {
   color: #50b5ff;
   background-color: rgba(80, 181, 255, 0.2); }
 
 .icon-warning-light {
   color: #ff9f1c;
   background-color: rgba(255, 159, 28, 0.2); }
 
 .icon-danger-light {
   color: #ff5c75;
   background-color: rgba(255, 92, 117, 0.2); }
 
 .icon-light-light {
   color: #f1f6fd;
   background-color: rgba(241, 246, 253, 0.2); }
 
 .icon-black-light {
   color: #000;
   background-color: rgba(0, 0, 0, 0.2); }
 
 .icon-dark-light {
   color: #203656;
   background-color: rgba(32, 54, 86, 0.2); }
 
 .border-style {
   position: relative; }
   .border-style:after {
     content: '';
     display: block;
     width: 80%;
     position: absolute;
     left: 0;
     right: 0;
     margin: auto;
     height: 1px;
     background: radial-gradient(ellipse at center, #e2e8f0 0, rgba(255, 255, 255, 0) 75%); }
   .border-style.bottom:after {
     bottom: 0; }
   .border-style.top:after {
     top: 0; }
   .border-style.light:after {
     background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.2) 0, rgba(31, 45, 61, 0) 75%); }
 
 .separated {
   overflow: hidden; }
   .separated > * {
     box-shadow: -1px -1px 0px 0px #d9e2ef; }
 
 .divider {
   height: 1px;
   background: #000;
   display: block;
   width: 100%;
   margin: 0 auto; }
 
 .divider-light {
   height: 1px;
   background: #ffffff;
   display: block;
   width: 100%;
   margin: 0 auto; }
 
 .list-type-01 {
   margin: 0;
   padding: 0;
   list-style: none; }
   .list-type-01 li {
     position: relative;
     padding: 9px 0 9px 25px; }
     .list-type-01 li + li {
       border-top: 1px solid #ddd; }
     .list-type-01 li i {
       position: absolute;
       top: 10;
       left: 0; }
 
 .tag-style a {
   color: #8F9BAD;
   font-size: 15px;
   padding: 5px 15px;
   border-radius: 40px;
   border: 1px solid #e2e8f0;
   font-size: 14px;
   margin-right: 5px;
   margin-bottom: 5px; }
   .tag-style a:hover {
     border: 1px solid #FE4F70; }
 
 /* Mask
 ------------------------*/
 .mask {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0; }
 
 .opacity-1 {
   opacity: 0.1; }
 
 .opacity-2 {
   opacity: 0.2; }
 
 .opacity-3 {
   opacity: 0.3; }
 
 .opacity-4 {
   opacity: 0.4; }
 
 .opacity-5 {
   opacity: 0.5; }
 
 .opacity-6 {
   opacity: 0.6; }
 
 .opacity-7 {
   opacity: 0.7; }
 
 .opacity-8 {
   opacity: 0.8; }
 
 .opacity-9 {
   opacity: 0.9; }
 
 .z-index-1 {
   z-index: 1; }
 
 .z-index-2 {
   z-index: 2; }
 
 .z-index-3 {
   z-index: 3; }
 
 .z-index-4 {
   z-index: 4; }
 
 .z-index-5 {
   z-index: 5; }
 
 .z-index-6 {
   z-index: 6; }
 
 .z-index-7 {
   z-index: 7; }
 
 .z-index-8 {
   z-index: 8; }
 
 .z-index-9 {
   z-index: 9; }
 
 .z-index--1 {
   z-index: -1; }
 
 .z-index--2 {
   z-index: -2; }
 
 .owl-dots {
   text-align: center; }
   .owl-main-slider .owl-dots {
     position: absolute;
     bottom: 20px;
     left: 0;
     right: 0;
     font-size: 0px; }
   .owl-dots .owl-dot {
     display: inline-block;
     vertical-align: top;
     width: 15px;
     height: 15px;
     background: #ffffff;
     border: 1px solid #FE4F70;
     transition: ease all 0.55s;
     border-radius: 50%;
     margin: 0 5px; }
     .owl-carousel-white .owl-dots .owl-dot {
       border-color: #ffffff;
       background: none; }
     .owl-dots .owl-dot.active {
       background: #FE4F70; }
       .owl-carousel-white .owl-dots .owl-dot.active {
         background: #ffffff; }
 
 .owl-carousel .owl-item img {
   width: auto;
   max-width: 100%; }
 
 .owl-no-overflow .owl-stage-outer {
   overflow: inherit; }
 
 .owl-nav .owl-next,
 .owl-nav .owl-prev {
   position: absolute;
   top: 0;
   bottom: 0;
   width: 25px;
   height: 40px;
   margin: auto;
   text-align: center;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   font-size: 15px;
   color: #203656;
   background: #ffffff; }
   .owl-nav .owl-next i,
   .owl-nav .owl-prev i {
     line-height: inherit; }
   .owl-nav-arrow-bottom .owl-nav .owl-next, .owl-nav-arrow-bottom
   .owl-nav .owl-prev {
     position: relative;
     display: inline-block;
     margin-right: 6px;
     width: 30px;
     height: 30px;
     background: #203656;
     color: #ffffff;
     border-radius: 50%;
     padding: 0;
     line-height: 30px;
     font-size: 13px; }
     .owl-nav-arrow-bottom .owl-nav .owl-next:hover, .owl-nav-arrow-bottom
     .owl-nav .owl-prev:hover {
       background: #FE4F70; }
   .owl-nav-rounded .owl-nav .owl-next, .owl-nav-rounded
   .owl-nav .owl-prev {
     width: 55px;
     height: 55px;
     border-radius: 50%;
     font-size: 22px; }
   .owl-nav-rounded-sm .owl-nav .owl-next, .owl-nav-rounded-sm
   .owl-nav .owl-prev {
     width: 30px;
     height: 30px;
     border-radius: 50%;
     font-size: 12px; }
   .owl-nav-dark .owl-nav .owl-next, .owl-nav-dark
   .owl-nav .owl-prev {
     color: #ffffff;
     background: #203656; }
   .owl-nav-arrow-only .owl-nav .owl-next, .owl-nav-arrow-only
   .owl-nav .owl-prev {
     background: none;
     box-shadow: none;
     font-size: 28px; }
 
 .owl-nav .owl-next {
   right: 0; }
   .owl-nav-rounded .owl-nav .owl-next {
     right: 20px; }
   .owl-nav-rounded-sm .owl-nav .owl-next {
     right: 8px; }
 
 .owl-nav .owl-prev {
   left: 0; }
   .owl-nav-rounded .owl-nav .owl-prev {
     left: 20px; }
   .owl-nav-rounded-sm .owl-nav .owl-prev {
     left: 8px; }
 
 /*---------------------------
  Portfolio Start
 ------------------------------*/
 .post-masonry .grid-item {
   float: left; }
 
 .page-heading {
   padding-top: 6rem;
   padding-bottom: 6rem;
   background-size: cover;
   position: relative; }
   .page-heading .container {
     position: relative;
     z-index: 1; }
   .page-heading .breadcrumb {
     border: none;
     padding: 0; }
     .page-heading .breadcrumb.light .breadcrumb-item {
       color: rgba(255, 255, 255, 0.7);
       font-weight: 400; }
       .page-heading .breadcrumb.light .breadcrumb-item:before {
         color: rgba(255, 255, 255, 0.2); }
       .page-heading .breadcrumb.light .breadcrumb-item a {
         color: #ffffff; }
 
 article h4, article .h4,
 article h5,
 article .h5 {
   margin-bottom: 1rem; }
   article h4:not(:first-child), article .h4:not(:first-child),
   article h5:not(:first-child),
   article .h5:not(:first-child) {
     margin-top: 2rem; }
 
 article p {
   line-height: 1.9; }
 
 article figure {
   margin: 1rem 0 2rem;
   position: relative; }
   article figure .figure-caption {
     position: absolute;
     bottom: 0;
     left: 0;
     right: 0;
     color: #ffffff;
     padding: 10px;
     background: rgba(0, 0, 0, 0.75);
     margin: 0; }
   article figure + h4, article figure + .h4,
   article figure + h5,
   article figure + .h5 {
     margin-top: 0 !important; }
 
 article blockquote {
   border-left: 5px solid #e2e8f0;
   padding-left: 2rem;
   margin-top: 2rem;
   margin-bottom: 3rem;
   display: inline-block;
   vertical-align: top;
   width: 100%;
   color: #203656;
   font-weight: 600; }
   article blockquote + h4, article blockquote + .h4,
   article blockquote + h5,
   article blockquote + .h5 {
     margin-top: 0 !important; }
 
 .laptop-screen {
   position: relative; }
   .laptop-screen .laptop-screen-inner {
     position: absolute;
     width: 79.3%;
     left: 10.5%;
     top: 7.6%;
     height: 80.5%;
     border: 1px solid #e2e8f0;
     border-radius: 10px;
     overflow: hidden; }
     .laptop-screen .laptop-screen-inner img {
       -o-object-fit: cover;
       object-fit: cover; }
 
 .mobile-screen {
   position: relative;
   border: 10px solid #203656;
   border-radius: 28px; }
   .mobile-screen .mobile-hidden {
     opacity: 0;
     visibility: hidden; }
   .mobile-screen .mobile-screen-in {
     position: absolute;
     top: 5px;
     left: 5px;
     right: 5px;
     bottom: 5px;
     overflow: hidden;
     border-radius: 18px; }
     .mobile-screen .mobile-screen-in img {
       width: 100%;
       height: 100%;
       -o-object-fit: cover;
       object-fit: cover; }
 
 .avatar {
   position: relative;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   vertical-align: middle;
   font-weight: 600;
   height: 3.125rem;
   width: 3.125rem;
   overflow: hidden; }
   .avatar-rounded {
     border-radius: 0.375rem; }
   .avatar-circle {
     border-radius: 50%; }
   .avatar-sm {
     height: 2.4375rem;
     width: 2.4375rem; }
   .avatar-lg {
     height: 4rem;
     width: 4rem; }
   .avatar-xl {
     height: 6rem;
     width: 6rem; }
   .avatar-xxl {
     height: 9rem;
     width: 9rem; }
   .avatar-group .avatar {
     margin-left: -1.25rem; }
     .avatar-group .avatar:hover {
       z-index: 1; }
   .avatar-parent {
     display: inline-block;
     position: relative; }
   .avatar-child {
     position: absolute;
     bottom: -0.45rem;
     border: 2px solid #ffffff;
     left: 0;
     right: 0;
     margin: auto;
     width: 0.9rem;
     height: 0.9rem;
     border-radius: 50%; }
 
 .form-group {
   margin-bottom: 1rem; }
 
 .rd-mailform .has-error .invalid-feedback {
   display: block; }
 
 .rd-mailform .snackbars {
   border: 1px solid #eff2f7;
   background: #eff2f7;
   border-radius: 3px;
   font-size: 14px;
   font-weight: 500;
   margin-top: 20px;
   display: none; }
   .rd-mailform .snackbars.active {
     display: block; }
   .rd-mailform .snackbars.error {
     border: 1px solid #ff768b;
     background: #ff667e;
     color: #ff5c75; }
   .rd-mailform .snackbars.success {
     border: 1px solid #3dd498;
     background: #30d191;
     color: #2dca8c; }
   .rd-mailform .snackbars p {
     margin: 0;
     position: relative;
     display: flex;
     align-items: center; }
     .rd-mailform .snackbars p .s-icon {
       width: 40px;
       height: 40px;
       line-height: 40px;
       text-align: center;
       font-size: 16px; }
 
 .hover-top {
   transition: ease-in-out all 0.35s;
   position: relative;
   top: 0; }
   .hover-top:hover {
     top: -10px; }
 
 .hover-top-in .hover-top--in {
   transition: ease-in-out all 0.35s;
   position: relative;
   top: 0; }
 
 .hover-top-in:hover .hover-top--in {
   top: -10px; }
 
 .hover-box-opacity .hover-box-opacity-in {
   opacity: 0;
   transition: ease-in-out all 0.35s; }
 
 .hover-box-opacity:hover .hover-box-opacity-in {
   opacity: 1; }
 
 .theme-hover-bg:hover {
   background: #FE4F70 !important; }
   .theme-hover-bg:hover * {
     color: #ffffff; }
   .theme-hover-bg:hover .text-primary {
     color: #ffffff !important; }
 
 .hover-scale .hover-scale-in {
   overflow: hidden; }
   .hover-scale .hover-scale-in img {
     transition: ease all 0.35s;
     transform: scale(1); }
 
 .hover-scale:hover .hover-scale-in img {
   transform: scale(1.05); }
 
 .shadow-sm-hover {
   transition: ease all 0.35s; }
   .shadow-sm-hover:hover {
     box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15) !important; }
 
 .shadow-hover {
   transition: ease all 0.35s; }
   .shadow-hover:hover {
     box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.625rem 1.5rem rgba(0, 0, 0, 0.15) !important; }
 
 .shadow-lg-hover {
   transition: ease all 0.35s; }
   .shadow-lg-hover:hover {
     box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1), 0 2rem 3rem rgba(0, 0, 0, 0.15) !important; }
 
 .dropdown-header {
   font-weight: 700;
   font-size: 0.95rem; }
 
 .dropdown-toggle:after {
   content: "\f078";
   font-family: 'Font Awesome 5 Free';
   font-size: 0.5rem;
   margin-left: 0.35rem;
   font-weight: 900;
   vertical-align: middle;
   border: none; }
 
 /*:not(.toast-header):not(.alert)>.btn-close {
     position: absolute;
     top: 1rem;
     right: 1rem;
     z-index: 1030;
     display: inline-flex;
 }*/
 .modal.px-modal-right .px-modal-vertical {
   transform: translateX(100%);
   height: 100%;
   max-width: 400px;
   margin: 0 0 0 auto;
   transition: transform .3s ease-out; }
   .modal.px-modal-right .px-modal-vertical .modal-content {
     height: inherit;
     overflow-y: auto;
     border: none;
     border-radius: 0; }
 
 .modal.px-modal-right.show {
   padding-right: 0 !important; }
   .modal.px-modal-right.show .px-modal-vertical {
     transform: translateX(0); }
 
 .divider-title {
   width: 100%;
   overflow: hidden;
   text-align: center;
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center; }
   .divider-title:after, .divider-title:before {
     background: #e2e8f0;
     content: "";
     display: inline-block;
     height: 1px;
     margin: 0 16px 0 -100%;
     vertical-align: top;
     width: 50%; }
   .divider-title:after {
     margin: 0 -100% 0 16px; }
   .divider-title.white:after, .divider-title.white:before {
     background: rgba(255, 255, 255, 0.1); }
 
 /*Logo show hide on scroll
 -----------------------------------*/
 .header-dark .logo-dark {
   display: none; }
 
 .fixed-header .navbar {
   background-color: #ffffff;
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15); }
 
 .fixed-header .logo-light {
   display: none; }
 
 .fixed-header .logo-dark {
   display: inline-flex; }
 
 .header-border-bottom {
   border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
   .header-light .header-border-bottom {
     border-bottom: 1px solid rgba(32, 54, 86, 0.1); }
 
 /* Hamburger Menu
 -----------------------------------*/
 .px-nav-hamburger .btn-close {
   position: absolute;
   top: 25px;
   right: 25px;
   z-index: 1; }
 
 .px-nav-hamburger .hamburger-in {
   padding: 110px 15% 50px; }
 
 .px-nav-hamburger .hamburger-bottom {
   border-top: 1px solid rgba(32, 54, 86, 0.05);
   padding-top: 30px;
   text-align: center; }
   .px-nav-hamburger .hamburger-bottom a {
     font-weight: 600;
     color: #203656;
     text-decoration: none; }
 
 .px-nav-hamburger .nav .nav-item {
   position: relative; }
   .px-nav-hamburger .nav .nav-item + .nav-item {
     border-top: 1px solid rgba(32, 54, 86, 0.05); }
   .px-nav-hamburger .nav .nav-item .nav-link {
     font-weight: 600;
     padding: 12px 0; }
     .px-nav-hamburger .nav .nav-item .nav-link:hover {
       color: #FE4F70; }
   .px-nav-hamburger .nav .nav-item .dropdown-toggle {
     position: absolute;
     top: 10px;
     right: 0;
     width: 25px;
     height: 25px;
     display: inline-flex;
     align-items: center;
     justify-content: center;
     color: #203656; }
     .px-nav-hamburger .nav .nav-item .dropdown-toggle:after {
       font-size: 1rem;
       content: "\f4fe";
       font-family: bootstrap-icons !important; }
   .px-nav-hamburger .nav .nav-item .list-unstyled {
     padding: 5px 0 15px 10px; }
     .px-nav-hamburger .nav .nav-item .list-unstyled li {
       padding: 3px 0; }
       .px-nav-hamburger .nav .nav-item .list-unstyled li a {
         font-size: 13px;
         color: #8F9BAD; }
         .px-nav-hamburger .nav .nav-item .list-unstyled li a:hover {
           color: #203656; }
 
 /* Header Search
 -----------------------------------*/
 .px-search-full {
   z-index: 9999; }
   .px-search-full .search-close {
     position: fixed;
     top: 15px;
     right: 15px;
     width: 40px;
     height: 40px;
     padding: 0;
     display: inline-flex;
     align-items: center;
     justify-content: center;
     font-size: 17px;
     color: #ffffff;
     background: #203656;
     border-radius: 50%; }
     .px-search-full .search-close:hover {
       background: #FE4F70; }
 
 /* Header Header
 -----------------------------------*/
 .fixed-header .header-top {
   visibility: hidden;
   pointer-events: none; }
 
 /* Header Social
 -----------------------------------*/
 .header-middle .h-social-link {
   margin-right: 5px;
   font-size: 20px; }
 
 .header-middle .dark-link a {
   color: #203656; }
   .header-middle .dark-link a:hover {
     color: rgba(32, 54, 86, 0.6); }
 
 .navbar-dark .nav .nav-link {
   color: #ffffff; }
 
 .navbar-light .nav .nav-link {
   color: #203656; }
 
 /*Header link and dropdown
 -----------------------------------*/
 .header-main {
   transition: ease-out top 0.15s; }
   .header-main .navbar-toggler {
     border: none;
     outline: none;
     box-shadow: none;
     padding: 0; }
     .header-main .navbar-toggler .navbar-toggler-icon {
       width: 1.8em;
       height: 1.8em; }
   .header-main .navbar-nav > .nav-item {
     margin: 0 5px; }
     .header-main .navbar-nav > .nav-item > .nav-link {
       font-weight: 500;
       text-transform: none;
       font-size: 1rem; }
     .header-main .navbar-nav > .nav-item.dropdown > .nav-link:after {
       display: none; }
     .header-main .navbar-nav > .nav-item .mob-menu {
       position: absolute;
       top: 0;
       right: 0;
       width: 40px;
       height: 52px;
       text-align: center;
       line-height: 52px;
       color: #203656; }
       .header-main .navbar-nav > .nav-item .mob-menu:after {
         display: none; }
       .header-main .navbar-nav > .nav-item .mob-menu:before {
         content: "\f078";
         font-family: "Font Awesome 5 Free";
         font-weight: 900;
         font-size: 11px;
         color: currentColor; }
       .header-main .navbar-nav > .nav-item .mob-menu.open:before {
         content: "\f077";
         color: #FE4F70; }
 
 /* Toggle bg color
 -----------------------------------*/
 .header-light.header-toggle .navbar {
   background: #ffffff; }
 
 /*Responsive
 -----------------------------------*/
 /* Mobile Menu
 -----------------------------------*/
 /*Menu Hover & Header Fluid Space 
 -----------------------------------*/
 .header-main {
   /*Top spacing for mobile menu 
   -----------------------------------*/ }
   @media (min-width: 1400px) {
     .header-main .navbar-expand-xxl .dropdown-mega-menu {
       width: 100%;
       background: #ffffff; }
     .header-main .navbar-expand-xxl > .container {
       position: relative; }
     .header-main .navbar-expand-xxl .dropdown-menu-md {
       min-width: 22rem; }
     .header-main .navbar-expand-xxl .dropdown-menu-lg {
       min-width: 35rem; }
     .header-main .navbar-expand-xxl .dropdown-menu-xl {
       min-width: 42rem; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
       line-height: 40px; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .nav-link:after {
       content: "\f078";
       font-family: "Font Awesome 5 Free";
       font-weight: 900;
       color: currentColor;
       margin-left: 8px;
       display: inline-block;
       font-size: 11px;
       border: none; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu {
       left: 50%;
       transform: translate(-50%, 0);
       margin: 0; }
       .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
         left: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
         left: auto;
         right: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
         position: relative; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
       position: absolute;
       top: 0;
       left: 100%; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown-full {
       position: static; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu {
       display: none;
       box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
       padding-top: 1rem;
       padding-bottom: 1rem;
       border-radius: 0; }
       .header-main .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown {
         position: relative; }
         .header-main .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
           position: relative; }
           .header-main .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
             content: "\f054";
             font-family: "Font Awesome 5 Free";
             font-weight: 900;
             color: currentColor;
             margin-left: 8px;
             display: inline-block;
             font-size: 11px;
             border: none;
             position: absolute;
             top: 8px;
             right: 10px; }
         .header-main .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
           display: block; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item:hover > .dropdown-menu {
       display: block; }
     .header-main .navbar-expand-xxl .navbar-nav > .nav-item .mob-menu {
       display: none; } }
   @media (min-width: 1200px) {
     .header-main .navbar-expand-xl .dropdown-mega-menu {
       width: 100%;
       background: #ffffff; }
     .header-main .navbar-expand-xl > .container {
       position: relative; }
     .header-main .navbar-expand-xl .dropdown-menu-md {
       min-width: 22rem; }
     .header-main .navbar-expand-xl .dropdown-menu-lg {
       min-width: 35rem; }
     .header-main .navbar-expand-xl .dropdown-menu-xl {
       min-width: 42rem; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
       line-height: 40px; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .nav-link:after {
       content: "\f078";
       font-family: "Font Awesome 5 Free";
       font-weight: 900;
       color: currentColor;
       margin-left: 8px;
       display: inline-block;
       font-size: 11px;
       border: none; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu {
       left: 50%;
       transform: translate(-50%, 0);
       margin: 0; }
       .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
         left: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
         left: auto;
         right: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
         position: relative; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
       position: absolute;
       top: 0;
       left: 100%; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown-full {
       position: static; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu {
       display: none;
       box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
       padding-top: 1rem;
       padding-bottom: 1rem;
       border-radius: 0; }
       .header-main .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown {
         position: relative; }
         .header-main .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
           position: relative; }
           .header-main .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
             content: "\f054";
             font-family: "Font Awesome 5 Free";
             font-weight: 900;
             color: currentColor;
             margin-left: 8px;
             display: inline-block;
             font-size: 11px;
             border: none;
             position: absolute;
             top: 8px;
             right: 10px; }
         .header-main .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
           display: block; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item:hover > .dropdown-menu {
       display: block; }
     .header-main .navbar-expand-xl .navbar-nav > .nav-item .mob-menu {
       display: none; } }
   @media (min-width: 992px) {
     .header-main .navbar-expand-lg .dropdown-mega-menu {
       width: 100%;
       background: #ffffff; }
     .header-main .navbar-expand-lg > .container {
       position: relative; }
     .header-main .navbar-expand-lg .dropdown-menu-md {
       min-width: 22rem; }
     .header-main .navbar-expand-lg .dropdown-menu-lg {
       min-width: 35rem; }
     .header-main .navbar-expand-lg .dropdown-menu-xl {
       min-width: 42rem; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
       line-height: 40px; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .nav-link:after {
       content: "\f078";
       font-family: "Font Awesome 5 Free";
       font-weight: 900;
       color: currentColor;
       margin-left: 8px;
       display: inline-block;
       font-size: 11px;
       border: none; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu {
       left: 50%;
       transform: translate(-50%, 0);
       margin: 0; }
       .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
         left: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
         left: auto;
         right: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
         position: relative; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
       position: absolute;
       top: 0;
       left: 100%; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown-full {
       position: static; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu {
       display: none;
       box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
       padding-top: 1rem;
       padding-bottom: 1rem;
       border-radius: 0; }
       .header-main .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown {
         position: relative; }
         .header-main .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
           position: relative; }
           .header-main .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
             content: "\f054";
             font-family: "Font Awesome 5 Free";
             font-weight: 900;
             color: currentColor;
             margin-left: 8px;
             display: inline-block;
             font-size: 11px;
             border: none;
             position: absolute;
             top: 8px;
             right: 10px; }
         .header-main .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
           display: block; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item:hover > .dropdown-menu {
       display: block; }
     .header-main .navbar-expand-lg .navbar-nav > .nav-item .mob-menu {
       display: none; } }
   @media (min-width: 768px) {
     .header-main .navbar-expand-md .dropdown-mega-menu {
       width: 100%;
       background: #ffffff; }
     .header-main .navbar-expand-md > .container {
       position: relative; }
     .header-main .navbar-expand-md .dropdown-menu-md {
       min-width: 22rem; }
     .header-main .navbar-expand-md .dropdown-menu-lg {
       min-width: 35rem; }
     .header-main .navbar-expand-md .dropdown-menu-xl {
       min-width: 42rem; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
       line-height: 40px; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .nav-link:after {
       content: "\f078";
       font-family: "Font Awesome 5 Free";
       font-weight: 900;
       color: currentColor;
       margin-left: 8px;
       display: inline-block;
       font-size: 11px;
       border: none; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu {
       left: 50%;
       transform: translate(-50%, 0);
       margin: 0; }
       .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
         left: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
         left: auto;
         right: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
         position: relative; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
       position: absolute;
       top: 0;
       left: 100%; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown-full {
       position: static; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu {
       display: none;
       box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
       padding-top: 1rem;
       padding-bottom: 1rem;
       border-radius: 0; }
       .header-main .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown {
         position: relative; }
         .header-main .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
           position: relative; }
           .header-main .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
             content: "\f054";
             font-family: "Font Awesome 5 Free";
             font-weight: 900;
             color: currentColor;
             margin-left: 8px;
             display: inline-block;
             font-size: 11px;
             border: none;
             position: absolute;
             top: 8px;
             right: 10px; }
         .header-main .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
           display: block; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item:hover > .dropdown-menu {
       display: block; }
     .header-main .navbar-expand-md .navbar-nav > .nav-item .mob-menu {
       display: none; } }
   @media (min-width: 576px) {
     .header-main .navbar-expand-sm .dropdown-mega-menu {
       width: 100%;
       background: #ffffff; }
     .header-main .navbar-expand-sm > .container {
       position: relative; }
     .header-main .navbar-expand-sm .dropdown-menu-md {
       min-width: 22rem; }
     .header-main .navbar-expand-sm .dropdown-menu-lg {
       min-width: 35rem; }
     .header-main .navbar-expand-sm .dropdown-menu-xl {
       min-width: 42rem; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
       line-height: 40px; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .nav-link:after {
       content: "\f078";
       font-family: "Font Awesome 5 Free";
       font-weight: 900;
       color: currentColor;
       margin-left: 8px;
       display: inline-block;
       font-size: 11px;
       border: none; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu {
       left: 50%;
       transform: translate(-50%, 0);
       margin: 0; }
       .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
         left: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
         left: auto;
         right: 0;
         transform: translate(0, 0); }
       .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
         position: relative; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
       position: absolute;
       top: 0;
       left: 100%; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown-full {
       position: static; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu {
       display: none;
       box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
       padding-top: 1rem;
       padding-bottom: 1rem;
       border-radius: 0; }
       .header-main .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown {
         position: relative; }
         .header-main .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
           position: relative; }
           .header-main .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
             content: "\f054";
             font-family: "Font Awesome 5 Free";
             font-weight: 900;
             color: currentColor;
             margin-left: 8px;
             display: inline-block;
             font-size: 11px;
             border: none;
             position: absolute;
             top: 8px;
             right: 10px; }
         .header-main .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
           display: block; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item:hover > .dropdown-menu {
       display: block; }
     .header-main .navbar-expand-sm .navbar-nav > .nav-item .mob-menu {
       display: none; } }
   .header-main .navbar-expand-xs .dropdown-mega-menu {
     width: 100%;
     background: #ffffff; }
   .header-main .navbar-expand-xs > .container {
     position: relative; }
   .header-main .navbar-expand-xs .dropdown-menu-md {
     min-width: 22rem; }
   .header-main .navbar-expand-xs .dropdown-menu-lg {
     min-width: 35rem; }
   .header-main .navbar-expand-xs .dropdown-menu-xl {
     min-width: 42rem; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item > .nav-link {
     line-height: 40px; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .nav-link:after {
     content: "\f078";
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
     color: currentColor;
     margin-left: 8px;
     display: inline-block;
     font-size: 11px;
     border: none; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu {
     left: 50%;
     transform: translate(-50%, 0);
     margin: 0; }
     .header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
       left: 0;
       transform: translate(0, 0); }
     .header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
       left: auto;
       right: 0;
       transform: translate(0, 0); }
     .header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
       position: relative; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
     position: absolute;
     top: 0;
     left: 100%; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown-full {
     position: static; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item .dropdown-menu {
     display: none;
     box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
     padding-top: 1rem;
     padding-bottom: 1rem;
     border-radius: 0; }
     .header-main .navbar-expand-xs .navbar-nav > .nav-item .dropdown-menu > .dropdown {
       position: relative; }
       .header-main .navbar-expand-xs .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
         position: relative; }
         .header-main .navbar-expand-xs .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
           content: "\f054";
           font-family: "Font Awesome 5 Free";
           font-weight: 900;
           color: currentColor;
           margin-left: 8px;
           display: inline-block;
           font-size: 11px;
           border: none;
           position: absolute;
           top: 8px;
           right: 10px; }
       .header-main .navbar-expand-xs .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
         display: block; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item:hover > .dropdown-menu {
     display: block; }
   .header-main .navbar-expand-xs .navbar-nav > .nav-item .mob-menu {
     display: none; }
   @media (max-width: 1399.98px) {
     .header-main .navbar-expand-xxl {
       padding-top: 10px;
       padding-bottom: 10px; }
       .header-main .navbar-expand-xxl .navbar-collapse {
         max-height: 70vh;
         overflow-y: auto;
         position: absolute;
         top: 100%;
         left: 0;
         right: 0;
         background: #ffffff; }
       .header-main .navbar-expand-xxl .navbar-nav .nav-item {
         margin: 0;
         border-top: 1px solid rgba(0, 0, 0, 0.08); }
         .header-main .navbar-expand-xxl .navbar-nav .nav-item > .nav-link {
           padding: 15px;
           color: #203656; }
         .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu {
           background: #f7f7ff;
           margin: 0;
           border-radius: 0; }
           .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu li {
             position: relative; }
             .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu li .mob-menu {
               line-height: 34px;
               height: 34px; }
           .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
             background: #ededff; }
             .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
               position: relative; } }
   @media (max-width: 1199.98px) {
     .header-main .navbar-expand-xl {
       padding-top: 10px;
       padding-bottom: 10px; }
       .header-main .navbar-expand-xl .navbar-collapse {
         max-height: 70vh;
         overflow-y: auto;
         position: absolute;
         top: 100%;
         left: 0;
         right: 0;
         background: #ffffff; }
       .header-main .navbar-expand-xl .navbar-nav .nav-item {
         margin: 0;
         border-top: 1px solid rgba(0, 0, 0, 0.08); }
         .header-main .navbar-expand-xl .navbar-nav .nav-item > .nav-link {
           padding: 15px;
           color: #203656; }
         .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu {
           background: #f7f7ff;
           margin: 0;
           border-radius: 0; }
           .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu li {
             position: relative; }
             .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu li .mob-menu {
               line-height: 34px;
               height: 34px; }
           .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
             background: #ededff; }
             .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
               position: relative; } }
   @media (max-width: 991.98px) {
     .header-main .navbar-expand-lg {
       padding-top: 10px;
       padding-bottom: 10px; }
       .header-main .navbar-expand-lg .navbar-collapse {
         max-height: 70vh;
         overflow-y: auto;
         position: absolute;
         top: 100%;
         left: 0;
         right: 0;
         background: #ffffff; }
       .header-main .navbar-expand-lg .navbar-nav .nav-item {
         margin: 0;
         border-top: 1px solid rgba(0, 0, 0, 0.08); }
         .header-main .navbar-expand-lg .navbar-nav .nav-item > .nav-link {
           padding: 15px;
           color: #203656; }
         .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu {
           background: #f7f7ff;
           margin: 0;
           border-radius: 0; }
           .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu li {
             position: relative; }
             .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu li .mob-menu {
               line-height: 34px;
               height: 34px; }
           .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
             background: #ededff; }
             .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
               position: relative; } }
   @media (max-width: 767.98px) {
     .header-main .navbar-expand-md {
       padding-top: 10px;
       padding-bottom: 10px; }
       .header-main .navbar-expand-md .navbar-collapse {
         max-height: 70vh;
         overflow-y: auto;
         position: absolute;
         top: 100%;
         left: 0;
         right: 0;
         background: #ffffff; }
       .header-main .navbar-expand-md .navbar-nav .nav-item {
         margin: 0;
         border-top: 1px solid rgba(0, 0, 0, 0.08); }
         .header-main .navbar-expand-md .navbar-nav .nav-item > .nav-link {
           padding: 15px;
           color: #203656; }
         .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu {
           background: #f7f7ff;
           margin: 0;
           border-radius: 0; }
           .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu li {
             position: relative; }
             .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu li .mob-menu {
               line-height: 34px;
               height: 34px; }
           .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
             background: #ededff; }
             .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
               position: relative; } }
   @media (max-width: 575.98px) {
     .header-main .navbar-expand-sm {
       padding-top: 10px;
       padding-bottom: 10px; }
       .header-main .navbar-expand-sm .navbar-collapse {
         max-height: 70vh;
         overflow-y: auto;
         position: absolute;
         top: 100%;
         left: 0;
         right: 0;
         background: #ffffff; }
       .header-main .navbar-expand-sm .navbar-nav .nav-item {
         margin: 0;
         border-top: 1px solid rgba(0, 0, 0, 0.08); }
         .header-main .navbar-expand-sm .navbar-nav .nav-item > .nav-link {
           padding: 15px;
           color: #203656; }
         .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu {
           background: #f7f7ff;
           margin: 0;
           border-radius: 0; }
           .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li {
             position: relative; }
             .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li .mob-menu {
               line-height: 34px;
               height: 34px; }
           .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
             background: #ededff; }
             .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
               position: relative; } }
   .header-main .navbar-expand-sm {
     padding-top: 10px;
     padding-bottom: 10px; }
     .header-main .navbar-expand-sm .navbar-collapse {
       max-height: 70vh;
       overflow-y: auto;
       position: absolute;
       top: 100%;
       left: 0;
       right: 0;
       background: #ffffff; }
     .header-main .navbar-expand-sm .navbar-nav .nav-item {
       margin: 0;
       border-top: 1px solid rgba(0, 0, 0, 0.08); }
       .header-main .navbar-expand-sm .navbar-nav .nav-item > .nav-link {
         padding: 15px;
         color: #203656; }
       .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu {
         background: #f7f7ff;
         margin: 0;
         border-radius: 0; }
         .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li {
           position: relative; }
           .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li .mob-menu {
             line-height: 34px;
             height: 34px; }
         .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
           background: #ededff; }
           .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
             position: relative; }
 
 .footer .footer-links {
   margin: 0; }
   .footer .footer-links li + li {
     padding-top: 10px; }
 
 .footer .footer-top {
   padding-top: 1rem;
   padding-bottom: 1rem; }
   @media (min-width: 768px) {
     .footer .footer-top {
       padding-top: 2rem;
       padding-bottom: 2rem; } }
   @media (min-width: 992px) {
     .footer .footer-top {
       padding-top: 3rem;
       padding-bottom: 3rem; } }
 
 .footer .footer-border {
   border-top: 1px solid rgba(255, 255, 255, 0.1); }
   .footer .footer-border.dark {
     border-top: 1px solid rgba(32, 54, 86, 0.1); }
 